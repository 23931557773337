{
    "file": {
        "base_type": "enum",
        "values": {
            "1": "device",
            "2": "settings",
            "3": "sport",
            "4": "activity",
            "5": "workout",
            "6": "course",
            "7": "schedules",
            "9": "weight",
            "10": "totals",
            "11": "goals",
            "14": "blood_pressure",
            "15": "monitoring_a",
            "20": "activity_summary",
            "28": "monitoring_daily",
            "32": "monitoring_b",
            "34": "segment",
            "35": "segment_list",
            "40": "exd_configuration",
            "247": "mfg_range_min",
            "254": "mfg_range_max",
            "device": 1,
            "settings": 2,
            "sport": 3,
            "activity": 4,
            "workout": 5,
            "course": 6,
            "schedules": 7,
            "weight": 9,
            "totals": 10,
            "goals": 11,
            "blood_pressure": 14,
            "monitoring_a": 15,
            "activity_summary": 20,
            "monitoring_daily": 28,
            "monitoring_b": 32,
            "segment": 34,
            "segment_list": 35,
            "exd_configuration": 40,
            "mfg_range_min": 247,
            "mfg_range_max": 254
        }
    },
    "mesg_num": {
        "base_type": "uint16",
        "values": {
            "0": "file_id",
            "1": "capabilities",
            "2": "device_settings",
            "3": "user_profile",
            "4": "hrm_profile",
            "5": "sdm_profile",
            "6": "bike_profile",
            "7": "zones_target",
            "8": "hr_zone",
            "9": "power_zone",
            "10": "met_zone",
            "12": "sport",
            "15": "goal",
            "18": "session",
            "19": "lap",
            "20": "record",
            "21": "event",
            "23": "device_info",
            "26": "workout",
            "27": "workout_step",
            "28": "schedule",
            "30": "weight_scale",
            "31": "course",
            "32": "course_point",
            "33": "totals",
            "34": "activity",
            "35": "software",
            "37": "file_capabilities",
            "38": "mesg_capabilities",
            "39": "field_capabilities",
            "49": "file_creator",
            "51": "blood_pressure",
            "53": "speed_zone",
            "55": "monitoring",
            "72": "training_file",
            "78": "hrv",
            "80": "ant_rx",
            "81": "ant_tx",
            "82": "ant_channel_id",
            "101": "length",
            "103": "monitoring_info",
            "105": "pad",
            "106": "slave_device",
            "127": "connectivity",
            "128": "weather_conditions",
            "129": "weather_alert",
            "131": "cadence_zone",
            "132": "hr",
            "142": "segment_lap",
            "145": "memo_glob",
            "148": "segment_id",
            "149": "segment_leaderboard_entry",
            "150": "segment_point",
            "151": "segment_file",
            "158": "workout_session",
            "159": "watchface_settings",
            "160": "gps_metadata",
            "161": "camera_event",
            "162": "timestamp_correlation",
            "164": "gyroscope_data",
            "165": "accelerometer_data",
            "167": "three_d_sensor_calibration",
            "169": "video_frame",
            "174": "obdii_data",
            "177": "nmea_sentence",
            "178": "aviation_attitude",
            "184": "video",
            "185": "video_title",
            "186": "video_description",
            "187": "video_clip",
            "188": "ohr_settings",
            "200": "exd_screen_configuration",
            "201": "exd_data_field_configuration",
            "202": "exd_data_concept_configuration",
            "206": "field_description",
            "207": "developer_data_id",
            "208": "magnetometer_data",
            "209": "barometer_data",
            "210": "one_d_sensor_calibration",
            "225": "set",
            "227": "stress_level",
            "258": "dive_settings",
            "259": "dive_gas",
            "262": "dive_alarm",
            "264": "exercise_title",
            "268": "dive_summary",
            "285": "jump",
            "317": "climb_pro",
            "65280": "mfg_range_min",
            "65534": "mfg_range_max",
            "file_id": 0,
            "capabilities": 1,
            "device_settings": 2,
            "user_profile": 3,
            "hrm_profile": 4,
            "sdm_profile": 5,
            "bike_profile": 6,
            "zones_target": 7,
            "hr_zone": 8,
            "power_zone": 9,
            "met_zone": 10,
            "sport": 12,
            "goal": 15,
            "session": 18,
            "lap": 19,
            "record": 20,
            "event": 21,
            "device_info": 23,
            "workout": 26,
            "workout_step": 27,
            "schedule": 28,
            "weight_scale": 30,
            "course": 31,
            "course_point": 32,
            "totals": 33,
            "activity": 34,
            "software": 35,
            "file_capabilities": 37,
            "mesg_capabilities": 38,
            "field_capabilities": 39,
            "file_creator": 49,
            "blood_pressure": 51,
            "speed_zone": 53,
            "monitoring": 55,
            "training_file": 72,
            "hrv": 78,
            "ant_rx": 80,
            "ant_tx": 81,
            "ant_channel_id": 82,
            "length": 101,
            "monitoring_info": 103,
            "pad": 105,
            "slave_device": 106,
            "connectivity": 127,
            "weather_conditions": 128,
            "weather_alert": 129,
            "cadence_zone": 131,
            "hr": 132,
            "segment_lap": 142,
            "memo_glob": 145,
            "segment_id": 148,
            "segment_leaderboard_entry": 149,
            "segment_point": 150,
            "segment_file": 151,
            "workout_session": 158,
            "watchface_settings": 159,
            "gps_metadata": 160,
            "camera_event": 161,
            "timestamp_correlation": 162,
            "gyroscope_data": 164,
            "accelerometer_data": 165,
            "three_d_sensor_calibration": 167,
            "video_frame": 169,
            "obdii_data": 174,
            "nmea_sentence": 177,
            "aviation_attitude": 178,
            "video": 184,
            "video_title": 185,
            "video_description": 186,
            "video_clip": 187,
            "ohr_settings": 188,
            "exd_screen_configuration": 200,
            "exd_data_field_configuration": 201,
            "exd_data_concept_configuration": 202,
            "field_description": 206,
            "developer_data_id": 207,
            "magnetometer_data": 208,
            "barometer_data": 209,
            "one_d_sensor_calibration": 210,
            "set": 225,
            "stress_level": 227,
            "dive_settings": 258,
            "dive_gas": 259,
            "dive_alarm": 262,
            "exercise_title": 264,
            "dive_summary": 268,
            "jump": 285,
            "climb_pro": 317,
            "mfg_range_min": 65280,
            "mfg_range_max": 65534
        }
    },
    "checksum": {
        "base_type": "uint8",
        "values": {
            "0": "clear",
            "1": "ok",
            "clear": 0,
            "ok": 1
        }
    },
    "file_flags": {
        "base_type": "uint8z",
        "values": {
            "2": "read",
            "4": "write",
            "8": "erase",
            "read": 2,
            "write": 4,
            "erase": 8
        }
    },
    "mesg_count": {
        "base_type": "enum",
        "values": {
            "0": "num_per_file",
            "1": "max_per_file",
            "2": "max_per_file_type",
            "num_per_file": 0,
            "max_per_file": 1,
            "max_per_file_type": 2
        }
    },
    "date_time": {
        "base_type": "uint32",
        "values": {
            "268435456": "min",
            "min": 268435456
        }
    },
    "local_date_time": {
        "base_type": "uint32",
        "values": {
            "268435456": "min",
            "min": 268435456
        }
    },
    "message_index": {
        "base_type": "uint16",
        "values": {
            "4095": "mask",
            "28672": "reserved",
            "32768": "selected",
            "selected": 32768,
            "reserved": 28672,
            "mask": 4095
        }
    },
    "device_index": {
        "base_type": "uint8",
        "values": {
            "0": "creator",
            "creator": 0
        }
    },
    "gender": {
        "base_type": "enum",
        "values": {
            "0": "female",
            "1": "male",
            "female": 0,
            "male": 1
        }
    },
    "language": {
        "base_type": "enum",
        "values": {
            "0": "english",
            "1": "french",
            "2": "italian",
            "3": "german",
            "4": "spanish",
            "5": "croatian",
            "6": "czech",
            "7": "danish",
            "8": "dutch",
            "9": "finnish",
            "10": "greek",
            "11": "hungarian",
            "12": "norwegian",
            "13": "polish",
            "14": "portuguese",
            "15": "slovakian",
            "16": "slovenian",
            "17": "swedish",
            "18": "russian",
            "19": "turkish",
            "20": "latvian",
            "21": "ukrainian",
            "22": "arabic",
            "23": "farsi",
            "24": "bulgarian",
            "25": "romanian",
            "26": "chinese",
            "27": "japanese",
            "28": "korean",
            "29": "taiwanese",
            "30": "thai",
            "31": "hebrew",
            "32": "brazilian_portuguese",
            "33": "indonesian",
            "34": "malaysian",
            "35": "vietnamese",
            "36": "burmese",
            "37": "mongolian",
            "254": "custom",
            "english": 0,
            "french": 1,
            "italian": 2,
            "german": 3,
            "spanish": 4,
            "croatian": 5,
            "czech": 6,
            "danish": 7,
            "dutch": 8,
            "finnish": 9,
            "greek": 10,
            "hungarian": 11,
            "norwegian": 12,
            "polish": 13,
            "portuguese": 14,
            "slovakian": 15,
            "slovenian": 16,
            "swedish": 17,
            "russian": 18,
            "turkish": 19,
            "latvian": 20,
            "ukrainian": 21,
            "arabic": 22,
            "farsi": 23,
            "bulgarian": 24,
            "romanian": 25,
            "chinese": 26,
            "japanese": 27,
            "korean": 28,
            "taiwanese": 29,
            "thai": 30,
            "hebrew": 31,
            "brazilian_portuguese": 32,
            "indonesian": 33,
            "malaysian": 34,
            "vietnamese": 35,
            "burmese": 36,
            "mongolian": 37,
            "custom": 254
        }
    },
    "language_bits_0": {
        "base_type": "uint8z",
        "values": {
            "1": "english",
            "2": "french",
            "4": "italian",
            "8": "german",
            "16": "spanish",
            "32": "croatian",
            "64": "czech",
            "128": "danish",
            "english": 1,
            "french": 2,
            "italian": 4,
            "german": 8,
            "spanish": 16,
            "croatian": 32,
            "czech": 64,
            "danish": 128
        }
    },
    "language_bits_1": {
        "base_type": "uint8z",
        "values": {
            "1": "dutch",
            "2": "finnish",
            "4": "greek",
            "8": "hungarian",
            "16": "norwegian",
            "32": "polish",
            "64": "portuguese",
            "128": "slovakian",
            "dutch": 1,
            "finnish": 2,
            "greek": 4,
            "hungarian": 8,
            "norwegian": 16,
            "polish": 32,
            "portuguese": 64,
            "slovakian": 128
        }
    },
    "language_bits_2": {
        "base_type": "uint8z",
        "values": {
            "1": "slovenian",
            "2": "swedish",
            "4": "russian",
            "8": "turkish",
            "16": "latvian",
            "32": "ukrainian",
            "64": "arabic",
            "128": "farsi",
            "slovenian": 1,
            "swedish": 2,
            "russian": 4,
            "turkish": 8,
            "latvian": 16,
            "ukrainian": 32,
            "arabic": 64,
            "farsi": 128
        }
    },
    "language_bits_3": {
        "base_type": "uint8z",
        "values": {
            "1": "bulgarian",
            "2": "romanian",
            "4": "chinese",
            "8": "japanese",
            "16": "korean",
            "32": "taiwanese",
            "64": "thai",
            "128": "hebrew",
            "bulgarian": 1,
            "romanian": 2,
            "chinese": 4,
            "japanese": 8,
            "korean": 16,
            "taiwanese": 32,
            "thai": 64,
            "hebrew": 128
        }
    },
    "language_bits_4": {
        "base_type": "uint8z",
        "values": {
            "1": "brazilian_portuguese",
            "2": "indonesian",
            "4": "malaysian",
            "8": "vietnamese",
            "16": "burmese",
            "32": "mongolian",
            "brazilian_portuguese": 1,
            "indonesian": 2,
            "malaysian": 4,
            "vietnamese": 8,
            "burmese": 16,
            "mongolian": 32
        }
    },
    "time_zone": {
        "base_type": "enum",
        "values": {
            "0": "almaty",
            "1": "bangkok",
            "2": "bombay",
            "3": "brasilia",
            "4": "cairo",
            "5": "cape_verde_is",
            "6": "darwin",
            "7": "eniwetok",
            "8": "fiji",
            "9": "hong_kong",
            "10": "islamabad",
            "11": "kabul",
            "12": "magadan",
            "13": "mid_atlantic",
            "14": "moscow",
            "15": "muscat",
            "16": "newfoundland",
            "17": "samoa",
            "18": "sydney",
            "19": "tehran",
            "20": "tokyo",
            "21": "us_alaska",
            "22": "us_atlantic",
            "23": "us_central",
            "24": "us_eastern",
            "25": "us_hawaii",
            "26": "us_mountain",
            "27": "us_pacific",
            "28": "other",
            "29": "auckland",
            "30": "kathmandu",
            "31": "europe_western_wet",
            "32": "europe_central_cet",
            "33": "europe_eastern_eet",
            "34": "jakarta",
            "35": "perth",
            "36": "adelaide",
            "37": "brisbane",
            "38": "tasmania",
            "39": "iceland",
            "40": "amsterdam",
            "41": "athens",
            "42": "barcelona",
            "43": "berlin",
            "44": "brussels",
            "45": "budapest",
            "46": "copenhagen",
            "47": "dublin",
            "48": "helsinki",
            "49": "lisbon",
            "50": "london",
            "51": "madrid",
            "52": "munich",
            "53": "oslo",
            "54": "paris",
            "55": "prague",
            "56": "reykjavik",
            "57": "rome",
            "58": "stockholm",
            "59": "vienna",
            "60": "warsaw",
            "61": "zurich",
            "62": "quebec",
            "63": "ontario",
            "64": "manitoba",
            "65": "saskatchewan",
            "66": "alberta",
            "67": "british_columbia",
            "68": "boise",
            "69": "boston",
            "70": "chicago",
            "71": "dallas",
            "72": "denver",
            "73": "kansas_city",
            "74": "las_vegas",
            "75": "los_angeles",
            "76": "miami",
            "77": "minneapolis",
            "78": "new_york",
            "79": "new_orleans",
            "80": "phoenix",
            "81": "santa_fe",
            "82": "seattle",
            "83": "washington_dc",
            "84": "us_arizona",
            "85": "chita",
            "86": "ekaterinburg",
            "87": "irkutsk",
            "88": "kaliningrad",
            "89": "krasnoyarsk",
            "90": "novosibirsk",
            "91": "petropavlovsk_kamchatskiy",
            "92": "samara",
            "93": "vladivostok",
            "94": "mexico_central",
            "95": "mexico_mountain",
            "96": "mexico_pacific",
            "97": "cape_town",
            "98": "winkhoek",
            "99": "lagos",
            "100": "riyahd",
            "101": "venezuela",
            "102": "australia_lh",
            "103": "santiago",
            "253": "manual",
            "254": "automatic",
            "almaty": 0,
            "bangkok": 1,
            "bombay": 2,
            "brasilia": 3,
            "cairo": 4,
            "cape_verde_is": 5,
            "darwin": 6,
            "eniwetok": 7,
            "fiji": 8,
            "hong_kong": 9,
            "islamabad": 10,
            "kabul": 11,
            "magadan": 12,
            "mid_atlantic": 13,
            "moscow": 14,
            "muscat": 15,
            "newfoundland": 16,
            "samoa": 17,
            "sydney": 18,
            "tehran": 19,
            "tokyo": 20,
            "us_alaska": 21,
            "us_atlantic": 22,
            "us_central": 23,
            "us_eastern": 24,
            "us_hawaii": 25,
            "us_mountain": 26,
            "us_pacific": 27,
            "other": 28,
            "auckland": 29,
            "kathmandu": 30,
            "europe_western_wet": 31,
            "europe_central_cet": 32,
            "europe_eastern_eet": 33,
            "jakarta": 34,
            "perth": 35,
            "adelaide": 36,
            "brisbane": 37,
            "tasmania": 38,
            "iceland": 39,
            "amsterdam": 40,
            "athens": 41,
            "barcelona": 42,
            "berlin": 43,
            "brussels": 44,
            "budapest": 45,
            "copenhagen": 46,
            "dublin": 47,
            "helsinki": 48,
            "lisbon": 49,
            "london": 50,
            "madrid": 51,
            "munich": 52,
            "oslo": 53,
            "paris": 54,
            "prague": 55,
            "reykjavik": 56,
            "rome": 57,
            "stockholm": 58,
            "vienna": 59,
            "warsaw": 60,
            "zurich": 61,
            "quebec": 62,
            "ontario": 63,
            "manitoba": 64,
            "saskatchewan": 65,
            "alberta": 66,
            "british_columbia": 67,
            "boise": 68,
            "boston": 69,
            "chicago": 70,
            "dallas": 71,
            "denver": 72,
            "kansas_city": 73,
            "las_vegas": 74,
            "los_angeles": 75,
            "miami": 76,
            "minneapolis": 77,
            "new_york": 78,
            "new_orleans": 79,
            "phoenix": 80,
            "santa_fe": 81,
            "seattle": 82,
            "washington_dc": 83,
            "us_arizona": 84,
            "chita": 85,
            "ekaterinburg": 86,
            "irkutsk": 87,
            "kaliningrad": 88,
            "krasnoyarsk": 89,
            "novosibirsk": 90,
            "petropavlovsk_kamchatskiy": 91,
            "samara": 92,
            "vladivostok": 93,
            "mexico_central": 94,
            "mexico_mountain": 95,
            "mexico_pacific": 96,
            "cape_town": 97,
            "winkhoek": 98,
            "lagos": 99,
            "riyahd": 100,
            "venezuela": 101,
            "australia_lh": 102,
            "santiago": 103,
            "manual": 253,
            "automatic": 254
        }
    },
    "display_measure": {
        "base_type": "enum",
        "values": {
            "0": "metric",
            "1": "statute",
            "2": "nautical",
            "metric": 0,
            "statute": 1,
            "nautical": 2
        }
    },
    "display_heart": {
        "base_type": "enum",
        "values": {
            "0": "bpm",
            "1": "max",
            "2": "reserve",
            "bpm": 0,
            "max": 1,
            "reserve": 2
        }
    },
    "display_power": {
        "base_type": "enum",
        "values": {
            "0": "watts",
            "1": "percent_ftp",
            "watts": 0,
            "percent_ftp": 1
        }
    },
    "display_position": {
        "base_type": "enum",
        "values": {
            "0": "degree",
            "1": "degree_minute",
            "2": "degree_minute_second",
            "3": "austrian_grid",
            "4": "british_grid",
            "5": "dutch_grid",
            "6": "hungarian_grid",
            "7": "finnish_grid",
            "8": "german_grid",
            "9": "icelandic_grid",
            "10": "indonesian_equatorial",
            "11": "indonesian_irian",
            "12": "indonesian_southern",
            "13": "india_zone_0",
            "14": "india_zone_IA",
            "15": "india_zone_IB",
            "16": "india_zone_IIA",
            "17": "india_zone_IIB",
            "18": "india_zone_IIIA",
            "19": "india_zone_IIIB",
            "20": "india_zone_IVA",
            "21": "india_zone_IVB",
            "22": "irish_transverse",
            "23": "irish_grid",
            "24": "loran",
            "25": "maidenhead_grid",
            "26": "mgrs_grid",
            "27": "new_zealand_grid",
            "28": "new_zealand_transverse",
            "29": "qatar_grid",
            "30": "modified_swedish_grid",
            "31": "swedish_grid",
            "32": "south_african_grid",
            "33": "swiss_grid",
            "34": "taiwan_grid",
            "35": "united_states_grid",
            "36": "utm_ups_grid",
            "37": "west_malayan",
            "38": "borneo_rso",
            "39": "estonian_grid",
            "40": "latvian_grid",
            "41": "swedish_ref_99_grid",
            "degree": 0,
            "degree_minute": 1,
            "degree_minute_second": 2,
            "austrian_grid": 3,
            "british_grid": 4,
            "dutch_grid": 5,
            "hungarian_grid": 6,
            "finnish_grid": 7,
            "german_grid": 8,
            "icelandic_grid": 9,
            "indonesian_equatorial": 10,
            "indonesian_irian": 11,
            "indonesian_southern": 12,
            "india_zone_0": 13,
            "india_zone_IA": 14,
            "india_zone_IB": 15,
            "india_zone_IIA": 16,
            "india_zone_IIB": 17,
            "india_zone_IIIA": 18,
            "india_zone_IIIB": 19,
            "india_zone_IVA": 20,
            "india_zone_IVB": 21,
            "irish_transverse": 22,
            "irish_grid": 23,
            "loran": 24,
            "maidenhead_grid": 25,
            "mgrs_grid": 26,
            "new_zealand_grid": 27,
            "new_zealand_transverse": 28,
            "qatar_grid": 29,
            "modified_swedish_grid": 30,
            "swedish_grid": 31,
            "south_african_grid": 32,
            "swiss_grid": 33,
            "taiwan_grid": 34,
            "united_states_grid": 35,
            "utm_ups_grid": 36,
            "west_malayan": 37,
            "borneo_rso": 38,
            "estonian_grid": 39,
            "latvian_grid": 40,
            "swedish_ref_99_grid": 41
        }
    },
    "switch": {
        "base_type": "enum",
        "values": {
            "0": "off",
            "1": "on",
            "2": "auto",
            "off": 0,
            "on": 1,
            "auto": 2
        }
    },
    "sport": {
        "base_type": "enum",
        "values": {
            "0": "generic",
            "1": "running",
            "2": "cycling",
            "3": "transition",
            "4": "fitness_equipment",
            "5": "swimming",
            "6": "basketball",
            "7": "soccer",
            "8": "tennis",
            "9": "american_football",
            "10": "training",
            "11": "walking",
            "12": "cross_country_skiing",
            "13": "alpine_skiing",
            "14": "snowboarding",
            "15": "rowing",
            "16": "mountaineering",
            "17": "hiking",
            "18": "multisport",
            "19": "paddling",
            "20": "flying",
            "21": "e_biking",
            "22": "motorcycling",
            "23": "boating",
            "24": "driving",
            "25": "golf",
            "26": "hang_gliding",
            "27": "horseback_riding",
            "28": "hunting",
            "29": "fishing",
            "30": "inline_skating",
            "31": "rock_climbing",
            "32": "sailing",
            "33": "ice_skating",
            "34": "sky_diving",
            "35": "snowshoeing",
            "36": "snowmobiling",
            "37": "stand_up_paddleboarding",
            "38": "surfing",
            "39": "wakeboarding",
            "40": "water_skiing",
            "41": "kayaking",
            "42": "rafting",
            "43": "windsurfing",
            "44": "kitesurfing",
            "45": "tactical",
            "46": "jumpmaster",
            "47": "boxing",
            "48": "floor_climbing",
            "53": "diving",
            "254": "all",
            "generic": 0,
            "running": 1,
            "cycling": 2,
            "transition": 3,
            "fitness_equipment": 4,
            "swimming": 5,
            "basketball": 6,
            "soccer": 7,
            "tennis": 8,
            "american_football": 9,
            "training": 10,
            "walking": 11,
            "cross_country_skiing": 12,
            "alpine_skiing": 13,
            "snowboarding": 14,
            "rowing": 15,
            "mountaineering": 16,
            "hiking": 17,
            "multisport": 18,
            "paddling": 19,
            "flying": 20,
            "e_biking": 21,
            "motorcycling": 22,
            "boating": 23,
            "driving": 24,
            "golf": 25,
            "hang_gliding": 26,
            "horseback_riding": 27,
            "hunting": 28,
            "fishing": 29,
            "inline_skating": 30,
            "rock_climbing": 31,
            "sailing": 32,
            "ice_skating": 33,
            "sky_diving": 34,
            "snowshoeing": 35,
            "snowmobiling": 36,
            "stand_up_paddleboarding": 37,
            "surfing": 38,
            "wakeboarding": 39,
            "water_skiing": 40,
            "kayaking": 41,
            "rafting": 42,
            "windsurfing": 43,
            "kitesurfing": 44,
            "tactical": 45,
            "jumpmaster": 46,
            "boxing": 47,
            "floor_climbing": 48,
            "diving": 53,
            "all": 254
        }
    },
    "sport_bits_0": {
        "base_type": "uint8z",
        "values": {
            "1": "generic",
            "2": "running",
            "4": "cycling",
            "8": "transition",
            "16": "fitness_equipment",
            "32": "swimming",
            "64": "basketball",
            "128": "soccer",
            "generic": 1,
            "running": 2,
            "cycling": 4,
            "transition": 8,
            "fitness_equipment": 16,
            "swimming": 32,
            "basketball": 64,
            "soccer": 128
        }
    },
    "sport_bits_1": {
        "base_type": "uint8z",
        "values": {
            "1": "tennis",
            "2": "american_football",
            "4": "training",
            "8": "walking",
            "16": "cross_country_skiing",
            "32": "alpine_skiing",
            "64": "snowboarding",
            "128": "rowing",
            "tennis": 1,
            "american_football": 2,
            "training": 4,
            "walking": 8,
            "cross_country_skiing": 16,
            "alpine_skiing": 32,
            "snowboarding": 64,
            "rowing": 128
        }
    },
    "sport_bits_2": {
        "base_type": "uint8z",
        "values": {
            "1": "mountaineering",
            "2": "hiking",
            "4": "multisport",
            "8": "paddling",
            "16": "flying",
            "32": "e_biking",
            "64": "motorcycling",
            "128": "boating",
            "mountaineering": 1,
            "hiking": 2,
            "multisport": 4,
            "paddling": 8,
            "flying": 16,
            "e_biking": 32,
            "motorcycling": 64,
            "boating": 128
        }
    },
    "sport_bits_3": {
        "base_type": "uint8z",
        "values": {
            "1": "driving",
            "2": "golf",
            "4": "hang_gliding",
            "8": "horseback_riding",
            "16": "hunting",
            "32": "fishing",
            "64": "inline_skating",
            "128": "rock_climbing",
            "driving": 1,
            "golf": 2,
            "hang_gliding": 4,
            "horseback_riding": 8,
            "hunting": 16,
            "fishing": 32,
            "inline_skating": 64,
            "rock_climbing": 128
        }
    },
    "sport_bits_4": {
        "base_type": "uint8z",
        "values": {
            "1": "sailing",
            "2": "ice_skating",
            "4": "sky_diving",
            "8": "snowshoeing",
            "16": "snowmobiling",
            "32": "stand_up_paddleboarding",
            "64": "surfing",
            "128": "wakeboarding",
            "sailing": 1,
            "ice_skating": 2,
            "sky_diving": 4,
            "snowshoeing": 8,
            "snowmobiling": 16,
            "stand_up_paddleboarding": 32,
            "surfing": 64,
            "wakeboarding": 128
        }
    },
    "sport_bits_5": {
        "base_type": "uint8z",
        "values": {
            "1": "water_skiing",
            "2": "kayaking",
            "4": "rafting",
            "8": "windsurfing",
            "16": "kitesurfing",
            "32": "tactical",
            "64": "jumpmaster",
            "128": "boxing",
            "water_skiing": 1,
            "kayaking": 2,
            "rafting": 4,
            "windsurfing": 8,
            "kitesurfing": 16,
            "tactical": 32,
            "jumpmaster": 64,
            "boxing": 128
        }
    },
    "sport_bits_6": {
        "base_type": "uint8z",
        "values": {
            "1": "floor_climbing",
            "floor_climbing": 1
        }
    },
    "sub_sport": {
        "base_type": "enum",
        "values": {
            "0": "generic",
            "1": "treadmill",
            "2": "street",
            "3": "trail",
            "4": "track",
            "5": "spin",
            "6": "indoor_cycling",
            "7": "road",
            "8": "mountain",
            "9": "downhill",
            "10": "recumbent",
            "11": "cyclocross",
            "12": "hand_cycling",
            "13": "track_cycling",
            "14": "indoor_rowing",
            "15": "elliptical",
            "16": "stair_climbing",
            "17": "lap_swimming",
            "18": "open_water",
            "19": "flexibility_training",
            "20": "strength_training",
            "21": "warm_up",
            "22": "match",
            "23": "exercise",
            "24": "challenge",
            "25": "indoor_skiing",
            "26": "cardio_training",
            "27": "indoor_walking",
            "28": "e_bike_fitness",
            "29": "bmx",
            "30": "casual_walking",
            "31": "speed_walking",
            "32": "bike_to_run_transition",
            "33": "run_to_bike_transition",
            "34": "swim_to_bike_transition",
            "35": "atv",
            "36": "motocross",
            "37": "backcountry",
            "38": "resort",
            "39": "rc_drone",
            "40": "wingsuit",
            "41": "whitewater",
            "42": "skate_skiing",
            "43": "yoga",
            "44": "pilates",
            "45": "indoor_running",
            "46": "gravel_cycling",
            "47": "e_bike_mountain",
            "48": "commuting",
            "49": "mixed_surface",
            "50": "navigate",
            "51": "track_me",
            "52": "map",
            "53": "single_gas_diving",
            "54": "multi_gas_diving",
            "55": "gauge_diving",
            "56": "apnea_diving",
            "57": "apnea_hunting",
            "58": "virtual_activity",
            "59": "obstacle",
            "65": "sail_race",
            "254": "all",
            "generic": 0,
            "treadmill": 1,
            "street": 2,
            "trail": 3,
            "track": 4,
            "spin": 5,
            "indoor_cycling": 6,
            "road": 7,
            "mountain": 8,
            "downhill": 9,
            "recumbent": 10,
            "cyclocross": 11,
            "hand_cycling": 12,
            "track_cycling": 13,
            "indoor_rowing": 14,
            "elliptical": 15,
            "stair_climbing": 16,
            "lap_swimming": 17,
            "open_water": 18,
            "flexibility_training": 19,
            "strength_training": 20,
            "warm_up": 21,
            "match": 22,
            "exercise": 23,
            "challenge": 24,
            "indoor_skiing": 25,
            "cardio_training": 26,
            "indoor_walking": 27,
            "e_bike_fitness": 28,
            "bmx": 29,
            "casual_walking": 30,
            "speed_walking": 31,
            "bike_to_run_transition": 32,
            "run_to_bike_transition": 33,
            "swim_to_bike_transition": 34,
            "atv": 35,
            "motocross": 36,
            "backcountry": 37,
            "resort": 38,
            "rc_drone": 39,
            "wingsuit": 40,
            "whitewater": 41,
            "skate_skiing": 42,
            "yoga": 43,
            "pilates": 44,
            "indoor_running": 45,
            "gravel_cycling": 46,
            "e_bike_mountain": 47,
            "commuting": 48,
            "mixed_surface": 49,
            "navigate": 50,
            "track_me": 51,
            "map": 52,
            "single_gas_diving": 53,
            "multi_gas_diving": 54,
            "gauge_diving": 55,
            "apnea_diving": 56,
            "apnea_hunting": 57,
            "virtual_activity": 58,
            "obstacle": 59,
            "sail_race": 65,
            "all": 254
        }
    },
    "sport_event": {
        "base_type": "enum",
        "values": {
            "0": "uncategorized",
            "1": "geocaching",
            "2": "fitness",
            "3": "recreation",
            "4": "race",
            "5": "special_event",
            "6": "training",
            "7": "transportation",
            "8": "touring",
            "uncategorized": 0,
            "geocaching": 1,
            "fitness": 2,
            "recreation": 3,
            "race": 4,
            "special_event": 5,
            "training": 6,
            "transportation": 7,
            "touring": 8
        }
    },
    "activity": {
        "base_type": "enum",
        "values": {
            "0": "manual",
            "1": "auto_multi_sport",
            "manual": 0,
            "auto_multi_sport": 1
        }
    },
    "intensity": {
        "base_type": "enum",
        "values": {
            "0": "active",
            "1": "rest",
            "2": "warmup",
            "3": "cooldown",
            "4": "recovery",
            "5": "interval",
            "6": "other",
            "active": 0,
            "rest": 1,
            "warmup": 2,
            "cooldown": 3,
            "recovery": 4,
            "interval": 5,
            "other": 6
        }
    },
    "session_trigger": {
        "base_type": "enum",
        "values": {
            "0": "activity_end",
            "1": "manual",
            "2": "auto_multi_sport",
            "3": "fitness_equipment",
            "activity_end": 0,
            "manual": 1,
            "auto_multi_sport": 2,
            "fitness_equipment": 3
        }
    },
    "autolap_trigger": {
        "base_type": "enum",
        "values": {
            "0": "time",
            "1": "distance",
            "2": "position_start",
            "3": "position_lap",
            "4": "position_waypoint",
            "5": "position_marked",
            "6": "off",
            "time": 0,
            "distance": 1,
            "position_start": 2,
            "position_lap": 3,
            "position_waypoint": 4,
            "position_marked": 5,
            "off": 6
        }
    },
    "lap_trigger": {
        "base_type": "enum",
        "values": {
            "0": "manual",
            "1": "time",
            "2": "distance",
            "3": "position_start",
            "4": "position_lap",
            "5": "position_waypoint",
            "6": "position_marked",
            "7": "session_end",
            "8": "fitness_equipment",
            "manual": 0,
            "time": 1,
            "distance": 2,
            "position_start": 3,
            "position_lap": 4,
            "position_waypoint": 5,
            "position_marked": 6,
            "session_end": 7,
            "fitness_equipment": 8
        }
    },
    "time_mode": {
        "base_type": "enum",
        "values": {
            "0": "hour12",
            "1": "hour24",
            "2": "military",
            "3": "hour_12_with_seconds",
            "4": "hour_24_with_seconds",
            "5": "utc",
            "hour12": 0,
            "hour24": 1,
            "military": 2,
            "hour_12_with_seconds": 3,
            "hour_24_with_seconds": 4,
            "utc": 5
        }
    },
    "backlight_mode": {
        "base_type": "enum",
        "values": {
            "0": "off",
            "1": "manual",
            "2": "key_and_messages",
            "3": "auto_brightness",
            "4": "smart_notifications",
            "5": "key_and_messages_night",
            "6": "key_and_messages_and_smart_notifications",
            "off": 0,
            "manual": 1,
            "key_and_messages": 2,
            "auto_brightness": 3,
            "smart_notifications": 4,
            "key_and_messages_night": 5,
            "key_and_messages_and_smart_notifications": 6
        }
    },
    "date_mode": {
        "base_type": "enum",
        "values": {
            "0": "day_month",
            "1": "month_day",
            "day_month": 0,
            "month_day": 1
        }
    },
    "backlight_timeout": {
        "base_type": "uint8",
        "values": {
            "0": "infinite",
            "infinite": 0
        }
    },
    "event": {
        "base_type": "enum",
        "values": {
            "0": "timer",
            "3": "workout",
            "4": "workout_step",
            "5": "power_down",
            "6": "power_up",
            "7": "off_course",
            "8": "session",
            "9": "lap",
            "10": "course_point",
            "11": "battery",
            "12": "virtual_partner_pace",
            "13": "hr_high_alert",
            "14": "hr_low_alert",
            "15": "speed_high_alert",
            "16": "speed_low_alert",
            "17": "cad_high_alert",
            "18": "cad_low_alert",
            "19": "power_high_alert",
            "20": "power_low_alert",
            "21": "recovery_hr",
            "22": "battery_low",
            "23": "time_duration_alert",
            "24": "distance_duration_alert",
            "25": "calorie_duration_alert",
            "26": "activity",
            "27": "fitness_equipment",
            "28": "length",
            "32": "user_marker",
            "33": "sport_point",
            "36": "calibration",
            "42": "front_gear_change",
            "43": "rear_gear_change",
            "44": "rider_position_change",
            "45": "elev_high_alert",
            "46": "elev_low_alert",
            "47": "comm_timeout",
            "75": "radar_threat_alert",
            "timer": 0,
            "workout": 3,
            "workout_step": 4,
            "power_down": 5,
            "power_up": 6,
            "off_course": 7,
            "session": 8,
            "lap": 9,
            "course_point": 10,
            "battery": 11,
            "virtual_partner_pace": 12,
            "hr_high_alert": 13,
            "hr_low_alert": 14,
            "speed_high_alert": 15,
            "speed_low_alert": 16,
            "cad_high_alert": 17,
            "cad_low_alert": 18,
            "power_high_alert": 19,
            "power_low_alert": 20,
            "recovery_hr": 21,
            "battery_low": 22,
            "time_duration_alert": 23,
            "distance_duration_alert": 24,
            "calorie_duration_alert": 25,
            "activity": 26,
            "fitness_equipment": 27,
            "length": 28,
            "user_marker": 32,
            "sport_point": 33,
            "calibration": 36,
            "front_gear_change": 42,
            "rear_gear_change": 43,
            "rider_position_change": 44,
            "elev_high_alert": 45,
            "elev_low_alert": 46,
            "comm_timeout": 47,
            "radar_threat_alert": 75
        }
    },
    "event_type": {
        "base_type": "enum",
        "values": {
            "0": "start",
            "1": "stop",
            "2": "consecutive_depreciated",
            "3": "marker",
            "4": "stop_all",
            "5": "begin_depreciated",
            "6": "end_depreciated",
            "7": "end_all_depreciated",
            "8": "stop_disable",
            "9": "stop_disable_all",
            "start": 0,
            "stop": 1,
            "consecutive_depreciated": 2,
            "marker": 3,
            "stop_all": 4,
            "begin_depreciated": 5,
            "end_depreciated": 6,
            "end_all_depreciated": 7,
            "stop_disable": 8,
            "stop_disable_all": 9
        }
    },
    "timer_trigger": {
        "base_type": "enum",
        "values": {
            "0": "manual",
            "1": "auto",
            "2": "fitness_equipment",
            "manual": 0,
            "auto": 1,
            "fitness_equipment": 2
        }
    },
    "fitness_equipment_state": {
        "base_type": "enum",
        "values": {
            "0": "ready",
            "1": "in_use",
            "2": "paused",
            "3": "unknown",
            "ready": 0,
            "in_use": 1,
            "paused": 2,
            "unknown": 3
        }
    },
    "tone": {
        "base_type": "enum",
        "values": {
            "0": "off",
            "1": "tone",
            "2": "vibrate",
            "3": "tone_and_vibrate",
            "off": 0,
            "tone": 1,
            "vibrate": 2,
            "tone_and_vibrate": 3
        }
    },
    "autoscroll": {
        "base_type": "enum",
        "values": {
            "0": "none",
            "1": "slow",
            "2": "medium",
            "3": "fast",
            "none": 0,
            "slow": 1,
            "medium": 2,
            "fast": 3
        }
    },
    "activity_class": {
        "base_type": "enum",
        "values": {
            "100": "level_max",
            "127": "level",
            "128": "athlete",
            "level": 127,
            "level_max": 100,
            "athlete": 128
        }
    },
    "hr_zone_calc": {
        "base_type": "enum",
        "values": {
            "0": "custom",
            "1": "percent_max_hr",
            "2": "percent_hrr",
            "custom": 0,
            "percent_max_hr": 1,
            "percent_hrr": 2
        }
    },
    "pwr_zone_calc": {
        "base_type": "enum",
        "values": {
            "0": "custom",
            "1": "percent_ftp",
            "custom": 0,
            "percent_ftp": 1
        }
    },
    "wkt_step_duration": {
        "base_type": "enum",
        "values": {
            "0": "time",
            "1": "distance",
            "2": "hr_less_than",
            "3": "hr_greater_than",
            "4": "calories",
            "5": "open",
            "6": "repeat_until_steps_cmplt",
            "7": "repeat_until_time",
            "8": "repeat_until_distance",
            "9": "repeat_until_calories",
            "10": "repeat_until_hr_less_than",
            "11": "repeat_until_hr_greater_than",
            "12": "repeat_until_power_less_than",
            "13": "repeat_until_power_greater_than",
            "14": "power_less_than",
            "15": "power_greater_than",
            "16": "training_peaks_tss",
            "17": "repeat_until_power_last_lap_less_than",
            "18": "repeat_until_max_power_last_lap_less_than",
            "19": "power_3s_less_than",
            "20": "power_10s_less_than",
            "21": "power_30s_less_than",
            "22": "power_3s_greater_than",
            "23": "power_10s_greater_than",
            "24": "power_30s_greater_than",
            "25": "power_lap_less_than",
            "26": "power_lap_greater_than",
            "27": "repeat_until_training_peaks_tss",
            "28": "repetition_time",
            "29": "reps",
            "31": "time_only",
            "time": 0,
            "distance": 1,
            "hr_less_than": 2,
            "hr_greater_than": 3,
            "calories": 4,
            "open": 5,
            "repeat_until_steps_cmplt": 6,
            "repeat_until_time": 7,
            "repeat_until_distance": 8,
            "repeat_until_calories": 9,
            "repeat_until_hr_less_than": 10,
            "repeat_until_hr_greater_than": 11,
            "repeat_until_power_less_than": 12,
            "repeat_until_power_greater_than": 13,
            "power_less_than": 14,
            "power_greater_than": 15,
            "training_peaks_tss": 16,
            "repeat_until_power_last_lap_less_than": 17,
            "repeat_until_max_power_last_lap_less_than": 18,
            "power_3s_less_than": 19,
            "power_10s_less_than": 20,
            "power_30s_less_than": 21,
            "power_3s_greater_than": 22,
            "power_10s_greater_than": 23,
            "power_30s_greater_than": 24,
            "power_lap_less_than": 25,
            "power_lap_greater_than": 26,
            "repeat_until_training_peaks_tss": 27,
            "repetition_time": 28,
            "reps": 29,
            "time_only": 31
        }
    },
    "wkt_step_target": {
        "base_type": "enum",
        "values": {
            "0": "speed",
            "1": "heart_rate",
            "2": "open",
            "3": "cadence",
            "4": "power",
            "5": "grade",
            "6": "resistance",
            "7": "power_3s",
            "8": "power_10s",
            "9": "power_30s",
            "10": "power_lap",
            "11": "swim_stroke",
            "12": "speed_lap",
            "13": "heart_rate_lap",
            "speed": 0,
            "heart_rate": 1,
            "open": 2,
            "cadence": 3,
            "power": 4,
            "grade": 5,
            "resistance": 6,
            "power_3s": 7,
            "power_10s": 8,
            "power_30s": 9,
            "power_lap": 10,
            "swim_stroke": 11,
            "speed_lap": 12,
            "heart_rate_lap": 13
        }
    },
    "goal": {
        "base_type": "enum",
        "values": {
            "0": "time",
            "1": "distance",
            "2": "calories",
            "3": "frequency",
            "4": "steps",
            "5": "ascent",
            "6": "active_minutes",
            "time": 0,
            "distance": 1,
            "calories": 2,
            "frequency": 3,
            "steps": 4,
            "ascent": 5,
            "active_minutes": 6
        }
    },
    "goal_recurrence": {
        "base_type": "enum",
        "values": {
            "0": "off",
            "1": "daily",
            "2": "weekly",
            "3": "monthly",
            "4": "yearly",
            "5": "custom",
            "off": 0,
            "daily": 1,
            "weekly": 2,
            "monthly": 3,
            "yearly": 4,
            "custom": 5
        }
    },
    "goal_source": {
        "base_type": "enum",
        "values": {
            "0": "auto",
            "1": "community",
            "2": "user",
            "auto": 0,
            "community": 1,
            "user": 2
        }
    },
    "schedule": {
        "base_type": "enum",
        "values": {
            "0": "workout",
            "1": "course",
            "workout": 0,
            "course": 1
        }
    },
    "course_point": {
        "base_type": "enum",
        "values": {
            "0": "generic",
            "1": "summit",
            "2": "valley",
            "3": "water",
            "4": "food",
            "5": "danger",
            "6": "left",
            "7": "right",
            "8": "straight",
            "9": "first_aid",
            "10": "fourth_category",
            "11": "third_category",
            "12": "second_category",
            "13": "first_category",
            "14": "hors_category",
            "15": "sprint",
            "16": "left_fork",
            "17": "right_fork",
            "18": "middle_fork",
            "19": "slight_left",
            "20": "sharp_left",
            "21": "slight_right",
            "22": "sharp_right",
            "23": "u_turn",
            "24": "segment_start",
            "25": "segment_end",
            "generic": 0,
            "summit": 1,
            "valley": 2,
            "water": 3,
            "food": 4,
            "danger": 5,
            "left": 6,
            "right": 7,
            "straight": 8,
            "first_aid": 9,
            "fourth_category": 10,
            "third_category": 11,
            "second_category": 12,
            "first_category": 13,
            "hors_category": 14,
            "sprint": 15,
            "left_fork": 16,
            "right_fork": 17,
            "middle_fork": 18,
            "slight_left": 19,
            "sharp_left": 20,
            "slight_right": 21,
            "sharp_right": 22,
            "u_turn": 23,
            "segment_start": 24,
            "segment_end": 25
        }
    },
    "manufacturer": {
        "base_type": "uint16",
        "values": {
            "1": "garmin",
            "2": "garmin_fr405_antfs",
            "3": "zephyr",
            "4": "dayton",
            "5": "idt",
            "6": "srm",
            "7": "quarq",
            "8": "ibike",
            "9": "saris",
            "10": "spark_hk",
            "11": "tanita",
            "12": "echowell",
            "13": "dynastream_oem",
            "14": "nautilus",
            "15": "dynastream",
            "16": "timex",
            "17": "metrigear",
            "18": "xelic",
            "19": "beurer",
            "20": "cardiosport",
            "21": "a_and_d",
            "22": "hmm",
            "23": "suunto",
            "24": "thita_elektronik",
            "25": "gpulse",
            "26": "clean_mobile",
            "27": "pedal_brain",
            "28": "peaksware",
            "29": "saxonar",
            "30": "lemond_fitness",
            "31": "dexcom",
            "32": "wahoo_fitness",
            "33": "octane_fitness",
            "34": "archinoetics",
            "35": "the_hurt_box",
            "36": "citizen_systems",
            "37": "magellan",
            "38": "osynce",
            "39": "holux",
            "40": "concept2",
            "41": "shimano",
            "42": "one_giant_leap",
            "43": "ace_sensor",
            "44": "brim_brothers",
            "45": "xplova",
            "46": "perception_digital",
            "47": "bf1systems",
            "48": "pioneer",
            "49": "spantec",
            "50": "metalogics",
            "51": "4iiiis",
            "52": "seiko_epson",
            "53": "seiko_epson_oem",
            "54": "ifor_powell",
            "55": "maxwell_guider",
            "56": "star_trac",
            "57": "breakaway",
            "58": "alatech_technology_ltd",
            "59": "mio_technology_europe",
            "60": "rotor",
            "61": "geonaute",
            "62": "id_bike",
            "63": "specialized",
            "64": "wtek",
            "65": "physical_enterprises",
            "66": "north_pole_engineering",
            "67": "bkool",
            "68": "cateye",
            "69": "stages_cycling",
            "70": "sigmasport",
            "71": "tomtom",
            "72": "peripedal",
            "73": "wattbike",
            "76": "moxy",
            "77": "ciclosport",
            "78": "powerbahn",
            "79": "acorn_projects_aps",
            "80": "lifebeam",
            "81": "bontrager",
            "82": "wellgo",
            "83": "scosche",
            "84": "magura",
            "85": "woodway",
            "86": "elite",
            "87": "nielsen_kellerman",
            "88": "dk_city",
            "89": "tacx",
            "90": "direction_technology",
            "91": "magtonic",
            "92": "1partcarbon",
            "93": "inside_ride_technologies",
            "94": "sound_of_motion",
            "95": "stryd",
            "96": "icg",
            "97": "MiPulse",
            "98": "bsx_athletics",
            "99": "look",
            "100": "campagnolo_srl",
            "101": "body_bike_smart",
            "102": "praxisworks",
            "103": "limits_technology",
            "104": "topaction_technology",
            "105": "cosinuss",
            "106": "fitcare",
            "107": "magene",
            "108": "giant_manufacturing_co",
            "109": "tigrasport",
            "110": "salutron",
            "111": "technogym",
            "112": "bryton_sensors",
            "113": "latitude_limited",
            "114": "soaring_technology",
            "115": "igpsport",
            "116": "thinkrider",
            "117": "gopher_sport",
            "118": "waterrower",
            "119": "orangetheory",
            "120": "inpeak",
            "121": "kinetic",
            "122": "johnson_health_tech",
            "123": "polar_electro",
            "124": "seesense",
            "125": "nci_technology",
            "126": "iqsquare",
            "127": "leomo",
            "128": "ifit_com",
            "129": "coros_byte",
            "130": "versa_design",
            "131": "chileaf",
            "132": "cycplus",
            "133": "gravaa_byte",
            "134": "sigeyi",
            "135": "coospo",
            "255": "development",
            "257": "healthandlife",
            "258": "lezyne",
            "259": "scribe_labs",
            "260": "zwift",
            "261": "watteam",
            "262": "recon",
            "263": "favero_electronics",
            "264": "dynovelo",
            "265": "strava",
            "266": "precor",
            "267": "bryton",
            "268": "sram",
            "269": "navman",
            "270": "cobi",
            "271": "spivi",
            "272": "mio_magellan",
            "273": "evesports",
            "274": "sensitivus_gauge",
            "275": "podoon",
            "276": "life_time_fitness",
            "277": "falco_e_motors",
            "278": "minoura",
            "279": "cycliq",
            "280": "luxottica",
            "281": "trainer_road",
            "282": "the_sufferfest",
            "283": "fullspeedahead",
            "284": "virtualtraining",
            "285": "feedbacksports",
            "286": "omata",
            "287": "vdo",
            "288": "magneticdays",
            "289": "hammerhead",
            "290": "kinetic_by_kurt",
            "291": "shapelog",
            "292": "dabuziduo",
            "293": "jetblack",
            "294": "coros",
            "295": "virtugo",
            "296": "velosense",
            "297": "cycligentinc",
            "298": "trailforks",
            "299": "mahle_ebikemotion",
            "300": "nurvv",
            "301": "microprogram",
            "302": "zone5cloud",
            "303": "greenteg",
            "304": "yamaha_motors",
            "305": "whoop",
            "306": "gravaa",
            "307": "onelap",
            "308": "monark_exercise",
            "309": "form",
            "310": "decathlon",
            "311": "syncros",
            "5759": "actigraphcorp",
            "garmin": 1,
            "garmin_fr405_antfs": 2,
            "zephyr": 3,
            "dayton": 4,
            "idt": 5,
            "srm": 6,
            "quarq": 7,
            "ibike": 8,
            "saris": 9,
            "spark_hk": 10,
            "tanita": 11,
            "echowell": 12,
            "dynastream_oem": 13,
            "nautilus": 14,
            "dynastream": 15,
            "timex": 16,
            "metrigear": 17,
            "xelic": 18,
            "beurer": 19,
            "cardiosport": 20,
            "a_and_d": 21,
            "hmm": 22,
            "suunto": 23,
            "thita_elektronik": 24,
            "gpulse": 25,
            "clean_mobile": 26,
            "pedal_brain": 27,
            "peaksware": 28,
            "saxonar": 29,
            "lemond_fitness": 30,
            "dexcom": 31,
            "wahoo_fitness": 32,
            "octane_fitness": 33,
            "archinoetics": 34,
            "the_hurt_box": 35,
            "citizen_systems": 36,
            "magellan": 37,
            "osynce": 38,
            "holux": 39,
            "concept2": 40,
            "shimano": 41,
            "one_giant_leap": 42,
            "ace_sensor": 43,
            "brim_brothers": 44,
            "xplova": 45,
            "perception_digital": 46,
            "bf1systems": 47,
            "pioneer": 48,
            "spantec": 49,
            "metalogics": 50,
            "4iiiis": 51,
            "seiko_epson": 52,
            "seiko_epson_oem": 53,
            "ifor_powell": 54,
            "maxwell_guider": 55,
            "star_trac": 56,
            "breakaway": 57,
            "alatech_technology_ltd": 58,
            "mio_technology_europe": 59,
            "rotor": 60,
            "geonaute": 61,
            "id_bike": 62,
            "specialized": 63,
            "wtek": 64,
            "physical_enterprises": 65,
            "north_pole_engineering": 66,
            "bkool": 67,
            "cateye": 68,
            "stages_cycling": 69,
            "sigmasport": 70,
            "tomtom": 71,
            "peripedal": 72,
            "wattbike": 73,
            "moxy": 76,
            "ciclosport": 77,
            "powerbahn": 78,
            "acorn_projects_aps": 79,
            "lifebeam": 80,
            "bontrager": 81,
            "wellgo": 82,
            "scosche": 83,
            "magura": 84,
            "woodway": 85,
            "elite": 86,
            "nielsen_kellerman": 87,
            "dk_city": 88,
            "tacx": 89,
            "direction_technology": 90,
            "magtonic": 91,
            "1partcarbon": 92,
            "inside_ride_technologies": 93,
            "sound_of_motion": 94,
            "stryd": 95,
            "icg": 96,
            "MiPulse": 97,
            "bsx_athletics": 98,
            "look": 99,
            "campagnolo_srl": 100,
            "body_bike_smart": 101,
            "praxisworks": 102,
            "limits_technology": 103,
            "topaction_technology": 104,
            "cosinuss": 105,
            "fitcare": 106,
            "magene": 107,
            "giant_manufacturing_co": 108,
            "tigrasport": 109,
            "salutron": 110,
            "technogym": 111,
            "bryton_sensors": 112,
            "latitude_limited": 113,
            "soaring_technology": 114,
            "igpsport": 115,
            "thinkrider": 116,
            "gopher_sport": 117,
            "waterrower": 118,
            "orangetheory": 119,
            "inpeak": 120,
            "kinetic": 121,
            "johnson_health_tech": 122,
            "polar_electro": 123,
            "seesense": 124,
            "nci_technology": 125,
            "iqsquare": 126,
            "leomo": 127,
            "ifit_com": 128,
            "coros_byte": 129,
            "versa_design": 130,
            "chileaf": 131,
            "cycplus": 132,
            "gravaa_byte": 133,
            "sigeyi": 134,
            "coospo": 135,
            "development": 255,
            "healthandlife": 257,
            "lezyne": 258,
            "scribe_labs": 259,
            "zwift": 260,
            "watteam": 261,
            "recon": 262,
            "favero_electronics": 263,
            "dynovelo": 264,
            "strava": 265,
            "precor": 266,
            "bryton": 267,
            "sram": 268,
            "navman": 269,
            "cobi": 270,
            "spivi": 271,
            "mio_magellan": 272,
            "evesports": 273,
            "sensitivus_gauge": 274,
            "podoon": 275,
            "life_time_fitness": 276,
            "falco_e_motors": 277,
            "minoura": 278,
            "cycliq": 279,
            "luxottica": 280,
            "trainer_road": 281,
            "the_sufferfest": 282,
            "fullspeedahead": 283,
            "virtualtraining": 284,
            "feedbacksports": 285,
            "omata": 286,
            "vdo": 287,
            "magneticdays": 288,
            "hammerhead": 289,
            "kinetic_by_kurt": 290,
            "shapelog": 291,
            "dabuziduo": 292,
            "jetblack": 293,
            "coros": 294,
            "virtugo": 295,
            "velosense": 296,
            "cycligentinc": 297,
            "trailforks": 298,
            "mahle_ebikemotion": 299,
            "nurvv": 300,
            "microprogram": 301,
            "zone5cloud": 302,
            "greenteg": 303,
            "yamaha_motors": 304,
            "whoop": 305,
            "gravaa": 306,
            "onelap": 307,
            "monark_exercise": 308,
            "form": 309,
            "decathlon": 310,
            "syncros": 311,
            "actigraphcorp": 5759
        }
    },
    "garmin_product": {
        "base_type": "uint16",
        "values": {
            "1": "hrm1",
            "2": "axh01",
            "3": "axb01",
            "4": "axb02",
            "5": "hrm2ss",
            "6": "dsi_alf02",
            "7": "hrm3ss",
            "8": "hrm_run_single_byte_product_id",
            "9": "bsm",
            "10": "bcm",
            "11": "axs01",
            "12": "hrm_tri_single_byte_product_id",
            "13": "hrm4_run_single_byte_product_id",
            "14": "fr225_single_byte_product_id",
            "15": "gen3_bsm_single_byte_product_id",
            "16": "gen3_bcm_single_byte_product_id",
            "473": "fr301_china",
            "474": "fr301_japan",
            "475": "fr301_korea",
            "494": "fr301_taiwan",
            "717": "fr405",
            "782": "fr50",
            "987": "fr405_japan",
            "988": "fr60",
            "1011": "dsi_alf01",
            "1018": "fr310xt",
            "1036": "edge500",
            "1124": "fr110",
            "1169": "edge800",
            "1199": "edge500_taiwan",
            "1213": "edge500_japan",
            "1253": "chirp",
            "1274": "fr110_japan",
            "1325": "edge200",
            "1328": "fr910xt",
            "1333": "edge800_taiwan",
            "1334": "edge800_japan",
            "1341": "alf04",
            "1345": "fr610",
            "1360": "fr210_japan",
            "1380": "vector_ss",
            "1381": "vector_cp",
            "1386": "edge800_china",
            "1387": "edge500_china",
            "1405": "approach_g10",
            "1410": "fr610_japan",
            "1422": "edge500_korea",
            "1436": "fr70",
            "1446": "fr310xt_4t",
            "1461": "amx",
            "1482": "fr10",
            "1497": "edge800_korea",
            "1499": "swim",
            "1537": "fr910xt_china",
            "1551": "fenix",
            "1555": "edge200_taiwan",
            "1561": "edge510",
            "1567": "edge810",
            "1570": "tempe",
            "1600": "fr910xt_japan",
            "1623": "fr620",
            "1632": "fr220",
            "1664": "fr910xt_korea",
            "1688": "fr10_japan",
            "1721": "edge810_japan",
            "1735": "virb_elite",
            "1736": "edge_touring",
            "1742": "edge510_japan",
            "1743": "hrm_tri",
            "1752": "hrm_run",
            "1765": "fr920xt",
            "1821": "edge510_asia",
            "1822": "edge810_china",
            "1823": "edge810_taiwan",
            "1836": "edge1000",
            "1837": "vivo_fit",
            "1853": "virb_remote",
            "1885": "vivo_ki",
            "1903": "fr15",
            "1907": "vivo_active",
            "1918": "edge510_korea",
            "1928": "fr620_japan",
            "1929": "fr620_china",
            "1930": "fr220_japan",
            "1931": "fr220_china",
            "1936": "approach_s6",
            "1956": "vivo_smart",
            "1967": "fenix2",
            "1988": "epix",
            "2050": "fenix3",
            "2052": "edge1000_taiwan",
            "2053": "edge1000_japan",
            "2061": "fr15_japan",
            "2067": "edge520",
            "2070": "edge1000_china",
            "2072": "fr620_russia",
            "2073": "fr220_russia",
            "2079": "vector_s",
            "2100": "edge1000_korea",
            "2130": "fr920xt_taiwan",
            "2131": "fr920xt_china",
            "2132": "fr920xt_japan",
            "2134": "virbx",
            "2135": "vivo_smart_apac",
            "2140": "etrex_touch",
            "2147": "edge25",
            "2148": "fr25",
            "2150": "vivo_fit2",
            "2153": "fr225",
            "2156": "fr630",
            "2157": "fr230",
            "2158": "fr735xt",
            "2160": "vivo_active_apac",
            "2161": "vector_2",
            "2162": "vector_2s",
            "2172": "virbxe",
            "2173": "fr620_taiwan",
            "2174": "fr220_taiwan",
            "2175": "truswing",
            "2187": "d2airvenu",
            "2188": "fenix3_china",
            "2189": "fenix3_twn",
            "2192": "varia_headlight",
            "2193": "varia_taillight_old",
            "2204": "edge_explore_1000",
            "2219": "fr225_asia",
            "2225": "varia_radar_taillight",
            "2226": "varia_radar_display",
            "2238": "edge20",
            "2260": "edge520_asia",
            "2261": "edge520_japan",
            "2262": "d2_bravo",
            "2266": "approach_s20",
            "2271": "vivo_smart2",
            "2274": "edge1000_thai",
            "2276": "varia_remote",
            "2288": "edge25_asia",
            "2289": "edge25_jpn",
            "2290": "edge20_asia",
            "2292": "approach_x40",
            "2293": "fenix3_japan",
            "2294": "vivo_smart_emea",
            "2310": "fr630_asia",
            "2311": "fr630_jpn",
            "2313": "fr230_jpn",
            "2327": "hrm4_run",
            "2332": "epix_japan",
            "2337": "vivo_active_hr",
            "2347": "vivo_smart_gps_hr",
            "2348": "vivo_smart_hr",
            "2361": "vivo_smart_hr_asia",
            "2362": "vivo_smart_gps_hr_asia",
            "2368": "vivo_move",
            "2379": "varia_taillight",
            "2396": "fr235_asia",
            "2397": "fr235_japan",
            "2398": "varia_vision",
            "2406": "vivo_fit3",
            "2407": "fenix3_korea",
            "2408": "fenix3_sea",
            "2413": "fenix3_hr",
            "2417": "virb_ultra_30",
            "2429": "index_smart_scale",
            "2431": "fr235",
            "2432": "fenix3_chronos",
            "2441": "oregon7xx",
            "2444": "rino7xx",
            "2457": "epix_korea",
            "2473": "fenix3_hr_chn",
            "2474": "fenix3_hr_twn",
            "2475": "fenix3_hr_jpn",
            "2476": "fenix3_hr_sea",
            "2477": "fenix3_hr_kor",
            "2496": "nautix",
            "2497": "vivo_active_hr_apac",
            "2512": "oregon7xx_ww",
            "2530": "edge_820",
            "2531": "edge_explore_820",
            "2533": "fr735xt_apac",
            "2534": "fr735xt_japan",
            "2544": "fenix5s",
            "2547": "d2_bravo_titanium",
            "2567": "varia_ut800",
            "2593": "running_dynamics_pod",
            "2599": "edge_820_china",
            "2600": "edge_820_japan",
            "2604": "fenix5x",
            "2606": "vivo_fit_jr",
            "2622": "vivo_smart3",
            "2623": "vivo_sport",
            "2628": "edge_820_taiwan",
            "2629": "edge_820_korea",
            "2630": "edge_820_sea",
            "2650": "fr35_hebrew",
            "2656": "approach_s60",
            "2667": "fr35_apac",
            "2668": "fr35_japan",
            "2675": "fenix3_chronos_asia",
            "2687": "virb_360",
            "2691": "fr935",
            "2697": "fenix5",
            "2700": "vivoactive3",
            "2713": "edge_1030",
            "2733": "fr235_china_nfc",
            "2769": "foretrex_601_701",
            "2772": "vivo_move_hr",
            "2787": "vector_3",
            "2796": "fenix5_asia",
            "2797": "fenix5s_asia",
            "2798": "fenix5x_asia",
            "2806": "approach_z80",
            "2814": "fr35_korea",
            "2819": "d2charlie",
            "2831": "vivo_smart3_apac",
            "2832": "vivo_sport_apac",
            "2833": "fr935_asia",
            "2859": "descent",
            "2878": "vivo_fit4",
            "2886": "fr645",
            "2888": "fr645m",
            "2891": "fr30",
            "2900": "fenix5s_plus",
            "2909": "Edge_130",
            "2924": "edge_1030_asia",
            "2927": "vivosmart_4",
            "2945": "vivo_move_hr_asia",
            "2962": "approach_x10",
            "2977": "fr30_asia",
            "2988": "vivoactive3m_w",
            "3003": "fr645_asia",
            "3004": "fr645m_asia",
            "3011": "edge_explore",
            "3028": "gpsmap66",
            "3049": "approach_s10",
            "3066": "vivoactive3m_l",
            "3085": "approach_g80",
            "3092": "edge_130_asia",
            "3095": "edge_1030_bontrager",
            "3110": "fenix5_plus",
            "3111": "fenix5x_plus",
            "3112": "edge_520_plus",
            "3113": "fr945",
            "3121": "edge_530",
            "3122": "edge_830",
            "3126": "instinct_esports",
            "3134": "fenix5s_plus_apac",
            "3135": "fenix5x_plus_apac",
            "3142": "edge_520_plus_apac",
            "3144": "fr235l_asia",
            "3145": "fr245_asia",
            "3163": "vivo_active3m_apac",
            "3192": "gen3_bsm",
            "3193": "gen3_bcm",
            "3218": "vivo_smart4_asia",
            "3224": "vivoactive4_small",
            "3225": "vivoactive4_large",
            "3226": "venu",
            "3246": "marq_driver",
            "3247": "marq_aviator",
            "3248": "marq_captain",
            "3249": "marq_commander",
            "3250": "marq_expedition",
            "3251": "marq_athlete",
            "3258": "descent_mk2",
            "3284": "gpsmap66i",
            "3287": "fenix6S_sport",
            "3288": "fenix6S",
            "3289": "fenix6_sport",
            "3290": "fenix6",
            "3291": "fenix6x",
            "3299": "hrm_dual",
            "3300": "hrm_pro",
            "3308": "vivo_move3_premium",
            "3314": "approach_s40",
            "3321": "fr245m_asia",
            "3349": "edge_530_apac",
            "3350": "edge_830_apac",
            "3378": "vivo_move3",
            "3387": "vivo_active4_small_asia",
            "3388": "vivo_active4_large_asia",
            "3389": "vivo_active4_oled_asia",
            "3405": "swim2",
            "3420": "marq_driver_asia",
            "3421": "marq_aviator_asia",
            "3422": "vivo_move3_asia",
            "3441": "fr945_asia",
            "3446": "vivo_active3t_chn",
            "3448": "marq_captain_asia",
            "3449": "marq_commander_asia",
            "3450": "marq_expedition_asia",
            "3451": "marq_athlete_asia",
            "3469": "fr45_asia",
            "3473": "vivoactive3_daimler",
            "3498": "legacy_rey",
            "3499": "legacy_darth_vader",
            "3500": "legacy_captain_marvel",
            "3501": "legacy_first_avenger",
            "3512": "fenix6s_sport_asia",
            "3513": "fenix6s_asia",
            "3514": "fenix6_sport_asia",
            "3515": "fenix6_asia",
            "3516": "fenix6x_asia",
            "3535": "legacy_captain_marvel_asia",
            "3536": "legacy_first_avenger_asia",
            "3537": "legacy_rey_asia",
            "3538": "legacy_darth_vader_asia",
            "3542": "descent_mk2s",
            "3558": "edge_130_plus",
            "3570": "edge_1030_plus",
            "3578": "rally_200",
            "3589": "fr745",
            "3600": "venusq",
            "3615": "lily",
            "3624": "marq_adventurer",
            "3638": "enduro",
            "3639": "swim2_apac",
            "3648": "marq_adventurer_asia",
            "3702": "descent_mk2_asia",
            "3703": "venu2",
            "3704": "venu2s",
            "3737": "venu_daimler_asia",
            "3739": "marq_golfer",
            "3740": "venu_daimler",
            "3794": "fr745_asia",
            "3809": "lily_asia",
            "3812": "edge_1030_plus_asia",
            "3813": "edge_130_plus_asia",
            "3823": "approach_s12",
            "3837": "venusq_asia",
            "3850": "marq_golfer_asia",
            "3872": "enduro_asia",
            "3927": "approach_g12",
            "3930": "descent_mk2s_asia",
            "3934": "approach_s42",
            "3949": "venu2s_asia",
            "3950": "venu2_asia",
            "3986": "approach_S12_asia",
            "4001": "approach_g12_asia",
            "4002": "approach_s42_asia",
            "10007": "sdm4",
            "10014": "edge_remote",
            "20119": "training_center",
            "20533": "tacx_training_app_win",
            "20534": "tacx_training_app_mac",
            "30045": "tacx_training_app_android",
            "30046": "tacx_training_app_ios",
            "30047": "tacx_training_app_legacy",
            "65531": "connectiq_simulator",
            "65532": "android_antplus_plugin",
            "65534": "connect",
            "hrm1": 1,
            "axh01": 2,
            "axb01": 3,
            "axb02": 4,
            "hrm2ss": 5,
            "dsi_alf02": 6,
            "hrm3ss": 7,
            "hrm_run_single_byte_product_id": 8,
            "bsm": 9,
            "bcm": 10,
            "axs01": 11,
            "hrm_tri_single_byte_product_id": 12,
            "hrm4_run_single_byte_product_id": 13,
            "fr225_single_byte_product_id": 14,
            "gen3_bsm_single_byte_product_id": 15,
            "gen3_bcm_single_byte_product_id": 16,
            "fr301_china": 473,
            "fr301_japan": 474,
            "fr301_korea": 475,
            "fr301_taiwan": 494,
            "fr405": 717,
            "fr50": 782,
            "fr405_japan": 987,
            "fr60": 988,
            "dsi_alf01": 1011,
            "fr310xt": 1018,
            "edge500": 1036,
            "fr110": 1124,
            "edge800": 1169,
            "edge500_taiwan": 1199,
            "edge500_japan": 1213,
            "chirp": 1253,
            "fr110_japan": 1274,
            "edge200": 1325,
            "fr910xt": 1328,
            "edge800_taiwan": 1333,
            "edge800_japan": 1334,
            "alf04": 1341,
            "fr610": 1345,
            "fr210_japan": 1360,
            "vector_ss": 1380,
            "vector_cp": 1381,
            "edge800_china": 1386,
            "edge500_china": 1387,
            "approach_g10": 1405,
            "fr610_japan": 1410,
            "edge500_korea": 1422,
            "fr70": 1436,
            "fr310xt_4t": 1446,
            "amx": 1461,
            "fr10": 1482,
            "edge800_korea": 1497,
            "swim": 1499,
            "fr910xt_china": 1537,
            "fenix": 1551,
            "edge200_taiwan": 1555,
            "edge510": 1561,
            "edge810": 1567,
            "tempe": 1570,
            "fr910xt_japan": 1600,
            "fr620": 1623,
            "fr220": 1632,
            "fr910xt_korea": 1664,
            "fr10_japan": 1688,
            "edge810_japan": 1721,
            "virb_elite": 1735,
            "edge_touring": 1736,
            "edge510_japan": 1742,
            "hrm_tri": 1743,
            "hrm_run": 1752,
            "fr920xt": 1765,
            "edge510_asia": 1821,
            "edge810_china": 1822,
            "edge810_taiwan": 1823,
            "edge1000": 1836,
            "vivo_fit": 1837,
            "virb_remote": 1853,
            "vivo_ki": 1885,
            "fr15": 1903,
            "vivo_active": 1907,
            "edge510_korea": 1918,
            "fr620_japan": 1928,
            "fr620_china": 1929,
            "fr220_japan": 1930,
            "fr220_china": 1931,
            "approach_s6": 1936,
            "vivo_smart": 1956,
            "fenix2": 1967,
            "epix": 1988,
            "fenix3": 2050,
            "edge1000_taiwan": 2052,
            "edge1000_japan": 2053,
            "fr15_japan": 2061,
            "edge520": 2067,
            "edge1000_china": 2070,
            "fr620_russia": 2072,
            "fr220_russia": 2073,
            "vector_s": 2079,
            "edge1000_korea": 2100,
            "fr920xt_taiwan": 2130,
            "fr920xt_china": 2131,
            "fr920xt_japan": 2132,
            "virbx": 2134,
            "vivo_smart_apac": 2135,
            "etrex_touch": 2140,
            "edge25": 2147,
            "fr25": 2148,
            "vivo_fit2": 2150,
            "fr225": 2153,
            "fr630": 2156,
            "fr230": 2157,
            "fr735xt": 2158,
            "vivo_active_apac": 2160,
            "vector_2": 2161,
            "vector_2s": 2162,
            "virbxe": 2172,
            "fr620_taiwan": 2173,
            "fr220_taiwan": 2174,
            "truswing": 2175,
            "d2airvenu": 2187,
            "fenix3_china": 2188,
            "fenix3_twn": 2189,
            "varia_headlight": 2192,
            "varia_taillight_old": 2193,
            "edge_explore_1000": 2204,
            "fr225_asia": 2219,
            "varia_radar_taillight": 2225,
            "varia_radar_display": 2226,
            "edge20": 2238,
            "edge520_asia": 2260,
            "edge520_japan": 2261,
            "d2_bravo": 2262,
            "approach_s20": 2266,
            "vivo_smart2": 2271,
            "edge1000_thai": 2274,
            "varia_remote": 2276,
            "edge25_asia": 2288,
            "edge25_jpn": 2289,
            "edge20_asia": 2290,
            "approach_x40": 2292,
            "fenix3_japan": 2293,
            "vivo_smart_emea": 2294,
            "fr630_asia": 2310,
            "fr630_jpn": 2311,
            "fr230_jpn": 2313,
            "hrm4_run": 2327,
            "epix_japan": 2332,
            "vivo_active_hr": 2337,
            "vivo_smart_gps_hr": 2347,
            "vivo_smart_hr": 2348,
            "vivo_smart_hr_asia": 2361,
            "vivo_smart_gps_hr_asia": 2362,
            "vivo_move": 2368,
            "varia_taillight": 2379,
            "fr235_asia": 2396,
            "fr235_japan": 2397,
            "varia_vision": 2398,
            "vivo_fit3": 2406,
            "fenix3_korea": 2407,
            "fenix3_sea": 2408,
            "fenix3_hr": 2413,
            "virb_ultra_30": 2417,
            "index_smart_scale": 2429,
            "fr235": 2431,
            "fenix3_chronos": 2432,
            "oregon7xx": 2441,
            "rino7xx": 2444,
            "epix_korea": 2457,
            "fenix3_hr_chn": 2473,
            "fenix3_hr_twn": 2474,
            "fenix3_hr_jpn": 2475,
            "fenix3_hr_sea": 2476,
            "fenix3_hr_kor": 2477,
            "nautix": 2496,
            "vivo_active_hr_apac": 2497,
            "oregon7xx_ww": 2512,
            "edge_820": 2530,
            "edge_explore_820": 2531,
            "fr735xt_apac": 2533,
            "fr735xt_japan": 2534,
            "fenix5s": 2544,
            "d2_bravo_titanium": 2547,
            "varia_ut800": 2567,
            "running_dynamics_pod": 2593,
            "edge_820_china": 2599,
            "edge_820_japan": 2600,
            "fenix5x": 2604,
            "vivo_fit_jr": 2606,
            "vivo_smart3": 2622,
            "vivo_sport": 2623,
            "edge_820_taiwan": 2628,
            "edge_820_korea": 2629,
            "edge_820_sea": 2630,
            "fr35_hebrew": 2650,
            "approach_s60": 2656,
            "fr35_apac": 2667,
            "fr35_japan": 2668,
            "fenix3_chronos_asia": 2675,
            "virb_360": 2687,
            "fr935": 2691,
            "fenix5": 2697,
            "vivoactive3": 2700,
            "fr235_china_nfc": 2733,
            "foretrex_601_701": 2769,
            "vivo_move_hr": 2772,
            "edge_1030": 2713,
            "vector_3": 2787,
            "fenix5_asia": 2796,
            "fenix5s_asia": 2797,
            "fenix5x_asia": 2798,
            "approach_z80": 2806,
            "fr35_korea": 2814,
            "d2charlie": 2819,
            "vivo_smart3_apac": 2831,
            "vivo_sport_apac": 2832,
            "fr935_asia": 2833,
            "descent": 2859,
            "vivo_fit4": 2878,
            "fr645": 2886,
            "fr645m": 2888,
            "fr30": 2891,
            "fenix5s_plus": 2900,
            "Edge_130": 2909,
            "edge_1030_asia": 2924,
            "vivosmart_4": 2927,
            "vivo_move_hr_asia": 2945,
            "approach_x10": 2962,
            "fr30_asia": 2977,
            "vivoactive3m_w": 2988,
            "fr645_asia": 3003,
            "fr645m_asia": 3004,
            "edge_explore": 3011,
            "gpsmap66": 3028,
            "approach_s10": 3049,
            "vivoactive3m_l": 3066,
            "approach_g80": 3085,
            "edge_130_asia": 3092,
            "edge_1030_bontrager": 3095,
            "fenix5_plus": 3110,
            "fenix5x_plus": 3111,
            "edge_520_plus": 3112,
            "fr945": 3113,
            "edge_530": 3121,
            "edge_830": 3122,
            "instinct_esports": 3126,
            "fenix5s_plus_apac": 3134,
            "fenix5x_plus_apac": 3135,
            "edge_520_plus_apac": 3142,
            "fr235l_asia": 3144,
            "fr245_asia": 3145,
            "vivo_active3m_apac": 3163,
            "gen3_bsm": 3192,
            "gen3_bcm": 3193,
            "vivo_smart4_asia": 3218,
            "vivoactive4_small": 3224,
            "vivoactive4_large": 3225,
            "venu": 3226,
            "marq_driver": 3246,
            "marq_aviator": 3247,
            "marq_captain": 3248,
            "marq_commander": 3249,
            "marq_expedition": 3250,
            "marq_athlete": 3251,
            "descent_mk2": 3258,
            "gpsmap66i": 3284,
            "fenix6S_sport": 3287,
            "fenix6S": 3288,
            "fenix6_sport": 3289,
            "fenix6": 3290,
            "fenix6x": 3291,
            "hrm_dual": 3299,
            "hrm_pro": 3300,
            "vivo_move3_premium": 3308,
            "approach_s40": 3314,
            "fr245m_asia": 3321,
            "edge_530_apac": 3349,
            "edge_830_apac": 3350,
            "vivo_move3": 3378,
            "vivo_active4_small_asia": 3387,
            "vivo_active4_large_asia": 3388,
            "vivo_active4_oled_asia": 3389,
            "swim2": 3405,
            "marq_driver_asia": 3420,
            "marq_aviator_asia": 3421,
            "vivo_move3_asia": 3422,
            "fr945_asia": 3441,
            "vivo_active3t_chn": 3446,
            "marq_captain_asia": 3448,
            "marq_commander_asia": 3449,
            "marq_expedition_asia": 3450,
            "marq_athlete_asia": 3451,
            "fr45_asia": 3469,
            "vivoactive3_daimler": 3473,
            "legacy_rey": 3498,
            "legacy_darth_vader": 3499,
            "legacy_captain_marvel": 3500,
            "legacy_first_avenger": 3501,
            "fenix6s_sport_asia": 3512,
            "fenix6s_asia": 3513,
            "fenix6_sport_asia": 3514,
            "fenix6_asia": 3515,
            "fenix6x_asia": 3516,
            "legacy_captain_marvel_asia": 3535,
            "legacy_first_avenger_asia": 3536,
            "legacy_rey_asia": 3537,
            "legacy_darth_vader_asia": 3538,
            "descent_mk2s": 3542,
            "edge_130_plus": 3558,
            "edge_1030_plus": 3570,
            "rally_200": 3578,
            "fr745": 3589,
            "venusq": 3600,
            "lily": 3615,
            "marq_adventurer": 3624,
            "enduro": 3638,
            "marq_adventurer_asia": 3648,
            "swim2_apac": 3639,
            "descent_mk2_asia": 3702,
            "venu2": 3703,
            "venu2s": 3704,
            "venu_daimler_asia": 3737,
            "marq_golfer": 3739,
            "venu_daimler": 3740,
            "fr745_asia": 3794,
            "lily_asia": 3809,
            "edge_1030_plus_asia": 3812,
            "edge_130_plus_asia": 3813,
            "approach_s12": 3823,
            "enduro_asia": 3872,
            "venusq_asia": 3837,
            "marq_golfer_asia": 3850,
            "approach_g12": 3927,
            "descent_mk2s_asia": 3930,
            "approach_s42": 3934,
            "venu2s_asia": 3949,
            "venu2_asia": 3950,
            "approach_S12_asia": 3986,
            "approach_g12_asia": 4001,
            "approach_s42_asia": 4002,
            "sdm4": 10007,
            "edge_remote": 10014,
            "tacx_training_app_win": 20533,
            "tacx_training_app_mac": 20534,
            "training_center": 20119,
            "tacx_training_app_android": 30045,
            "tacx_training_app_ios": 30046,
            "tacx_training_app_legacy": 30047,
            "connectiq_simulator": 65531,
            "android_antplus_plugin": 65532,
            "connect": 65534
        }
    },
    "antplus_device_type": {
        "base_type": "uint8",
        "values": {
            "1": "antfs",
            "11": "bike_power",
            "12": "environment_sensor_legacy",
            "15": "multi_sport_speed_distance",
            "16": "control",
            "17": "fitness_equipment",
            "18": "blood_pressure",
            "19": "geocache_node",
            "20": "light_electric_vehicle",
            "25": "env_sensor",
            "26": "racquet",
            "27": "control_hub",
            "31": "muscle_oxygen",
            "35": "bike_light_main",
            "36": "bike_light_shared",
            "38": "exd",
            "40": "bike_radar",
            "46": "bike_aero",
            "119": "weight_scale",
            "120": "heart_rate",
            "121": "bike_speed_cadence",
            "122": "bike_cadence",
            "123": "bike_speed",
            "124": "stride_speed_distance",
            "antfs": 1,
            "bike_power": 11,
            "environment_sensor_legacy": 12,
            "multi_sport_speed_distance": 15,
            "control": 16,
            "fitness_equipment": 17,
            "blood_pressure": 18,
            "geocache_node": 19,
            "light_electric_vehicle": 20,
            "env_sensor": 25,
            "racquet": 26,
            "control_hub": 27,
            "muscle_oxygen": 31,
            "bike_light_main": 35,
            "bike_light_shared": 36,
            "exd": 38,
            "bike_radar": 40,
            "bike_aero": 46,
            "weight_scale": 119,
            "heart_rate": 120,
            "bike_speed_cadence": 121,
            "bike_cadence": 122,
            "bike_speed": 123,
            "stride_speed_distance": 124
        }
    },
    "ant_network": {
        "base_type": "enum",
        "values": {
            "0": "public",
            "1": "antplus",
            "2": "antfs",
            "3": "private",
            "public": 0,
            "antplus": 1,
            "antfs": 2,
            "private": 3
        }
    },
    "workout_capabilities": {
        "base_type": "uint32z",
        "values": {
            "1": "interval",
            "2": "custom",
            "4": "fitness_equipment",
            "8": "firstbeat",
            "16": "new_leaf",
            "32": "tcx",
            "128": "speed",
            "256": "heart_rate",
            "512": "distance",
            "1024": "cadence",
            "2048": "power",
            "4096": "grade",
            "8192": "resistance",
            "16384": "protected",
            "interval": 1,
            "custom": 2,
            "fitness_equipment": 4,
            "firstbeat": 8,
            "new_leaf": 16,
            "tcx": 32,
            "speed": 128,
            "heart_rate": 256,
            "distance": 512,
            "cadence": 1024,
            "power": 2048,
            "grade": 4096,
            "resistance": 8192,
            "protected": 16384
        }
    },
    "battery_status": {
        "base_type": "uint8",
        "values": {
            "1": "new",
            "2": "good",
            "3": "ok",
            "4": "low",
            "5": "critical",
            "6": "charging",
            "7": "unknown",
            "new": 1,
            "good": 2,
            "ok": 3,
            "low": 4,
            "critical": 5,
            "charging": 6,
            "unknown": 7
        }
    },
    "hr_type": {
        "base_type": "enum",
        "values": {
            "0": "normal",
            "1": "irregular",
            "normal": 0,
            "irregular": 1
        }
    },
    "course_capabilities": {
        "base_type": "uint32z",
        "values": {
            "1": "processed",
            "2": "valid",
            "4": "time",
            "8": "distance",
            "16": "position",
            "32": "heart_rate",
            "64": "power",
            "128": "cadence",
            "256": "training",
            "512": "navigation",
            "1024": "bikeway",
            "processed": 1,
            "valid": 2,
            "time": 4,
            "distance": 8,
            "position": 16,
            "heart_rate": 32,
            "power": 64,
            "cadence": 128,
            "training": 256,
            "navigation": 512,
            "bikeway": 1024
        }
    },
    "weight": {
        "base_type": "uint16",
        "values": {
            "65534": "calculating",
            "calculating": 65534
        }
    },
    "workout_hr": {
        "base_type": "uint32",
        "values": {
            "100": "bpm_offset",
            "bpm_offset": 100
        }
    },
    "workout_power": {
        "base_type": "uint32",
        "values": {
            "1000": "watts_offset",
            "watts_offset": 1000
        }
    },
    "bp_status": {
        "base_type": "enum",
        "values": {
            "0": "no_error",
            "1": "error_incomplete_data",
            "2": "error_no_measurement",
            "3": "error_data_out_of_range",
            "4": "error_irregular_heart_rate",
            "no_error": 0,
            "error_incomplete_data": 1,
            "error_no_measurement": 2,
            "error_data_out_of_range": 3,
            "error_irregular_heart_rate": 4
        }
    },
    "user_local_id": {
        "base_type": "uint16",
        "values": {
            "0": "local_min",
            "15": "local_max",
            "16": "stationary_min",
            "255": "stationary_max",
            "256": "portable_min",
            "65534": "portable_max",
            "local_min": 0,
            "local_max": 15,
            "stationary_min": 16,
            "stationary_max": 255,
            "portable_min": 256,
            "portable_max": 65534
        }
    },
    "swim_stroke": {
        "base_type": "enum",
        "values": {
            "0": "freestyle",
            "1": "backstroke",
            "2": "breaststroke",
            "3": "butterfly",
            "4": "drill",
            "5": "mixed",
            "6": "im",
            "freestyle": 0,
            "backstroke": 1,
            "breaststroke": 2,
            "butterfly": 3,
            "drill": 4,
            "mixed": 5,
            "im": 6
        }
    },
    "activity_type": {
        "base_type": "enum",
        "values": {
            "0": "generic",
            "1": "running",
            "2": "cycling",
            "3": "transition",
            "4": "fitness_equipment",
            "5": "swimming",
            "6": "walking",
            "8": "sedentary",
            "254": "all",
            "generic": 0,
            "running": 1,
            "cycling": 2,
            "transition": 3,
            "fitness_equipment": 4,
            "swimming": 5,
            "walking": 6,
            "sedentary": 8,
            "all": 254
        }
    },
    "activity_subtype": {
        "base_type": "enum",
        "values": {
            "0": "generic",
            "1": "treadmill",
            "2": "street",
            "3": "trail",
            "4": "track",
            "5": "spin",
            "6": "indoor_cycling",
            "7": "road",
            "8": "mountain",
            "9": "downhill",
            "10": "recumbent",
            "11": "cyclocross",
            "12": "hand_cycling",
            "13": "track_cycling",
            "14": "indoor_rowing",
            "15": "elliptical",
            "16": "stair_climbing",
            "17": "lap_swimming",
            "18": "open_water",
            "254": "all",
            "generic": 0,
            "treadmill": 1,
            "street": 2,
            "trail": 3,
            "track": 4,
            "spin": 5,
            "indoor_cycling": 6,
            "road": 7,
            "mountain": 8,
            "downhill": 9,
            "recumbent": 10,
            "cyclocross": 11,
            "hand_cycling": 12,
            "track_cycling": 13,
            "indoor_rowing": 14,
            "elliptical": 15,
            "stair_climbing": 16,
            "lap_swimming": 17,
            "open_water": 18,
            "all": 254
        }
    },
    "activity_level": {
        "base_type": "enum",
        "values": {
            "0": "low",
            "1": "medium",
            "2": "high",
            "low": 0,
            "medium": 1,
            "high": 2
        }
    },
    "side": {
        "base_type": "enum",
        "values": {
            "0": "right",
            "1": "left",
            "right": 0,
            "left": 1
        }
    },
    "left_right_balance": {
        "base_type": "uint8",
        "values": {
            "127": "mask",
            "128": "right",
            "mask": 127,
            "right": 128
        }
    },
    "left_right_balance_100": {
        "base_type": "uint16",
        "values": {
            "16383": "mask",
            "32768": "right",
            "mask": 16383,
            "right": 32768
        }
    },
    "length_type": {
        "base_type": "enum",
        "values": {
            "0": "idle",
            "1": "active",
            "idle": 0,
            "active": 1
        }
    },
    "day_of_week": {
        "base_type": "enum",
        "values": {
            "0": "sunday",
            "1": "monday",
            "2": "tuesday",
            "3": "wednesday",
            "4": "thursday",
            "5": "friday",
            "6": "saturday",
            "sunday": 0,
            "monday": 1,
            "tuesday": 2,
            "wednesday": 3,
            "thursday": 4,
            "friday": 5,
            "saturday": 6
        }
    },
    "connectivity_capabilities": {
        "base_type": "uint32z",
        "values": {
            "1": "bluetooth",
            "2": "bluetooth_le",
            "4": "ant",
            "8": "activity_upload",
            "16": "course_download",
            "32": "workout_download",
            "64": "live_track",
            "128": "weather_conditions",
            "256": "weather_alerts",
            "512": "gps_ephemeris_download",
            "1024": "explicit_archive",
            "2048": "setup_incomplete",
            "4096": "continue_sync_after_software_update",
            "8192": "connect_iq_app_download",
            "16384": "golf_course_download",
            "32768": "device_initiates_sync",
            "65536": "connect_iq_watch_app_download",
            "131072": "connect_iq_widget_download",
            "262144": "connect_iq_watch_face_download",
            "524288": "connect_iq_data_field_download",
            "1048576": "connect_iq_app_managment",
            "2097152": "swing_sensor",
            "4194304": "swing_sensor_remote",
            "8388608": "incident_detection",
            "16777216": "audio_prompts",
            "33554432": "wifi_verification",
            "67108864": "true_up",
            "134217728": "find_my_watch",
            "268435456": "remote_manual_sync",
            "536870912": "live_track_auto_start",
            "1073741824": "live_track_messaging",
            "2147483648": "instant_input",
            "bluetooth": 1,
            "bluetooth_le": 2,
            "ant": 4,
            "activity_upload": 8,
            "course_download": 16,
            "workout_download": 32,
            "live_track": 64,
            "weather_conditions": 128,
            "weather_alerts": 256,
            "gps_ephemeris_download": 512,
            "explicit_archive": 1024,
            "setup_incomplete": 2048,
            "continue_sync_after_software_update": 4096,
            "connect_iq_app_download": 8192,
            "golf_course_download": 16384,
            "device_initiates_sync": 32768,
            "connect_iq_watch_app_download": 65536,
            "connect_iq_widget_download": 131072,
            "connect_iq_watch_face_download": 262144,
            "connect_iq_data_field_download": 524288,
            "connect_iq_app_managment": 1048576,
            "swing_sensor": 2097152,
            "swing_sensor_remote": 4194304,
            "incident_detection": 8388608,
            "audio_prompts": 16777216,
            "wifi_verification": 33554432,
            "true_up": 67108864,
            "find_my_watch": 134217728,
            "remote_manual_sync": 268435456,
            "live_track_auto_start": 536870912,
            "live_track_messaging": 1073741824,
            "instant_input": 2147483648
        }
    },
    "weather_report": {
        "base_type": "enum",
        "values": {
            "0": "current",
            "1": "hourly_forecast",
            "2": "daily_forecast",
            "current": 0,
            "forecast": 1,
            "hourly_forecast": 1,
            "daily_forecast": 2
        }
    },
    "weather_status": {
        "base_type": "enum",
        "values": {
            "0": "clear",
            "1": "partly_cloudy",
            "2": "mostly_cloudy",
            "3": "rain",
            "4": "snow",
            "5": "windy",
            "6": "thunderstorms",
            "7": "wintry_mix",
            "8": "fog",
            "11": "hazy",
            "12": "hail",
            "13": "scattered_showers",
            "14": "scattered_thunderstorms",
            "15": "unknown_precipitation",
            "16": "light_rain",
            "17": "heavy_rain",
            "18": "light_snow",
            "19": "heavy_snow",
            "20": "light_rain_snow",
            "21": "heavy_rain_snow",
            "22": "cloudy",
            "clear": 0,
            "partly_cloudy": 1,
            "mostly_cloudy": 2,
            "rain": 3,
            "snow": 4,
            "windy": 5,
            "thunderstorms": 6,
            "wintry_mix": 7,
            "fog": 8,
            "hazy": 11,
            "hail": 12,
            "scattered_showers": 13,
            "scattered_thunderstorms": 14,
            "unknown_precipitation": 15,
            "light_rain": 16,
            "heavy_rain": 17,
            "light_snow": 18,
            "heavy_snow": 19,
            "light_rain_snow": 20,
            "heavy_rain_snow": 21,
            "cloudy": 22
        }
    },
    "weather_severity": {
        "base_type": "enum",
        "values": {
            "0": "unknown",
            "1": "warning",
            "2": "watch",
            "3": "advisory",
            "4": "statement",
            "unknown": 0,
            "warning": 1,
            "watch": 2,
            "advisory": 3,
            "statement": 4
        }
    },
    "weather_severe_type": {
        "base_type": "enum",
        "values": {
            "0": "unspecified",
            "1": "tornado",
            "2": "tsunami",
            "3": "hurricane",
            "4": "extreme_wind",
            "5": "typhoon",
            "6": "inland_hurricane",
            "7": "hurricane_force_wind",
            "8": "waterspout",
            "9": "severe_thunderstorm",
            "10": "wreckhouse_winds",
            "11": "les_suetes_wind",
            "12": "avalanche",
            "13": "flash_flood",
            "14": "tropical_storm",
            "15": "inland_tropical_storm",
            "16": "blizzard",
            "17": "ice_storm",
            "18": "freezing_rain",
            "19": "debris_flow",
            "20": "flash_freeze",
            "21": "dust_storm",
            "22": "high_wind",
            "23": "winter_storm",
            "24": "heavy_freezing_spray",
            "25": "extreme_cold",
            "26": "wind_chill",
            "27": "cold_wave",
            "28": "heavy_snow_alert",
            "29": "lake_effect_blowing_snow",
            "30": "snow_squall",
            "31": "lake_effect_snow",
            "32": "winter_weather",
            "33": "sleet",
            "34": "snowfall",
            "35": "snow_and_blowing_snow",
            "36": "blowing_snow",
            "37": "snow_alert",
            "38": "arctic_outflow",
            "39": "freezing_drizzle",
            "40": "storm",
            "41": "storm_surge",
            "42": "rainfall",
            "43": "areal_flood",
            "44": "coastal_flood",
            "45": "lakeshore_flood",
            "46": "excessive_heat",
            "47": "heat",
            "48": "weather",
            "49": "high_heat_and_humidity",
            "50": "humidex_and_health",
            "51": "humidex",
            "52": "gale",
            "53": "freezing_spray",
            "54": "special_marine",
            "55": "squall",
            "56": "strong_wind",
            "57": "lake_wind",
            "58": "marine_weather",
            "59": "wind",
            "60": "small_craft_hazardous_seas",
            "61": "hazardous_seas",
            "62": "small_craft",
            "63": "small_craft_winds",
            "64": "small_craft_rough_bar",
            "65": "high_water_level",
            "66": "ashfall",
            "67": "freezing_fog",
            "68": "dense_fog",
            "69": "dense_smoke",
            "70": "blowing_dust",
            "71": "hard_freeze",
            "72": "freeze",
            "73": "frost",
            "74": "fire_weather",
            "75": "flood",
            "76": "rip_tide",
            "77": "high_surf",
            "78": "smog",
            "79": "air_quality",
            "80": "brisk_wind",
            "81": "air_stagnation",
            "82": "low_water",
            "83": "hydrological",
            "84": "special_weather",
            "unspecified": 0,
            "tornado": 1,
            "tsunami": 2,
            "hurricane": 3,
            "extreme_wind": 4,
            "typhoon": 5,
            "inland_hurricane": 6,
            "hurricane_force_wind": 7,
            "waterspout": 8,
            "severe_thunderstorm": 9,
            "wreckhouse_winds": 10,
            "les_suetes_wind": 11,
            "avalanche": 12,
            "flash_flood": 13,
            "tropical_storm": 14,
            "inland_tropical_storm": 15,
            "blizzard": 16,
            "ice_storm": 17,
            "freezing_rain": 18,
            "debris_flow": 19,
            "flash_freeze": 20,
            "dust_storm": 21,
            "high_wind": 22,
            "winter_storm": 23,
            "heavy_freezing_spray": 24,
            "extreme_cold": 25,
            "wind_chill": 26,
            "cold_wave": 27,
            "heavy_snow_alert": 28,
            "lake_effect_blowing_snow": 29,
            "snow_squall": 30,
            "lake_effect_snow": 31,
            "winter_weather": 32,
            "sleet": 33,
            "snowfall": 34,
            "snow_and_blowing_snow": 35,
            "blowing_snow": 36,
            "snow_alert": 37,
            "arctic_outflow": 38,
            "freezing_drizzle": 39,
            "storm": 40,
            "storm_surge": 41,
            "rainfall": 42,
            "areal_flood": 43,
            "coastal_flood": 44,
            "lakeshore_flood": 45,
            "excessive_heat": 46,
            "heat": 47,
            "weather": 48,
            "high_heat_and_humidity": 49,
            "humidex_and_health": 50,
            "humidex": 51,
            "gale": 52,
            "freezing_spray": 53,
            "special_marine": 54,
            "squall": 55,
            "strong_wind": 56,
            "lake_wind": 57,
            "marine_weather": 58,
            "wind": 59,
            "small_craft_hazardous_seas": 60,
            "hazardous_seas": 61,
            "small_craft": 62,
            "small_craft_winds": 63,
            "small_craft_rough_bar": 64,
            "high_water_level": 65,
            "ashfall": 66,
            "freezing_fog": 67,
            "dense_fog": 68,
            "dense_smoke": 69,
            "blowing_dust": 70,
            "hard_freeze": 71,
            "freeze": 72,
            "frost": 73,
            "fire_weather": 74,
            "flood": 75,
            "rip_tide": 76,
            "high_surf": 77,
            "smog": 78,
            "air_quality": 79,
            "brisk_wind": 80,
            "air_stagnation": 81,
            "low_water": 82,
            "hydrological": 83,
            "special_weather": 84
        }
    },
    "time_into_day": {
        "base_type": "uint32",
        "values": {}
    },
    "localtime_into_day": {
        "base_type": "uint32",
        "values": {}
    },
    "stroke_type": {
        "base_type": "enum",
        "values": {
            "0": "no_event",
            "1": "other",
            "2": "serve",
            "3": "forehand",
            "4": "backhand",
            "5": "smash",
            "no_event": 0,
            "other": 1,
            "serve": 2,
            "forehand": 3,
            "backhand": 4,
            "smash": 5
        }
    },
    "body_location": {
        "base_type": "enum",
        "values": {
            "0": "left_leg",
            "1": "left_calf",
            "2": "left_shin",
            "3": "left_hamstring",
            "4": "left_quad",
            "5": "left_glute",
            "6": "right_leg",
            "7": "right_calf",
            "8": "right_shin",
            "9": "right_hamstring",
            "10": "right_quad",
            "11": "right_glute",
            "12": "torso_back",
            "13": "left_lower_back",
            "14": "left_upper_back",
            "15": "right_lower_back",
            "16": "right_upper_back",
            "17": "torso_front",
            "18": "left_abdomen",
            "19": "left_chest",
            "20": "right_abdomen",
            "21": "right_chest",
            "22": "left_arm",
            "23": "left_shoulder",
            "24": "left_bicep",
            "25": "left_tricep",
            "26": "left_brachioradialis",
            "27": "left_forearm_extensors",
            "28": "right_arm",
            "29": "right_shoulder",
            "30": "right_bicep",
            "31": "right_tricep",
            "32": "right_brachioradialis",
            "33": "right_forearm_extensors",
            "34": "neck",
            "35": "throat",
            "36": "waist_mid_back",
            "37": "waist_front",
            "38": "waist_left",
            "39": "waist_right",
            "left_leg": 0,
            "left_calf": 1,
            "left_shin": 2,
            "left_hamstring": 3,
            "left_quad": 4,
            "left_glute": 5,
            "right_leg": 6,
            "right_calf": 7,
            "right_shin": 8,
            "right_hamstring": 9,
            "right_quad": 10,
            "right_glute": 11,
            "torso_back": 12,
            "left_lower_back": 13,
            "left_upper_back": 14,
            "right_lower_back": 15,
            "right_upper_back": 16,
            "torso_front": 17,
            "left_abdomen": 18,
            "left_chest": 19,
            "right_abdomen": 20,
            "right_chest": 21,
            "left_arm": 22,
            "left_shoulder": 23,
            "left_bicep": 24,
            "left_tricep": 25,
            "left_brachioradialis": 26,
            "left_forearm_extensors": 27,
            "right_arm": 28,
            "right_shoulder": 29,
            "right_bicep": 30,
            "right_tricep": 31,
            "right_brachioradialis": 32,
            "right_forearm_extensors": 33,
            "neck": 34,
            "throat": 35,
            "waist_mid_back": 36,
            "waist_front": 37,
            "waist_left": 38,
            "waist_right": 39
        }
    },
    "segment_lap_status": {
        "base_type": "enum",
        "values": {
            "0": "end",
            "1": "fail",
            "end": 0,
            "fail": 1
        }
    },
    "segment_leaderboard_type": {
        "base_type": "enum",
        "values": {
            "0": "overall",
            "1": "personal_best",
            "2": "connections",
            "3": "group",
            "4": "challenger",
            "5": "kom",
            "6": "qom",
            "7": "pr",
            "8": "goal",
            "9": "rival",
            "10": "club_leader",
            "overall": 0,
            "personal_best": 1,
            "connections": 2,
            "group": 3,
            "challenger": 4,
            "kom": 5,
            "qom": 6,
            "pr": 7,
            "goal": 8,
            "rival": 9,
            "club_leader": 10
        }
    },
    "segment_delete_status": {
        "base_type": "enum",
        "values": {
            "0": "do_not_delete",
            "1": "delete_one",
            "2": "delete_all",
            "do_not_delete": 0,
            "delete_one": 1,
            "delete_all": 2
        }
    },
    "segment_selection_type": {
        "base_type": "enum",
        "values": {
            "0": "starred",
            "1": "suggested",
            "starred": 0,
            "suggested": 1
        }
    },
    "source_type": {
        "base_type": "enum",
        "values": {
            "0": "ant",
            "1": "antplus",
            "2": "bluetooth",
            "3": "bluetooth_low_energy",
            "4": "wifi",
            "5": "local",
            "ant": 0,
            "antplus": 1,
            "bluetooth": 2,
            "bluetooth_low_energy": 3,
            "wifi": 4,
            "local": 5
        }
    },
    "local_device_type": {
        "base_type": "uint8",
        "values": {}
    },
    "display_orientation": {
        "base_type": "enum",
        "values": {
            "0": "auto",
            "1": "portrait",
            "2": "landscape",
            "3": "portrait_flipped",
            "4": "landscape_flipped",
            "auto": 0,
            "portrait": 1,
            "landscape": 2,
            "portrait_flipped": 3,
            "landscape_flipped": 4
        }
    },
    "workout_equipment": {
        "base_type": "enum",
        "values": {
            "0": "none",
            "1": "swim_fins",
            "2": "swim_kickboard",
            "3": "swim_paddles",
            "4": "swim_pull_buoy",
            "5": "swim_snorkel",
            "none": 0,
            "swim_fins": 1,
            "swim_kickboard": 2,
            "swim_paddles": 3,
            "swim_pull_buoy": 4,
            "swim_snorkel": 5
        }
    },
    "watchface_mode": {
        "base_type": "enum",
        "values": {
            "0": "digital",
            "1": "analog",
            "2": "connect_iq",
            "3": "disabled",
            "digital": 0,
            "analog": 1,
            "connect_iq": 2,
            "disabled": 3
        }
    },
    "digital_watchface_layout": {
        "base_type": "enum",
        "values": {
            "0": "traditional",
            "1": "modern",
            "2": "bold",
            "traditional": 0,
            "modern": 1,
            "bold": 2
        }
    },
    "analog_watchface_layout": {
        "base_type": "enum",
        "values": {
            "0": "minimal",
            "1": "traditional",
            "2": "modern",
            "minimal": 0,
            "traditional": 1,
            "modern": 2
        }
    },
    "rider_position_type": {
        "base_type": "enum",
        "values": {
            "0": "seated",
            "1": "standing",
            "2": "transition_to_seated",
            "3": "transition_to_standing",
            "seated": 0,
            "standing": 1,
            "transition_to_seated": 2,
            "transition_to_standing": 3
        }
    },
    "power_phase_type": {
        "base_type": "enum",
        "values": {
            "0": "power_phase_start_angle",
            "1": "power_phase_end_angle",
            "2": "power_phase_arc_length",
            "3": "power_phase_center",
            "power_phase_start_angle": 0,
            "power_phase_end_angle": 1,
            "power_phase_arc_length": 2,
            "power_phase_center": 3
        }
    },
    "camera_event_type": {
        "base_type": "enum",
        "values": {
            "0": "video_start",
            "1": "video_split",
            "2": "video_end",
            "3": "photo_taken",
            "4": "video_second_stream_start",
            "5": "video_second_stream_split",
            "6": "video_second_stream_end",
            "7": "video_split_start",
            "8": "video_second_stream_split_start",
            "11": "video_pause",
            "12": "video_second_stream_pause",
            "13": "video_resume",
            "14": "video_second_stream_resume",
            "video_start": 0,
            "video_split": 1,
            "video_end": 2,
            "photo_taken": 3,
            "video_second_stream_start": 4,
            "video_second_stream_split": 5,
            "video_second_stream_end": 6,
            "video_split_start": 7,
            "video_second_stream_split_start": 8,
            "video_pause": 11,
            "video_second_stream_pause": 12,
            "video_resume": 13,
            "video_second_stream_resume": 14
        }
    },
    "sensor_type": {
        "base_type": "enum",
        "values": {
            "0": "accelerometer",
            "1": "gyroscope",
            "2": "compass",
            "3": "barometer",
            "accelerometer": 0,
            "gyroscope": 1,
            "compass": 2,
            "barometer": 3
        }
    },
    "bike_light_network_config_type": {
        "base_type": "enum",
        "values": {
            "0": "auto",
            "4": "individual",
            "5": "high_visibility",
            "6": "trail",
            "auto": 0,
            "individual": 4,
            "high_visibility": 5,
            "trail": 6
        }
    },
    "comm_timeout_type": {
        "base_type": "uint16",
        "values": {
            "0": "wildcard_pairing_timeout",
            "1": "pairing_timeout",
            "2": "connection_lost",
            "3": "connection_timeout",
            "wildcard_pairing_timeout": 0,
            "pairing_timeout": 1,
            "connection_lost": 2,
            "connection_timeout": 3
        }
    },
    "camera_orientation_type": {
        "base_type": "enum",
        "values": {
            "0": "camera_orientation_0",
            "1": "camera_orientation_90",
            "2": "camera_orientation_180",
            "3": "camera_orientation_270",
            "camera_orientation_0": 0,
            "camera_orientation_90": 1,
            "camera_orientation_180": 2,
            "camera_orientation_270": 3
        }
    },
    "attitude_stage": {
        "base_type": "enum",
        "values": {
            "0": "failed",
            "1": "aligning",
            "2": "degraded",
            "3": "valid",
            "failed": 0,
            "aligning": 1,
            "degraded": 2,
            "valid": 3
        }
    },
    "attitude_validity": {
        "base_type": "uint16",
        "values": {
            "1": "track_angle_heading_valid",
            "2": "pitch_valid",
            "4": "roll_valid",
            "8": "lateral_body_accel_valid",
            "16": "normal_body_accel_valid",
            "32": "turn_rate_valid",
            "64": "hw_fail",
            "128": "mag_invalid",
            "256": "no_gps",
            "512": "gps_invalid",
            "1024": "solution_coasting",
            "2048": "true_track_angle",
            "4096": "magnetic_heading",
            "track_angle_heading_valid": 1,
            "pitch_valid": 2,
            "roll_valid": 4,
            "lateral_body_accel_valid": 8,
            "normal_body_accel_valid": 16,
            "turn_rate_valid": 32,
            "hw_fail": 64,
            "mag_invalid": 128,
            "no_gps": 256,
            "gps_invalid": 512,
            "solution_coasting": 1024,
            "true_track_angle": 2048,
            "magnetic_heading": 4096
        }
    },
    "auto_sync_frequency": {
        "base_type": "enum",
        "values": {
            "0": "never",
            "1": "occasionally",
            "2": "frequent",
            "3": "once_a_day",
            "4": "remote",
            "never": 0,
            "occasionally": 1,
            "frequent": 2,
            "once_a_day": 3,
            "remote": 4
        }
    },
    "exd_layout": {
        "base_type": "enum",
        "values": {
            "0": "full_screen",
            "1": "half_vertical",
            "2": "half_horizontal",
            "3": "half_vertical_right_split",
            "4": "half_horizontal_bottom_split",
            "5": "full_quarter_split",
            "6": "half_vertical_left_split",
            "7": "half_horizontal_top_split",
            "8": "dynamic",
            "full_screen": 0,
            "half_vertical": 1,
            "half_horizontal": 2,
            "half_vertical_right_split": 3,
            "half_horizontal_bottom_split": 4,
            "full_quarter_split": 5,
            "half_vertical_left_split": 6,
            "half_horizontal_top_split": 7,
            "dynamic": 8
        }
    },
    "exd_display_type": {
        "base_type": "enum",
        "values": {
            "0": "numerical",
            "1": "simple",
            "2": "graph",
            "3": "bar",
            "4": "circle_graph",
            "5": "virtual_partner",
            "6": "balance",
            "7": "string_list",
            "8": "string",
            "9": "simple_dynamic_icon",
            "10": "gauge",
            "numerical": 0,
            "simple": 1,
            "graph": 2,
            "bar": 3,
            "circle_graph": 4,
            "virtual_partner": 5,
            "balance": 6,
            "string_list": 7,
            "string": 8,
            "simple_dynamic_icon": 9,
            "gauge": 10
        }
    },
    "exd_data_units": {
        "base_type": "enum",
        "values": {
            "0": "no_units",
            "1": "laps",
            "2": "miles_per_hour",
            "3": "kilometers_per_hour",
            "4": "feet_per_hour",
            "5": "meters_per_hour",
            "6": "degrees_celsius",
            "7": "degrees_farenheit",
            "8": "zone",
            "9": "gear",
            "10": "rpm",
            "11": "bpm",
            "12": "degrees",
            "13": "millimeters",
            "14": "meters",
            "15": "kilometers",
            "16": "feet",
            "17": "yards",
            "18": "kilofeet",
            "19": "miles",
            "20": "time",
            "21": "enum_turn_type",
            "22": "percent",
            "23": "watts",
            "24": "watts_per_kilogram",
            "25": "enum_battery_status",
            "26": "enum_bike_light_beam_angle_mode",
            "27": "enum_bike_light_battery_status",
            "28": "enum_bike_light_network_config_type",
            "29": "lights",
            "30": "seconds",
            "31": "minutes",
            "32": "hours",
            "33": "calories",
            "34": "kilojoules",
            "35": "milliseconds",
            "36": "second_per_mile",
            "37": "second_per_kilometer",
            "38": "centimeter",
            "39": "enum_course_point",
            "40": "bradians",
            "41": "enum_sport",
            "42": "inches_hg",
            "43": "mm_hg",
            "44": "mbars",
            "45": "hecto_pascals",
            "46": "feet_per_min",
            "47": "meters_per_min",
            "48": "meters_per_sec",
            "49": "eight_cardinal",
            "no_units": 0,
            "laps": 1,
            "miles_per_hour": 2,
            "kilometers_per_hour": 3,
            "feet_per_hour": 4,
            "meters_per_hour": 5,
            "degrees_celsius": 6,
            "degrees_farenheit": 7,
            "zone": 8,
            "gear": 9,
            "rpm": 10,
            "bpm": 11,
            "degrees": 12,
            "millimeters": 13,
            "meters": 14,
            "kilometers": 15,
            "feet": 16,
            "yards": 17,
            "kilofeet": 18,
            "miles": 19,
            "time": 20,
            "enum_turn_type": 21,
            "percent": 22,
            "watts": 23,
            "watts_per_kilogram": 24,
            "enum_battery_status": 25,
            "enum_bike_light_beam_angle_mode": 26,
            "enum_bike_light_battery_status": 27,
            "enum_bike_light_network_config_type": 28,
            "lights": 29,
            "seconds": 30,
            "minutes": 31,
            "hours": 32,
            "calories": 33,
            "kilojoules": 34,
            "milliseconds": 35,
            "second_per_mile": 36,
            "second_per_kilometer": 37,
            "centimeter": 38,
            "enum_course_point": 39,
            "bradians": 40,
            "enum_sport": 41,
            "inches_hg": 42,
            "mm_hg": 43,
            "mbars": 44,
            "hecto_pascals": 45,
            "feet_per_min": 46,
            "meters_per_min": 47,
            "meters_per_sec": 48,
            "eight_cardinal": 49
        }
    },
    "exd_qualifiers": {
        "base_type": "enum",
        "values": {
            "0": "no_qualifier",
            "1": "instantaneous",
            "2": "average",
            "3": "lap",
            "4": "maximum",
            "5": "maximum_average",
            "6": "maximum_lap",
            "7": "last_lap",
            "8": "average_lap",
            "9": "to_destination",
            "10": "to_go",
            "11": "to_next",
            "12": "next_course_point",
            "13": "total",
            "14": "three_second_average",
            "15": "ten_second_average",
            "16": "thirty_second_average",
            "17": "percent_maximum",
            "18": "percent_maximum_average",
            "19": "lap_percent_maximum",
            "20": "elapsed",
            "21": "sunrise",
            "22": "sunset",
            "23": "compared_to_virtual_partner",
            "24": "maximum_24h",
            "25": "minimum_24h",
            "26": "minimum",
            "27": "first",
            "28": "second",
            "29": "third",
            "30": "shifter",
            "31": "last_sport",
            "32": "moving",
            "33": "stopped",
            "34": "estimated_total",
            "242": "zone_9",
            "243": "zone_8",
            "244": "zone_7",
            "245": "zone_6",
            "246": "zone_5",
            "247": "zone_4",
            "248": "zone_3",
            "249": "zone_2",
            "250": "zone_1",
            "no_qualifier": 0,
            "instantaneous": 1,
            "average": 2,
            "lap": 3,
            "maximum": 4,
            "maximum_average": 5,
            "maximum_lap": 6,
            "last_lap": 7,
            "average_lap": 8,
            "to_destination": 9,
            "to_go": 10,
            "to_next": 11,
            "next_course_point": 12,
            "total": 13,
            "three_second_average": 14,
            "ten_second_average": 15,
            "thirty_second_average": 16,
            "percent_maximum": 17,
            "percent_maximum_average": 18,
            "lap_percent_maximum": 19,
            "elapsed": 20,
            "sunrise": 21,
            "sunset": 22,
            "compared_to_virtual_partner": 23,
            "maximum_24h": 24,
            "minimum_24h": 25,
            "minimum": 26,
            "first": 27,
            "second": 28,
            "third": 29,
            "shifter": 30,
            "last_sport": 31,
            "moving": 32,
            "stopped": 33,
            "estimated_total": 34,
            "zone_9": 242,
            "zone_8": 243,
            "zone_7": 244,
            "zone_6": 245,
            "zone_5": 246,
            "zone_4": 247,
            "zone_3": 248,
            "zone_2": 249,
            "zone_1": 250
        }
    },
    "exd_descriptors": {
        "base_type": "enum",
        "values": {
            "0": "bike_light_battery_status",
            "1": "beam_angle_status",
            "2": "batery_level",
            "3": "light_network_mode",
            "4": "number_lights_connected",
            "5": "cadence",
            "6": "distance",
            "7": "estimated_time_of_arrival",
            "8": "heading",
            "9": "time",
            "10": "battery_level",
            "11": "trainer_resistance",
            "12": "trainer_target_power",
            "13": "time_seated",
            "14": "time_standing",
            "15": "elevation",
            "16": "grade",
            "17": "ascent",
            "18": "descent",
            "19": "vertical_speed",
            "20": "di2_battery_level",
            "21": "front_gear",
            "22": "rear_gear",
            "23": "gear_ratio",
            "24": "heart_rate",
            "25": "heart_rate_zone",
            "26": "time_in_heart_rate_zone",
            "27": "heart_rate_reserve",
            "28": "calories",
            "29": "gps_accuracy",
            "30": "gps_signal_strength",
            "31": "temperature",
            "32": "time_of_day",
            "33": "balance",
            "34": "pedal_smoothness",
            "35": "power",
            "36": "functional_threshold_power",
            "37": "intensity_factor",
            "38": "work",
            "39": "power_ratio",
            "40": "normalized_power",
            "41": "training_stress_Score",
            "42": "time_on_zone",
            "43": "speed",
            "44": "laps",
            "45": "reps",
            "46": "workout_step",
            "47": "course_distance",
            "48": "navigation_distance",
            "49": "course_estimated_time_of_arrival",
            "50": "navigation_estimated_time_of_arrival",
            "51": "course_time",
            "52": "navigation_time",
            "53": "course_heading",
            "54": "navigation_heading",
            "55": "power_zone",
            "56": "torque_effectiveness",
            "57": "timer_time",
            "58": "power_weight_ratio",
            "59": "left_platform_center_offset",
            "60": "right_platform_center_offset",
            "61": "left_power_phase_start_angle",
            "62": "right_power_phase_start_angle",
            "63": "left_power_phase_finish_angle",
            "64": "right_power_phase_finish_angle",
            "65": "gears",
            "66": "pace",
            "67": "training_effect",
            "68": "vertical_oscillation",
            "69": "vertical_ratio",
            "70": "ground_contact_time",
            "71": "left_ground_contact_time_balance",
            "72": "right_ground_contact_time_balance",
            "73": "stride_length",
            "74": "running_cadence",
            "75": "performance_condition",
            "76": "course_type",
            "77": "time_in_power_zone",
            "78": "navigation_turn",
            "79": "course_location",
            "80": "navigation_location",
            "81": "compass",
            "82": "gear_combo",
            "83": "muscle_oxygen",
            "84": "icon",
            "85": "compass_heading",
            "86": "gps_heading",
            "87": "gps_elevation",
            "88": "anaerobic_training_effect",
            "89": "course",
            "90": "off_course",
            "91": "glide_ratio",
            "92": "vertical_distance",
            "93": "vmg",
            "94": "ambient_pressure",
            "95": "pressure",
            "96": "vam",
            "bike_light_battery_status": 0,
            "beam_angle_status": 1,
            "batery_level": 2,
            "light_network_mode": 3,
            "number_lights_connected": 4,
            "cadence": 5,
            "distance": 6,
            "estimated_time_of_arrival": 7,
            "heading": 8,
            "time": 9,
            "battery_level": 10,
            "trainer_resistance": 11,
            "trainer_target_power": 12,
            "time_seated": 13,
            "time_standing": 14,
            "elevation": 15,
            "grade": 16,
            "ascent": 17,
            "descent": 18,
            "vertical_speed": 19,
            "di2_battery_level": 20,
            "front_gear": 21,
            "rear_gear": 22,
            "gear_ratio": 23,
            "heart_rate": 24,
            "heart_rate_zone": 25,
            "time_in_heart_rate_zone": 26,
            "heart_rate_reserve": 27,
            "calories": 28,
            "gps_accuracy": 29,
            "gps_signal_strength": 30,
            "temperature": 31,
            "time_of_day": 32,
            "balance": 33,
            "pedal_smoothness": 34,
            "power": 35,
            "functional_threshold_power": 36,
            "intensity_factor": 37,
            "work": 38,
            "power_ratio": 39,
            "normalized_power": 40,
            "training_stress_Score": 41,
            "time_on_zone": 42,
            "speed": 43,
            "laps": 44,
            "reps": 45,
            "workout_step": 46,
            "course_distance": 47,
            "navigation_distance": 48,
            "course_estimated_time_of_arrival": 49,
            "navigation_estimated_time_of_arrival": 50,
            "course_time": 51,
            "navigation_time": 52,
            "course_heading": 53,
            "navigation_heading": 54,
            "power_zone": 55,
            "torque_effectiveness": 56,
            "timer_time": 57,
            "power_weight_ratio": 58,
            "left_platform_center_offset": 59,
            "right_platform_center_offset": 60,
            "left_power_phase_start_angle": 61,
            "right_power_phase_start_angle": 62,
            "left_power_phase_finish_angle": 63,
            "right_power_phase_finish_angle": 64,
            "gears": 65,
            "pace": 66,
            "training_effect": 67,
            "vertical_oscillation": 68,
            "vertical_ratio": 69,
            "ground_contact_time": 70,
            "left_ground_contact_time_balance": 71,
            "right_ground_contact_time_balance": 72,
            "stride_length": 73,
            "running_cadence": 74,
            "performance_condition": 75,
            "course_type": 76,
            "time_in_power_zone": 77,
            "navigation_turn": 78,
            "course_location": 79,
            "navigation_location": 80,
            "compass": 81,
            "gear_combo": 82,
            "muscle_oxygen": 83,
            "icon": 84,
            "compass_heading": 85,
            "gps_heading": 86,
            "gps_elevation": 87,
            "anaerobic_training_effect": 88,
            "course": 89,
            "off_course": 90,
            "glide_ratio": 91,
            "vertical_distance": 92,
            "vmg": 93,
            "ambient_pressure": 94,
            "pressure": 95,
            "vam": 96
        }
    },
    "auto_activity_detect": {
        "base_type": "uint32",
        "values": {
            "0": "none",
            "1": "running",
            "2": "cycling",
            "4": "swimming",
            "8": "walking",
            "32": "elliptical",
            "1024": "sedentary",
            "none": 0,
            "running": 1,
            "cycling": 2,
            "swimming": 4,
            "walking": 8,
            "elliptical": 32,
            "sedentary": 1024
        }
    },
    "supported_exd_screen_layouts": {
        "base_type": "uint32z",
        "values": {
            "1": "full_screen",
            "2": "half_vertical",
            "4": "half_horizontal",
            "8": "half_vertical_right_split",
            "16": "half_horizontal_bottom_split",
            "32": "full_quarter_split",
            "64": "half_vertical_left_split",
            "128": "half_horizontal_top_split",
            "full_screen": 1,
            "half_vertical": 2,
            "half_horizontal": 4,
            "half_vertical_right_split": 8,
            "half_horizontal_bottom_split": 16,
            "full_quarter_split": 32,
            "half_vertical_left_split": 64,
            "half_horizontal_top_split": 128
        }
    },
    "fit_base_type": {
        "base_type": "uint8",
        "values": {
            "0": "enum",
            "1": "sint8",
            "2": "uint8",
            "7": "string",
            "10": "uint8z",
            "13": "byte",
            "131": "sint16",
            "132": "uint16",
            "133": "sint32",
            "134": "uint32",
            "136": "float32",
            "137": "float64",
            "139": "uint16z",
            "140": "uint32z",
            "142": "sint64",
            "143": "uint64",
            "144": "uint64z",
            "enum": 0,
            "sint8": 1,
            "uint8": 2,
            "sint16": 131,
            "uint16": 132,
            "sint32": 133,
            "uint32": 134,
            "string": 7,
            "float32": 136,
            "float64": 137,
            "uint8z": 10,
            "uint16z": 139,
            "uint32z": 140,
            "byte": 13,
            "sint64": 142,
            "uint64": 143,
            "uint64z": 144
        }
    },
    "turn_type": {
        "base_type": "enum",
        "values": {
            "0": "arriving_idx",
            "1": "arriving_left_idx",
            "2": "arriving_right_idx",
            "3": "arriving_via_idx",
            "4": "arriving_via_left_idx",
            "5": "arriving_via_right_idx",
            "6": "bear_keep_left_idx",
            "7": "bear_keep_right_idx",
            "8": "continue_idx",
            "9": "exit_left_idx",
            "10": "exit_right_idx",
            "11": "ferry_idx",
            "12": "roundabout_45_idx",
            "13": "roundabout_90_idx",
            "14": "roundabout_135_idx",
            "15": "roundabout_180_idx",
            "16": "roundabout_225_idx",
            "17": "roundabout_270_idx",
            "18": "roundabout_315_idx",
            "19": "roundabout_360_idx",
            "20": "roundabout_neg_45_idx",
            "21": "roundabout_neg_90_idx",
            "22": "roundabout_neg_135_idx",
            "23": "roundabout_neg_180_idx",
            "24": "roundabout_neg_225_idx",
            "25": "roundabout_neg_270_idx",
            "26": "roundabout_neg_315_idx",
            "27": "roundabout_neg_360_idx",
            "28": "roundabout_generic_idx",
            "29": "roundabout_neg_generic_idx",
            "30": "sharp_turn_left_idx",
            "31": "sharp_turn_right_idx",
            "32": "turn_left_idx",
            "33": "turn_right_idx",
            "34": "uturn_left_idx",
            "35": "uturn_right_idx",
            "36": "icon_inv_idx",
            "37": "icon_idx_cnt",
            "arriving_idx": 0,
            "arriving_left_idx": 1,
            "arriving_right_idx": 2,
            "arriving_via_idx": 3,
            "arriving_via_left_idx": 4,
            "arriving_via_right_idx": 5,
            "bear_keep_left_idx": 6,
            "bear_keep_right_idx": 7,
            "continue_idx": 8,
            "exit_left_idx": 9,
            "exit_right_idx": 10,
            "ferry_idx": 11,
            "roundabout_45_idx": 12,
            "roundabout_90_idx": 13,
            "roundabout_135_idx": 14,
            "roundabout_180_idx": 15,
            "roundabout_225_idx": 16,
            "roundabout_270_idx": 17,
            "roundabout_315_idx": 18,
            "roundabout_360_idx": 19,
            "roundabout_neg_45_idx": 20,
            "roundabout_neg_90_idx": 21,
            "roundabout_neg_135_idx": 22,
            "roundabout_neg_180_idx": 23,
            "roundabout_neg_225_idx": 24,
            "roundabout_neg_270_idx": 25,
            "roundabout_neg_315_idx": 26,
            "roundabout_neg_360_idx": 27,
            "roundabout_generic_idx": 28,
            "roundabout_neg_generic_idx": 29,
            "sharp_turn_left_idx": 30,
            "sharp_turn_right_idx": 31,
            "turn_left_idx": 32,
            "turn_right_idx": 33,
            "uturn_left_idx": 34,
            "uturn_right_idx": 35,
            "icon_inv_idx": 36,
            "icon_idx_cnt": 37
        }
    },
    "bike_light_beam_angle_mode": {
        "base_type": "uint8",
        "values": {
            "0": "manual",
            "1": "auto",
            "manual": 0,
            "auto": 1
        }
    },
    "fit_base_unit": {
        "base_type": "uint16",
        "values": {
            "0": "other",
            "1": "kilogram",
            "2": "pound",
            "other": 0,
            "kilogram": 1,
            "pound": 2
        }
    },
    "set_type": {
        "base_type": "uint8",
        "values": {
            "0": "rest",
            "1": "active",
            "rest": 0,
            "active": 1
        }
    },
    "exercise_category": {
        "base_type": "uint16",
        "values": {
            "0": "bench_press",
            "1": "calf_raise",
            "2": "cardio",
            "3": "carry",
            "4": "chop",
            "5": "core",
            "6": "crunch",
            "7": "curl",
            "8": "deadlift",
            "9": "flye",
            "10": "hip_raise",
            "11": "hip_stability",
            "12": "hip_swing",
            "13": "hyperextension",
            "14": "lateral_raise",
            "15": "leg_curl",
            "16": "leg_raise",
            "17": "lunge",
            "18": "olympic_lift",
            "19": "plank",
            "20": "plyo",
            "21": "pull_up",
            "22": "push_up",
            "23": "row",
            "24": "shoulder_press",
            "25": "shoulder_stability",
            "26": "shrug",
            "27": "sit_up",
            "28": "squat",
            "29": "total_body",
            "30": "triceps_extension",
            "31": "warm_up",
            "32": "run",
            "65534": "unknown",
            "bench_press": 0,
            "calf_raise": 1,
            "cardio": 2,
            "carry": 3,
            "chop": 4,
            "core": 5,
            "crunch": 6,
            "curl": 7,
            "deadlift": 8,
            "flye": 9,
            "hip_raise": 10,
            "hip_stability": 11,
            "hip_swing": 12,
            "hyperextension": 13,
            "lateral_raise": 14,
            "leg_curl": 15,
            "leg_raise": 16,
            "lunge": 17,
            "olympic_lift": 18,
            "plank": 19,
            "plyo": 20,
            "pull_up": 21,
            "push_up": 22,
            "row": 23,
            "shoulder_press": 24,
            "shoulder_stability": 25,
            "shrug": 26,
            "sit_up": 27,
            "squat": 28,
            "total_body": 29,
            "triceps_extension": 30,
            "warm_up": 31,
            "run": 32,
            "unknown": 65534
        }
    },
    "bench_press_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "alternating_dumbbell_chest_press_on_swiss_ball",
            "1": "barbell_bench_press",
            "2": "barbell_board_bench_press",
            "3": "barbell_floor_press",
            "4": "close_grip_barbell_bench_press",
            "5": "decline_dumbbell_bench_press",
            "6": "dumbbell_bench_press",
            "7": "dumbbell_floor_press",
            "8": "incline_barbell_bench_press",
            "9": "incline_dumbbell_bench_press",
            "10": "incline_smith_machine_bench_press",
            "11": "isometric_barbell_bench_press",
            "12": "kettlebell_chest_press",
            "13": "neutral_grip_dumbbell_bench_press",
            "14": "neutral_grip_dumbbell_incline_bench_press",
            "15": "one_arm_floor_press",
            "16": "weighted_one_arm_floor_press",
            "17": "partial_lockout",
            "18": "reverse_grip_barbell_bench_press",
            "19": "reverse_grip_incline_bench_press",
            "20": "single_arm_cable_chest_press",
            "21": "single_arm_dumbbell_bench_press",
            "22": "smith_machine_bench_press",
            "23": "swiss_ball_dumbbell_chest_press",
            "24": "triple_stop_barbell_bench_press",
            "25": "wide_grip_barbell_bench_press",
            "26": "alternating_dumbbell_chest_press",
            "alternating_dumbbell_chest_press_on_swiss_ball": 0,
            "barbell_bench_press": 1,
            "barbell_board_bench_press": 2,
            "barbell_floor_press": 3,
            "close_grip_barbell_bench_press": 4,
            "decline_dumbbell_bench_press": 5,
            "dumbbell_bench_press": 6,
            "dumbbell_floor_press": 7,
            "incline_barbell_bench_press": 8,
            "incline_dumbbell_bench_press": 9,
            "incline_smith_machine_bench_press": 10,
            "isometric_barbell_bench_press": 11,
            "kettlebell_chest_press": 12,
            "neutral_grip_dumbbell_bench_press": 13,
            "neutral_grip_dumbbell_incline_bench_press": 14,
            "one_arm_floor_press": 15,
            "weighted_one_arm_floor_press": 16,
            "partial_lockout": 17,
            "reverse_grip_barbell_bench_press": 18,
            "reverse_grip_incline_bench_press": 19,
            "single_arm_cable_chest_press": 20,
            "single_arm_dumbbell_bench_press": 21,
            "smith_machine_bench_press": 22,
            "swiss_ball_dumbbell_chest_press": 23,
            "triple_stop_barbell_bench_press": 24,
            "wide_grip_barbell_bench_press": 25,
            "alternating_dumbbell_chest_press": 26
        }
    },
    "calf_raise_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "3_way_calf_raise",
            "1": "3_way_weighted_calf_raise",
            "2": "3_way_single_leg_calf_raise",
            "3": "3_way_weighted_single_leg_calf_raise",
            "4": "donkey_calf_raise",
            "5": "weighted_donkey_calf_raise",
            "6": "seated_calf_raise",
            "7": "weighted_seated_calf_raise",
            "8": "seated_dumbbell_toe_raise",
            "9": "single_leg_bent_knee_calf_raise",
            "10": "weighted_single_leg_bent_knee_calf_raise",
            "11": "single_leg_decline_push_up",
            "12": "single_leg_donkey_calf_raise",
            "13": "weighted_single_leg_donkey_calf_raise",
            "14": "single_leg_hip_raise_with_knee_hold",
            "15": "single_leg_standing_calf_raise",
            "16": "single_leg_standing_dumbbell_calf_raise",
            "17": "standing_barbell_calf_raise",
            "18": "standing_calf_raise",
            "19": "weighted_standing_calf_raise",
            "20": "standing_dumbbell_calf_raise",
            "3_way_calf_raise": 0,
            "3_way_weighted_calf_raise": 1,
            "3_way_single_leg_calf_raise": 2,
            "3_way_weighted_single_leg_calf_raise": 3,
            "donkey_calf_raise": 4,
            "weighted_donkey_calf_raise": 5,
            "seated_calf_raise": 6,
            "weighted_seated_calf_raise": 7,
            "seated_dumbbell_toe_raise": 8,
            "single_leg_bent_knee_calf_raise": 9,
            "weighted_single_leg_bent_knee_calf_raise": 10,
            "single_leg_decline_push_up": 11,
            "single_leg_donkey_calf_raise": 12,
            "weighted_single_leg_donkey_calf_raise": 13,
            "single_leg_hip_raise_with_knee_hold": 14,
            "single_leg_standing_calf_raise": 15,
            "single_leg_standing_dumbbell_calf_raise": 16,
            "standing_barbell_calf_raise": 17,
            "standing_calf_raise": 18,
            "weighted_standing_calf_raise": 19,
            "standing_dumbbell_calf_raise": 20
        }
    },
    "cardio_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "bob_and_weave_circle",
            "1": "weighted_bob_and_weave_circle",
            "2": "cardio_core_crawl",
            "3": "weighted_cardio_core_crawl",
            "4": "double_under",
            "5": "weighted_double_under",
            "6": "jump_rope",
            "7": "weighted_jump_rope",
            "8": "jump_rope_crossover",
            "9": "weighted_jump_rope_crossover",
            "10": "jump_rope_jog",
            "11": "weighted_jump_rope_jog",
            "12": "jumping_jacks",
            "13": "weighted_jumping_jacks",
            "14": "ski_moguls",
            "15": "weighted_ski_moguls",
            "16": "split_jacks",
            "17": "weighted_split_jacks",
            "18": "squat_jacks",
            "19": "weighted_squat_jacks",
            "20": "triple_under",
            "21": "weighted_triple_under",
            "bob_and_weave_circle": 0,
            "weighted_bob_and_weave_circle": 1,
            "cardio_core_crawl": 2,
            "weighted_cardio_core_crawl": 3,
            "double_under": 4,
            "weighted_double_under": 5,
            "jump_rope": 6,
            "weighted_jump_rope": 7,
            "jump_rope_crossover": 8,
            "weighted_jump_rope_crossover": 9,
            "jump_rope_jog": 10,
            "weighted_jump_rope_jog": 11,
            "jumping_jacks": 12,
            "weighted_jumping_jacks": 13,
            "ski_moguls": 14,
            "weighted_ski_moguls": 15,
            "split_jacks": 16,
            "weighted_split_jacks": 17,
            "squat_jacks": 18,
            "weighted_squat_jacks": 19,
            "triple_under": 20,
            "weighted_triple_under": 21
        }
    },
    "carry_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "bar_holds",
            "1": "farmers_walk",
            "2": "farmers_walk_on_toes",
            "3": "hex_dumbbell_hold",
            "4": "overhead_carry",
            "bar_holds": 0,
            "farmers_walk": 1,
            "farmers_walk_on_toes": 2,
            "hex_dumbbell_hold": 3,
            "overhead_carry": 4
        }
    },
    "chop_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "cable_pull_through",
            "1": "cable_rotational_lift",
            "2": "cable_woodchop",
            "3": "cross_chop_to_knee",
            "4": "weighted_cross_chop_to_knee",
            "5": "dumbbell_chop",
            "6": "half_kneeling_rotation",
            "7": "weighted_half_kneeling_rotation",
            "8": "half_kneeling_rotational_chop",
            "9": "half_kneeling_rotational_reverse_chop",
            "10": "half_kneeling_stability_chop",
            "11": "half_kneeling_stability_reverse_chop",
            "12": "kneeling_rotational_chop",
            "13": "kneeling_rotational_reverse_chop",
            "14": "kneeling_stability_chop",
            "15": "kneeling_woodchopper",
            "16": "medicine_ball_wood_chops",
            "17": "power_squat_chops",
            "18": "weighted_power_squat_chops",
            "19": "standing_rotational_chop",
            "20": "standing_split_rotational_chop",
            "21": "standing_split_rotational_reverse_chop",
            "22": "standing_stability_reverse_chop",
            "cable_pull_through": 0,
            "cable_rotational_lift": 1,
            "cable_woodchop": 2,
            "cross_chop_to_knee": 3,
            "weighted_cross_chop_to_knee": 4,
            "dumbbell_chop": 5,
            "half_kneeling_rotation": 6,
            "weighted_half_kneeling_rotation": 7,
            "half_kneeling_rotational_chop": 8,
            "half_kneeling_rotational_reverse_chop": 9,
            "half_kneeling_stability_chop": 10,
            "half_kneeling_stability_reverse_chop": 11,
            "kneeling_rotational_chop": 12,
            "kneeling_rotational_reverse_chop": 13,
            "kneeling_stability_chop": 14,
            "kneeling_woodchopper": 15,
            "medicine_ball_wood_chops": 16,
            "power_squat_chops": 17,
            "weighted_power_squat_chops": 18,
            "standing_rotational_chop": 19,
            "standing_split_rotational_chop": 20,
            "standing_split_rotational_reverse_chop": 21,
            "standing_stability_reverse_chop": 22
        }
    },
    "core_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "abs_jabs",
            "1": "weighted_abs_jabs",
            "2": "alternating_plate_reach",
            "3": "barbell_rollout",
            "4": "weighted_barbell_rollout",
            "5": "body_bar_oblique_twist",
            "6": "cable_core_press",
            "7": "cable_side_bend",
            "8": "side_bend",
            "9": "weighted_side_bend",
            "10": "crescent_circle",
            "11": "weighted_crescent_circle",
            "12": "cycling_russian_twist",
            "13": "weighted_cycling_russian_twist",
            "14": "elevated_feet_russian_twist",
            "15": "weighted_elevated_feet_russian_twist",
            "16": "half_turkish_get_up",
            "17": "kettlebell_windmill",
            "18": "kneeling_ab_wheel",
            "19": "weighted_kneeling_ab_wheel",
            "20": "modified_front_lever",
            "21": "open_knee_tucks",
            "22": "weighted_open_knee_tucks",
            "23": "side_abs_leg_lift",
            "24": "weighted_side_abs_leg_lift",
            "25": "swiss_ball_jackknife",
            "26": "weighted_swiss_ball_jackknife",
            "27": "swiss_ball_pike",
            "28": "weighted_swiss_ball_pike",
            "29": "swiss_ball_rollout",
            "30": "weighted_swiss_ball_rollout",
            "31": "triangle_hip_press",
            "32": "weighted_triangle_hip_press",
            "33": "trx_suspended_jackknife",
            "34": "weighted_trx_suspended_jackknife",
            "35": "u_boat",
            "36": "weighted_u_boat",
            "37": "windmill_switches",
            "38": "weighted_windmill_switches",
            "39": "alternating_slide_out",
            "40": "weighted_alternating_slide_out",
            "41": "ghd_back_extensions",
            "42": "weighted_ghd_back_extensions",
            "43": "overhead_walk",
            "44": "inchworm",
            "45": "weighted_modified_front_lever",
            "46": "russian_twist",
            "47": "abdominal_leg_rotations",
            "48": "arm_and_leg_extension_on_knees",
            "49": "bicycle",
            "50": "bicep_curl_with_leg_extension",
            "51": "cat_cow",
            "52": "corkscrew",
            "53": "criss_cross",
            "54": "criss_cross_with_ball",
            "55": "double_leg_stretch",
            "56": "knee_folds",
            "57": "lower_lift",
            "58": "neck_pull",
            "59": "pelvic_clocks",
            "60": "roll_over",
            "61": "roll_up",
            "62": "rolling",
            "63": "rowing_1",
            "64": "rowing_2",
            "65": "scissors",
            "66": "single_leg_circles",
            "67": "single_leg_stretch",
            "68": "snake_twist_1_and_2",
            "69": "swan",
            "70": "swimming",
            "71": "teaser",
            "72": "the_hundred",
            "abs_jabs": 0,
            "weighted_abs_jabs": 1,
            "alternating_plate_reach": 2,
            "barbell_rollout": 3,
            "weighted_barbell_rollout": 4,
            "body_bar_oblique_twist": 5,
            "cable_core_press": 6,
            "cable_side_bend": 7,
            "side_bend": 8,
            "weighted_side_bend": 9,
            "crescent_circle": 10,
            "weighted_crescent_circle": 11,
            "cycling_russian_twist": 12,
            "weighted_cycling_russian_twist": 13,
            "elevated_feet_russian_twist": 14,
            "weighted_elevated_feet_russian_twist": 15,
            "half_turkish_get_up": 16,
            "kettlebell_windmill": 17,
            "kneeling_ab_wheel": 18,
            "weighted_kneeling_ab_wheel": 19,
            "modified_front_lever": 20,
            "open_knee_tucks": 21,
            "weighted_open_knee_tucks": 22,
            "side_abs_leg_lift": 23,
            "weighted_side_abs_leg_lift": 24,
            "swiss_ball_jackknife": 25,
            "weighted_swiss_ball_jackknife": 26,
            "swiss_ball_pike": 27,
            "weighted_swiss_ball_pike": 28,
            "swiss_ball_rollout": 29,
            "weighted_swiss_ball_rollout": 30,
            "triangle_hip_press": 31,
            "weighted_triangle_hip_press": 32,
            "trx_suspended_jackknife": 33,
            "weighted_trx_suspended_jackknife": 34,
            "u_boat": 35,
            "weighted_u_boat": 36,
            "windmill_switches": 37,
            "weighted_windmill_switches": 38,
            "alternating_slide_out": 39,
            "weighted_alternating_slide_out": 40,
            "ghd_back_extensions": 41,
            "weighted_ghd_back_extensions": 42,
            "overhead_walk": 43,
            "inchworm": 44,
            "weighted_modified_front_lever": 45,
            "russian_twist": 46,
            "abdominal_leg_rotations": 47,
            "arm_and_leg_extension_on_knees": 48,
            "bicycle": 49,
            "bicep_curl_with_leg_extension": 50,
            "cat_cow": 51,
            "corkscrew": 52,
            "criss_cross": 53,
            "criss_cross_with_ball": 54,
            "double_leg_stretch": 55,
            "knee_folds": 56,
            "lower_lift": 57,
            "neck_pull": 58,
            "pelvic_clocks": 59,
            "roll_over": 60,
            "roll_up": 61,
            "rolling": 62,
            "rowing_1": 63,
            "rowing_2": 64,
            "scissors": 65,
            "single_leg_circles": 66,
            "single_leg_stretch": 67,
            "snake_twist_1_and_2": 68,
            "swan": 69,
            "swimming": 70,
            "teaser": 71,
            "the_hundred": 72
        }
    },
    "crunch_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "bicycle_crunch",
            "1": "cable_crunch",
            "2": "circular_arm_crunch",
            "3": "crossed_arms_crunch",
            "4": "weighted_crossed_arms_crunch",
            "5": "cross_leg_reverse_crunch",
            "6": "weighted_cross_leg_reverse_crunch",
            "7": "crunch_chop",
            "8": "weighted_crunch_chop",
            "9": "double_crunch",
            "10": "weighted_double_crunch",
            "11": "elbow_to_knee_crunch",
            "12": "weighted_elbow_to_knee_crunch",
            "13": "flutter_kicks",
            "14": "weighted_flutter_kicks",
            "15": "foam_roller_reverse_crunch_on_bench",
            "16": "weighted_foam_roller_reverse_crunch_on_bench",
            "17": "foam_roller_reverse_crunch_with_dumbbell",
            "18": "foam_roller_reverse_crunch_with_medicine_ball",
            "19": "frog_press",
            "20": "hanging_knee_raise_oblique_crunch",
            "21": "weighted_hanging_knee_raise_oblique_crunch",
            "22": "hip_crossover",
            "23": "weighted_hip_crossover",
            "24": "hollow_rock",
            "25": "weighted_hollow_rock",
            "26": "incline_reverse_crunch",
            "27": "weighted_incline_reverse_crunch",
            "28": "kneeling_cable_crunch",
            "29": "kneeling_cross_crunch",
            "30": "weighted_kneeling_cross_crunch",
            "31": "kneeling_oblique_cable_crunch",
            "32": "knees_to_elbow",
            "33": "leg_extensions",
            "34": "weighted_leg_extensions",
            "35": "leg_levers",
            "36": "mcgill_curl_up",
            "37": "weighted_mcgill_curl_up",
            "38": "modified_pilates_roll_up_with_ball",
            "39": "weighted_modified_pilates_roll_up_with_ball",
            "40": "pilates_crunch",
            "41": "weighted_pilates_crunch",
            "42": "pilates_roll_up_with_ball",
            "43": "weighted_pilates_roll_up_with_ball",
            "44": "raised_legs_crunch",
            "45": "weighted_raised_legs_crunch",
            "46": "reverse_crunch",
            "47": "weighted_reverse_crunch",
            "48": "reverse_crunch_on_a_bench",
            "49": "weighted_reverse_crunch_on_a_bench",
            "50": "reverse_curl_and_lift",
            "51": "weighted_reverse_curl_and_lift",
            "52": "rotational_lift",
            "53": "weighted_rotational_lift",
            "54": "seated_alternating_reverse_crunch",
            "55": "weighted_seated_alternating_reverse_crunch",
            "56": "seated_leg_u",
            "57": "weighted_seated_leg_u",
            "58": "side_to_side_crunch_and_weave",
            "59": "weighted_side_to_side_crunch_and_weave",
            "60": "single_leg_reverse_crunch",
            "61": "weighted_single_leg_reverse_crunch",
            "62": "skater_crunch_cross",
            "63": "weighted_skater_crunch_cross",
            "64": "standing_cable_crunch",
            "65": "standing_side_crunch",
            "66": "step_climb",
            "67": "weighted_step_climb",
            "68": "swiss_ball_crunch",
            "69": "swiss_ball_reverse_crunch",
            "70": "weighted_swiss_ball_reverse_crunch",
            "71": "swiss_ball_russian_twist",
            "72": "weighted_swiss_ball_russian_twist",
            "73": "swiss_ball_side_crunch",
            "74": "weighted_swiss_ball_side_crunch",
            "75": "thoracic_crunches_on_foam_roller",
            "76": "weighted_thoracic_crunches_on_foam_roller",
            "77": "triceps_crunch",
            "78": "weighted_bicycle_crunch",
            "79": "weighted_crunch",
            "80": "weighted_swiss_ball_crunch",
            "81": "toes_to_bar",
            "82": "weighted_toes_to_bar",
            "83": "crunch",
            "84": "straight_leg_crunch_with_ball",
            "bicycle_crunch": 0,
            "cable_crunch": 1,
            "circular_arm_crunch": 2,
            "crossed_arms_crunch": 3,
            "weighted_crossed_arms_crunch": 4,
            "cross_leg_reverse_crunch": 5,
            "weighted_cross_leg_reverse_crunch": 6,
            "crunch_chop": 7,
            "weighted_crunch_chop": 8,
            "double_crunch": 9,
            "weighted_double_crunch": 10,
            "elbow_to_knee_crunch": 11,
            "weighted_elbow_to_knee_crunch": 12,
            "flutter_kicks": 13,
            "weighted_flutter_kicks": 14,
            "foam_roller_reverse_crunch_on_bench": 15,
            "weighted_foam_roller_reverse_crunch_on_bench": 16,
            "foam_roller_reverse_crunch_with_dumbbell": 17,
            "foam_roller_reverse_crunch_with_medicine_ball": 18,
            "frog_press": 19,
            "hanging_knee_raise_oblique_crunch": 20,
            "weighted_hanging_knee_raise_oblique_crunch": 21,
            "hip_crossover": 22,
            "weighted_hip_crossover": 23,
            "hollow_rock": 24,
            "weighted_hollow_rock": 25,
            "incline_reverse_crunch": 26,
            "weighted_incline_reverse_crunch": 27,
            "kneeling_cable_crunch": 28,
            "kneeling_cross_crunch": 29,
            "weighted_kneeling_cross_crunch": 30,
            "kneeling_oblique_cable_crunch": 31,
            "knees_to_elbow": 32,
            "leg_extensions": 33,
            "weighted_leg_extensions": 34,
            "leg_levers": 35,
            "mcgill_curl_up": 36,
            "weighted_mcgill_curl_up": 37,
            "modified_pilates_roll_up_with_ball": 38,
            "weighted_modified_pilates_roll_up_with_ball": 39,
            "pilates_crunch": 40,
            "weighted_pilates_crunch": 41,
            "pilates_roll_up_with_ball": 42,
            "weighted_pilates_roll_up_with_ball": 43,
            "raised_legs_crunch": 44,
            "weighted_raised_legs_crunch": 45,
            "reverse_crunch": 46,
            "weighted_reverse_crunch": 47,
            "reverse_crunch_on_a_bench": 48,
            "weighted_reverse_crunch_on_a_bench": 49,
            "reverse_curl_and_lift": 50,
            "weighted_reverse_curl_and_lift": 51,
            "rotational_lift": 52,
            "weighted_rotational_lift": 53,
            "seated_alternating_reverse_crunch": 54,
            "weighted_seated_alternating_reverse_crunch": 55,
            "seated_leg_u": 56,
            "weighted_seated_leg_u": 57,
            "side_to_side_crunch_and_weave": 58,
            "weighted_side_to_side_crunch_and_weave": 59,
            "single_leg_reverse_crunch": 60,
            "weighted_single_leg_reverse_crunch": 61,
            "skater_crunch_cross": 62,
            "weighted_skater_crunch_cross": 63,
            "standing_cable_crunch": 64,
            "standing_side_crunch": 65,
            "step_climb": 66,
            "weighted_step_climb": 67,
            "swiss_ball_crunch": 68,
            "swiss_ball_reverse_crunch": 69,
            "weighted_swiss_ball_reverse_crunch": 70,
            "swiss_ball_russian_twist": 71,
            "weighted_swiss_ball_russian_twist": 72,
            "swiss_ball_side_crunch": 73,
            "weighted_swiss_ball_side_crunch": 74,
            "thoracic_crunches_on_foam_roller": 75,
            "weighted_thoracic_crunches_on_foam_roller": 76,
            "triceps_crunch": 77,
            "weighted_bicycle_crunch": 78,
            "weighted_crunch": 79,
            "weighted_swiss_ball_crunch": 80,
            "toes_to_bar": 81,
            "weighted_toes_to_bar": 82,
            "crunch": 83,
            "straight_leg_crunch_with_ball": 84
        }
    },
    "curl_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "alternating_dumbbell_biceps_curl",
            "1": "alternating_dumbbell_biceps_curl_on_swiss_ball",
            "2": "alternating_incline_dumbbell_biceps_curl",
            "3": "barbell_biceps_curl",
            "4": "barbell_reverse_wrist_curl",
            "5": "barbell_wrist_curl",
            "6": "behind_the_back_barbell_reverse_wrist_curl",
            "7": "behind_the_back_one_arm_cable_curl",
            "8": "cable_biceps_curl",
            "9": "cable_hammer_curl",
            "10": "cheating_barbell_biceps_curl",
            "11": "close_grip_ez_bar_biceps_curl",
            "12": "cross_body_dumbbell_hammer_curl",
            "13": "dead_hang_biceps_curl",
            "14": "decline_hammer_curl",
            "15": "dumbbell_biceps_curl_with_static_hold",
            "16": "dumbbell_hammer_curl",
            "17": "dumbbell_reverse_wrist_curl",
            "18": "dumbbell_wrist_curl",
            "19": "ez_bar_preacher_curl",
            "20": "forward_bend_biceps_curl",
            "21": "hammer_curl_to_press",
            "22": "incline_dumbbell_biceps_curl",
            "23": "incline_offset_thumb_dumbbell_curl",
            "24": "kettlebell_biceps_curl",
            "25": "lying_concentration_cable_curl",
            "26": "one_arm_preacher_curl",
            "27": "plate_pinch_curl",
            "28": "preacher_curl_with_cable",
            "29": "reverse_ez_bar_curl",
            "30": "reverse_grip_wrist_curl",
            "31": "reverse_grip_barbell_biceps_curl",
            "32": "seated_alternating_dumbbell_biceps_curl",
            "33": "seated_dumbbell_biceps_curl",
            "34": "seated_reverse_dumbbell_curl",
            "35": "split_stance_offset_pinky_dumbbell_curl",
            "36": "standing_alternating_dumbbell_curls",
            "37": "standing_dumbbell_biceps_curl",
            "38": "standing_ez_bar_biceps_curl",
            "39": "static_curl",
            "40": "swiss_ball_dumbbell_overhead_triceps_extension",
            "41": "swiss_ball_ez_bar_preacher_curl",
            "42": "twisting_standing_dumbbell_biceps_curl",
            "43": "wide_grip_ez_bar_biceps_curl",
            "alternating_dumbbell_biceps_curl": 0,
            "alternating_dumbbell_biceps_curl_on_swiss_ball": 1,
            "alternating_incline_dumbbell_biceps_curl": 2,
            "barbell_biceps_curl": 3,
            "barbell_reverse_wrist_curl": 4,
            "barbell_wrist_curl": 5,
            "behind_the_back_barbell_reverse_wrist_curl": 6,
            "behind_the_back_one_arm_cable_curl": 7,
            "cable_biceps_curl": 8,
            "cable_hammer_curl": 9,
            "cheating_barbell_biceps_curl": 10,
            "close_grip_ez_bar_biceps_curl": 11,
            "cross_body_dumbbell_hammer_curl": 12,
            "dead_hang_biceps_curl": 13,
            "decline_hammer_curl": 14,
            "dumbbell_biceps_curl_with_static_hold": 15,
            "dumbbell_hammer_curl": 16,
            "dumbbell_reverse_wrist_curl": 17,
            "dumbbell_wrist_curl": 18,
            "ez_bar_preacher_curl": 19,
            "forward_bend_biceps_curl": 20,
            "hammer_curl_to_press": 21,
            "incline_dumbbell_biceps_curl": 22,
            "incline_offset_thumb_dumbbell_curl": 23,
            "kettlebell_biceps_curl": 24,
            "lying_concentration_cable_curl": 25,
            "one_arm_preacher_curl": 26,
            "plate_pinch_curl": 27,
            "preacher_curl_with_cable": 28,
            "reverse_ez_bar_curl": 29,
            "reverse_grip_wrist_curl": 30,
            "reverse_grip_barbell_biceps_curl": 31,
            "seated_alternating_dumbbell_biceps_curl": 32,
            "seated_dumbbell_biceps_curl": 33,
            "seated_reverse_dumbbell_curl": 34,
            "split_stance_offset_pinky_dumbbell_curl": 35,
            "standing_alternating_dumbbell_curls": 36,
            "standing_dumbbell_biceps_curl": 37,
            "standing_ez_bar_biceps_curl": 38,
            "static_curl": 39,
            "swiss_ball_dumbbell_overhead_triceps_extension": 40,
            "swiss_ball_ez_bar_preacher_curl": 41,
            "twisting_standing_dumbbell_biceps_curl": 42,
            "wide_grip_ez_bar_biceps_curl": 43
        }
    },
    "deadlift_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "barbell_deadlift",
            "1": "barbell_straight_leg_deadlift",
            "2": "dumbbell_deadlift",
            "3": "dumbbell_single_leg_deadlift_to_row",
            "4": "dumbbell_straight_leg_deadlift",
            "5": "kettlebell_floor_to_shelf",
            "6": "one_arm_one_leg_deadlift",
            "7": "rack_pull",
            "8": "rotational_dumbbell_straight_leg_deadlift",
            "9": "single_arm_deadlift",
            "10": "single_leg_barbell_deadlift",
            "11": "single_leg_barbell_straight_leg_deadlift",
            "12": "single_leg_deadlift_with_barbell",
            "13": "single_leg_rdl_circuit",
            "14": "single_leg_romanian_deadlift_with_dumbbell",
            "15": "sumo_deadlift",
            "16": "sumo_deadlift_high_pull",
            "17": "trap_bar_deadlift",
            "18": "wide_grip_barbell_deadlift",
            "barbell_deadlift": 0,
            "barbell_straight_leg_deadlift": 1,
            "dumbbell_deadlift": 2,
            "dumbbell_single_leg_deadlift_to_row": 3,
            "dumbbell_straight_leg_deadlift": 4,
            "kettlebell_floor_to_shelf": 5,
            "one_arm_one_leg_deadlift": 6,
            "rack_pull": 7,
            "rotational_dumbbell_straight_leg_deadlift": 8,
            "single_arm_deadlift": 9,
            "single_leg_barbell_deadlift": 10,
            "single_leg_barbell_straight_leg_deadlift": 11,
            "single_leg_deadlift_with_barbell": 12,
            "single_leg_rdl_circuit": 13,
            "single_leg_romanian_deadlift_with_dumbbell": 14,
            "sumo_deadlift": 15,
            "sumo_deadlift_high_pull": 16,
            "trap_bar_deadlift": 17,
            "wide_grip_barbell_deadlift": 18
        }
    },
    "flye_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "cable_crossover",
            "1": "decline_dumbbell_flye",
            "2": "dumbbell_flye",
            "3": "incline_dumbbell_flye",
            "4": "kettlebell_flye",
            "5": "kneeling_rear_flye",
            "6": "single_arm_standing_cable_reverse_flye",
            "7": "swiss_ball_dumbbell_flye",
            "8": "arm_rotations",
            "9": "hug_a_tree",
            "cable_crossover": 0,
            "decline_dumbbell_flye": 1,
            "dumbbell_flye": 2,
            "incline_dumbbell_flye": 3,
            "kettlebell_flye": 4,
            "kneeling_rear_flye": 5,
            "single_arm_standing_cable_reverse_flye": 6,
            "swiss_ball_dumbbell_flye": 7,
            "arm_rotations": 8,
            "hug_a_tree": 9
        }
    },
    "hip_raise_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "barbell_hip_thrust_on_floor",
            "1": "barbell_hip_thrust_with_bench",
            "2": "bent_knee_swiss_ball_reverse_hip_raise",
            "3": "weighted_bent_knee_swiss_ball_reverse_hip_raise",
            "4": "bridge_with_leg_extension",
            "5": "weighted_bridge_with_leg_extension",
            "6": "clam_bridge",
            "7": "front_kick_tabletop",
            "8": "weighted_front_kick_tabletop",
            "9": "hip_extension_and_cross",
            "10": "weighted_hip_extension_and_cross",
            "11": "hip_raise",
            "12": "weighted_hip_raise",
            "13": "hip_raise_with_feet_on_swiss_ball",
            "14": "weighted_hip_raise_with_feet_on_swiss_ball",
            "15": "hip_raise_with_head_on_bosu_ball",
            "16": "weighted_hip_raise_with_head_on_bosu_ball",
            "17": "hip_raise_with_head_on_swiss_ball",
            "18": "weighted_hip_raise_with_head_on_swiss_ball",
            "19": "hip_raise_with_knee_squeeze",
            "20": "weighted_hip_raise_with_knee_squeeze",
            "21": "incline_rear_leg_extension",
            "22": "weighted_incline_rear_leg_extension",
            "23": "kettlebell_swing",
            "24": "marching_hip_raise",
            "25": "weighted_marching_hip_raise",
            "26": "marching_hip_raise_with_feet_on_a_swiss_ball",
            "27": "weighted_marching_hip_raise_with_feet_on_a_swiss_ball",
            "28": "reverse_hip_raise",
            "29": "weighted_reverse_hip_raise",
            "30": "single_leg_hip_raise",
            "31": "weighted_single_leg_hip_raise",
            "32": "single_leg_hip_raise_with_foot_on_bench",
            "33": "weighted_single_leg_hip_raise_with_foot_on_bench",
            "34": "single_leg_hip_raise_with_foot_on_bosu_ball",
            "35": "weighted_single_leg_hip_raise_with_foot_on_bosu_ball",
            "36": "single_leg_hip_raise_with_foot_on_foam_roller",
            "37": "weighted_single_leg_hip_raise_with_foot_on_foam_roller",
            "38": "single_leg_hip_raise_with_foot_on_medicine_ball",
            "39": "weighted_single_leg_hip_raise_with_foot_on_medicine_ball",
            "40": "single_leg_hip_raise_with_head_on_bosu_ball",
            "41": "weighted_single_leg_hip_raise_with_head_on_bosu_ball",
            "42": "weighted_clam_bridge",
            "43": "single_leg_swiss_ball_hip_raise_and_leg_curl",
            "44": "clams",
            "45": "inner_thigh_circles",
            "46": "inner_thigh_side_lift",
            "47": "leg_circles",
            "48": "leg_lift",
            "49": "leg_lift_in_external_rotation",
            "barbell_hip_thrust_on_floor": 0,
            "barbell_hip_thrust_with_bench": 1,
            "bent_knee_swiss_ball_reverse_hip_raise": 2,
            "weighted_bent_knee_swiss_ball_reverse_hip_raise": 3,
            "bridge_with_leg_extension": 4,
            "weighted_bridge_with_leg_extension": 5,
            "clam_bridge": 6,
            "front_kick_tabletop": 7,
            "weighted_front_kick_tabletop": 8,
            "hip_extension_and_cross": 9,
            "weighted_hip_extension_and_cross": 10,
            "hip_raise": 11,
            "weighted_hip_raise": 12,
            "hip_raise_with_feet_on_swiss_ball": 13,
            "weighted_hip_raise_with_feet_on_swiss_ball": 14,
            "hip_raise_with_head_on_bosu_ball": 15,
            "weighted_hip_raise_with_head_on_bosu_ball": 16,
            "hip_raise_with_head_on_swiss_ball": 17,
            "weighted_hip_raise_with_head_on_swiss_ball": 18,
            "hip_raise_with_knee_squeeze": 19,
            "weighted_hip_raise_with_knee_squeeze": 20,
            "incline_rear_leg_extension": 21,
            "weighted_incline_rear_leg_extension": 22,
            "kettlebell_swing": 23,
            "marching_hip_raise": 24,
            "weighted_marching_hip_raise": 25,
            "marching_hip_raise_with_feet_on_a_swiss_ball": 26,
            "weighted_marching_hip_raise_with_feet_on_a_swiss_ball": 27,
            "reverse_hip_raise": 28,
            "weighted_reverse_hip_raise": 29,
            "single_leg_hip_raise": 30,
            "weighted_single_leg_hip_raise": 31,
            "single_leg_hip_raise_with_foot_on_bench": 32,
            "weighted_single_leg_hip_raise_with_foot_on_bench": 33,
            "single_leg_hip_raise_with_foot_on_bosu_ball": 34,
            "weighted_single_leg_hip_raise_with_foot_on_bosu_ball": 35,
            "single_leg_hip_raise_with_foot_on_foam_roller": 36,
            "weighted_single_leg_hip_raise_with_foot_on_foam_roller": 37,
            "single_leg_hip_raise_with_foot_on_medicine_ball": 38,
            "weighted_single_leg_hip_raise_with_foot_on_medicine_ball": 39,
            "single_leg_hip_raise_with_head_on_bosu_ball": 40,
            "weighted_single_leg_hip_raise_with_head_on_bosu_ball": 41,
            "weighted_clam_bridge": 42,
            "single_leg_swiss_ball_hip_raise_and_leg_curl": 43,
            "clams": 44,
            "inner_thigh_circles": 45,
            "inner_thigh_side_lift": 46,
            "leg_circles": 47,
            "leg_lift": 48,
            "leg_lift_in_external_rotation": 49
        }
    },
    "hip_stability_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "band_side_lying_leg_raise",
            "1": "dead_bug",
            "2": "weighted_dead_bug",
            "3": "external_hip_raise",
            "4": "weighted_external_hip_raise",
            "5": "fire_hydrant_kicks",
            "6": "weighted_fire_hydrant_kicks",
            "7": "hip_circles",
            "8": "weighted_hip_circles",
            "9": "inner_thigh_lift",
            "10": "weighted_inner_thigh_lift",
            "11": "lateral_walks_with_band_at_ankles",
            "12": "pretzel_side_kick",
            "13": "weighted_pretzel_side_kick",
            "14": "prone_hip_internal_rotation",
            "15": "weighted_prone_hip_internal_rotation",
            "16": "quadruped",
            "17": "quadruped_hip_extension",
            "18": "weighted_quadruped_hip_extension",
            "19": "quadruped_with_leg_lift",
            "20": "weighted_quadruped_with_leg_lift",
            "21": "side_lying_leg_raise",
            "22": "weighted_side_lying_leg_raise",
            "23": "sliding_hip_adduction",
            "24": "weighted_sliding_hip_adduction",
            "25": "standing_adduction",
            "26": "weighted_standing_adduction",
            "27": "standing_cable_hip_abduction",
            "28": "standing_hip_abduction",
            "29": "weighted_standing_hip_abduction",
            "30": "standing_rear_leg_raise",
            "31": "weighted_standing_rear_leg_raise",
            "32": "supine_hip_internal_rotation",
            "33": "weighted_supine_hip_internal_rotation",
            "band_side_lying_leg_raise": 0,
            "dead_bug": 1,
            "weighted_dead_bug": 2,
            "external_hip_raise": 3,
            "weighted_external_hip_raise": 4,
            "fire_hydrant_kicks": 5,
            "weighted_fire_hydrant_kicks": 6,
            "hip_circles": 7,
            "weighted_hip_circles": 8,
            "inner_thigh_lift": 9,
            "weighted_inner_thigh_lift": 10,
            "lateral_walks_with_band_at_ankles": 11,
            "pretzel_side_kick": 12,
            "weighted_pretzel_side_kick": 13,
            "prone_hip_internal_rotation": 14,
            "weighted_prone_hip_internal_rotation": 15,
            "quadruped": 16,
            "quadruped_hip_extension": 17,
            "weighted_quadruped_hip_extension": 18,
            "quadruped_with_leg_lift": 19,
            "weighted_quadruped_with_leg_lift": 20,
            "side_lying_leg_raise": 21,
            "weighted_side_lying_leg_raise": 22,
            "sliding_hip_adduction": 23,
            "weighted_sliding_hip_adduction": 24,
            "standing_adduction": 25,
            "weighted_standing_adduction": 26,
            "standing_cable_hip_abduction": 27,
            "standing_hip_abduction": 28,
            "weighted_standing_hip_abduction": 29,
            "standing_rear_leg_raise": 30,
            "weighted_standing_rear_leg_raise": 31,
            "supine_hip_internal_rotation": 32,
            "weighted_supine_hip_internal_rotation": 33
        }
    },
    "hip_swing_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "single_arm_kettlebell_swing",
            "1": "single_arm_dumbbell_swing",
            "2": "step_out_swing",
            "single_arm_kettlebell_swing": 0,
            "single_arm_dumbbell_swing": 1,
            "step_out_swing": 2
        }
    },
    "hyperextension_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "back_extension_with_opposite_arm_and_leg_reach",
            "1": "weighted_back_extension_with_opposite_arm_and_leg_reach",
            "2": "base_rotations",
            "3": "weighted_base_rotations",
            "4": "bent_knee_reverse_hyperextension",
            "5": "weighted_bent_knee_reverse_hyperextension",
            "6": "hollow_hold_and_roll",
            "7": "weighted_hollow_hold_and_roll",
            "8": "kicks",
            "9": "weighted_kicks",
            "10": "knee_raises",
            "11": "weighted_knee_raises",
            "12": "kneeling_superman",
            "13": "weighted_kneeling_superman",
            "14": "lat_pull_down_with_row",
            "15": "medicine_ball_deadlift_to_reach",
            "16": "one_arm_one_leg_row",
            "17": "one_arm_row_with_band",
            "18": "overhead_lunge_with_medicine_ball",
            "19": "plank_knee_tucks",
            "20": "weighted_plank_knee_tucks",
            "21": "side_step",
            "22": "weighted_side_step",
            "23": "single_leg_back_extension",
            "24": "weighted_single_leg_back_extension",
            "25": "spine_extension",
            "26": "weighted_spine_extension",
            "27": "static_back_extension",
            "28": "weighted_static_back_extension",
            "29": "superman_from_floor",
            "30": "weighted_superman_from_floor",
            "31": "swiss_ball_back_extension",
            "32": "weighted_swiss_ball_back_extension",
            "33": "swiss_ball_hyperextension",
            "34": "weighted_swiss_ball_hyperextension",
            "35": "swiss_ball_opposite_arm_and_leg_lift",
            "36": "weighted_swiss_ball_opposite_arm_and_leg_lift",
            "37": "superman_on_swiss_ball",
            "38": "cobra",
            "39": "supine_floor_barre",
            "back_extension_with_opposite_arm_and_leg_reach": 0,
            "weighted_back_extension_with_opposite_arm_and_leg_reach": 1,
            "base_rotations": 2,
            "weighted_base_rotations": 3,
            "bent_knee_reverse_hyperextension": 4,
            "weighted_bent_knee_reverse_hyperextension": 5,
            "hollow_hold_and_roll": 6,
            "weighted_hollow_hold_and_roll": 7,
            "kicks": 8,
            "weighted_kicks": 9,
            "knee_raises": 10,
            "weighted_knee_raises": 11,
            "kneeling_superman": 12,
            "weighted_kneeling_superman": 13,
            "lat_pull_down_with_row": 14,
            "medicine_ball_deadlift_to_reach": 15,
            "one_arm_one_leg_row": 16,
            "one_arm_row_with_band": 17,
            "overhead_lunge_with_medicine_ball": 18,
            "plank_knee_tucks": 19,
            "weighted_plank_knee_tucks": 20,
            "side_step": 21,
            "weighted_side_step": 22,
            "single_leg_back_extension": 23,
            "weighted_single_leg_back_extension": 24,
            "spine_extension": 25,
            "weighted_spine_extension": 26,
            "static_back_extension": 27,
            "weighted_static_back_extension": 28,
            "superman_from_floor": 29,
            "weighted_superman_from_floor": 30,
            "swiss_ball_back_extension": 31,
            "weighted_swiss_ball_back_extension": 32,
            "swiss_ball_hyperextension": 33,
            "weighted_swiss_ball_hyperextension": 34,
            "swiss_ball_opposite_arm_and_leg_lift": 35,
            "weighted_swiss_ball_opposite_arm_and_leg_lift": 36,
            "superman_on_swiss_ball": 37,
            "cobra": 38,
            "supine_floor_barre": 39
        }
    },
    "lateral_raise_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "45_degree_cable_external_rotation",
            "1": "alternating_lateral_raise_with_static_hold",
            "2": "bar_muscle_up",
            "3": "bent_over_lateral_raise",
            "4": "cable_diagonal_raise",
            "5": "cable_front_raise",
            "6": "calorie_row",
            "7": "combo_shoulder_raise",
            "8": "dumbbell_diagonal_raise",
            "9": "dumbbell_v_raise",
            "10": "front_raise",
            "11": "leaning_dumbbell_lateral_raise",
            "12": "lying_dumbbell_raise",
            "13": "muscle_up",
            "14": "one_arm_cable_lateral_raise",
            "15": "overhand_grip_rear_lateral_raise",
            "16": "plate_raises",
            "17": "ring_dip",
            "18": "weighted_ring_dip",
            "19": "ring_muscle_up",
            "20": "weighted_ring_muscle_up",
            "21": "rope_climb",
            "22": "weighted_rope_climb",
            "23": "scaption",
            "24": "seated_lateral_raise",
            "25": "seated_rear_lateral_raise",
            "26": "side_lying_lateral_raise",
            "27": "standing_lift",
            "28": "suspended_row",
            "29": "underhand_grip_rear_lateral_raise",
            "30": "wall_slide",
            "31": "weighted_wall_slide",
            "32": "arm_circles",
            "33": "shaving_the_head",
            "45_degree_cable_external_rotation": 0,
            "alternating_lateral_raise_with_static_hold": 1,
            "bar_muscle_up": 2,
            "bent_over_lateral_raise": 3,
            "cable_diagonal_raise": 4,
            "cable_front_raise": 5,
            "calorie_row": 6,
            "combo_shoulder_raise": 7,
            "dumbbell_diagonal_raise": 8,
            "dumbbell_v_raise": 9,
            "front_raise": 10,
            "leaning_dumbbell_lateral_raise": 11,
            "lying_dumbbell_raise": 12,
            "muscle_up": 13,
            "one_arm_cable_lateral_raise": 14,
            "overhand_grip_rear_lateral_raise": 15,
            "plate_raises": 16,
            "ring_dip": 17,
            "weighted_ring_dip": 18,
            "ring_muscle_up": 19,
            "weighted_ring_muscle_up": 20,
            "rope_climb": 21,
            "weighted_rope_climb": 22,
            "scaption": 23,
            "seated_lateral_raise": 24,
            "seated_rear_lateral_raise": 25,
            "side_lying_lateral_raise": 26,
            "standing_lift": 27,
            "suspended_row": 28,
            "underhand_grip_rear_lateral_raise": 29,
            "wall_slide": 30,
            "weighted_wall_slide": 31,
            "arm_circles": 32,
            "shaving_the_head": 33
        }
    },
    "leg_curl_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "leg_curl",
            "1": "weighted_leg_curl",
            "2": "good_morning",
            "3": "seated_barbell_good_morning",
            "4": "single_leg_barbell_good_morning",
            "5": "single_leg_sliding_leg_curl",
            "6": "sliding_leg_curl",
            "7": "split_barbell_good_morning",
            "8": "split_stance_extension",
            "9": "staggered_stance_good_morning",
            "10": "swiss_ball_hip_raise_and_leg_curl",
            "11": "zercher_good_morning",
            "leg_curl": 0,
            "weighted_leg_curl": 1,
            "good_morning": 2,
            "seated_barbell_good_morning": 3,
            "single_leg_barbell_good_morning": 4,
            "single_leg_sliding_leg_curl": 5,
            "sliding_leg_curl": 6,
            "split_barbell_good_morning": 7,
            "split_stance_extension": 8,
            "staggered_stance_good_morning": 9,
            "swiss_ball_hip_raise_and_leg_curl": 10,
            "zercher_good_morning": 11
        }
    },
    "leg_raise_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "hanging_knee_raise",
            "1": "hanging_leg_raise",
            "2": "weighted_hanging_leg_raise",
            "3": "hanging_single_leg_raise",
            "4": "weighted_hanging_single_leg_raise",
            "5": "kettlebell_leg_raises",
            "6": "leg_lowering_drill",
            "7": "weighted_leg_lowering_drill",
            "8": "lying_straight_leg_raise",
            "9": "weighted_lying_straight_leg_raise",
            "10": "medicine_ball_leg_drops",
            "11": "quadruped_leg_raise",
            "12": "weighted_quadruped_leg_raise",
            "13": "reverse_leg_raise",
            "14": "weighted_reverse_leg_raise",
            "15": "reverse_leg_raise_on_swiss_ball",
            "16": "weighted_reverse_leg_raise_on_swiss_ball",
            "17": "single_leg_lowering_drill",
            "18": "weighted_single_leg_lowering_drill",
            "19": "weighted_hanging_knee_raise",
            "20": "lateral_stepover",
            "21": "weighted_lateral_stepover",
            "hanging_knee_raise": 0,
            "hanging_leg_raise": 1,
            "weighted_hanging_leg_raise": 2,
            "hanging_single_leg_raise": 3,
            "weighted_hanging_single_leg_raise": 4,
            "kettlebell_leg_raises": 5,
            "leg_lowering_drill": 6,
            "weighted_leg_lowering_drill": 7,
            "lying_straight_leg_raise": 8,
            "weighted_lying_straight_leg_raise": 9,
            "medicine_ball_leg_drops": 10,
            "quadruped_leg_raise": 11,
            "weighted_quadruped_leg_raise": 12,
            "reverse_leg_raise": 13,
            "weighted_reverse_leg_raise": 14,
            "reverse_leg_raise_on_swiss_ball": 15,
            "weighted_reverse_leg_raise_on_swiss_ball": 16,
            "single_leg_lowering_drill": 17,
            "weighted_single_leg_lowering_drill": 18,
            "weighted_hanging_knee_raise": 19,
            "lateral_stepover": 20,
            "weighted_lateral_stepover": 21
        }
    },
    "lunge_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "overhead_lunge",
            "1": "lunge_matrix",
            "2": "weighted_lunge_matrix",
            "3": "alternating_barbell_forward_lunge",
            "4": "alternating_dumbbell_lunge_with_reach",
            "5": "back_foot_elevated_dumbbell_split_squat",
            "6": "barbell_box_lunge",
            "7": "barbell_bulgarian_split_squat",
            "8": "barbell_crossover_lunge",
            "9": "barbell_front_split_squat",
            "10": "barbell_lunge",
            "11": "barbell_reverse_lunge",
            "12": "barbell_side_lunge",
            "13": "barbell_split_squat",
            "14": "core_control_rear_lunge",
            "15": "diagonal_lunge",
            "16": "drop_lunge",
            "17": "dumbbell_box_lunge",
            "18": "dumbbell_bulgarian_split_squat",
            "19": "dumbbell_crossover_lunge",
            "20": "dumbbell_diagonal_lunge",
            "21": "dumbbell_lunge",
            "22": "dumbbell_lunge_and_rotation",
            "23": "dumbbell_overhead_bulgarian_split_squat",
            "24": "dumbbell_reverse_lunge_to_high_knee_and_press",
            "25": "dumbbell_side_lunge",
            "26": "elevated_front_foot_barbell_split_squat",
            "27": "front_foot_elevated_dumbbell_split_squat",
            "28": "gunslinger_lunge",
            "29": "lawnmower_lunge",
            "30": "low_lunge_with_isometric_adduction",
            "31": "low_side_to_side_lunge",
            "32": "lunge",
            "33": "weighted_lunge",
            "34": "lunge_with_arm_reach",
            "35": "lunge_with_diagonal_reach",
            "36": "lunge_with_side_bend",
            "37": "offset_dumbbell_lunge",
            "38": "offset_dumbbell_reverse_lunge",
            "39": "overhead_bulgarian_split_squat",
            "40": "overhead_dumbbell_reverse_lunge",
            "41": "overhead_dumbbell_split_squat",
            "42": "overhead_lunge_with_rotation",
            "43": "reverse_barbell_box_lunge",
            "44": "reverse_box_lunge",
            "45": "reverse_dumbbell_box_lunge",
            "46": "reverse_dumbbell_crossover_lunge",
            "47": "reverse_dumbbell_diagonal_lunge",
            "48": "reverse_lunge_with_reach_back",
            "49": "weighted_reverse_lunge_with_reach_back",
            "50": "reverse_lunge_with_twist_and_overhead_reach",
            "51": "weighted_reverse_lunge_with_twist_and_overhead_reach",
            "52": "reverse_sliding_box_lunge",
            "53": "weighted_reverse_sliding_box_lunge",
            "54": "reverse_sliding_lunge",
            "55": "weighted_reverse_sliding_lunge",
            "56": "runners_lunge_to_balance",
            "57": "weighted_runners_lunge_to_balance",
            "58": "shifting_side_lunge",
            "59": "side_and_crossover_lunge",
            "60": "weighted_side_and_crossover_lunge",
            "61": "side_lunge",
            "62": "weighted_side_lunge",
            "63": "side_lunge_and_press",
            "64": "side_lunge_jump_off",
            "65": "side_lunge_sweep",
            "66": "weighted_side_lunge_sweep",
            "67": "side_lunge_to_crossover_tap",
            "68": "weighted_side_lunge_to_crossover_tap",
            "69": "side_to_side_lunge_chops",
            "70": "weighted_side_to_side_lunge_chops",
            "71": "siff_jump_lunge",
            "72": "weighted_siff_jump_lunge",
            "73": "single_arm_reverse_lunge_and_press",
            "74": "sliding_lateral_lunge",
            "75": "weighted_sliding_lateral_lunge",
            "76": "walking_barbell_lunge",
            "77": "walking_dumbbell_lunge",
            "78": "walking_lunge",
            "79": "weighted_walking_lunge",
            "80": "wide_grip_overhead_barbell_split_squat",
            "overhead_lunge": 0,
            "lunge_matrix": 1,
            "weighted_lunge_matrix": 2,
            "alternating_barbell_forward_lunge": 3,
            "alternating_dumbbell_lunge_with_reach": 4,
            "back_foot_elevated_dumbbell_split_squat": 5,
            "barbell_box_lunge": 6,
            "barbell_bulgarian_split_squat": 7,
            "barbell_crossover_lunge": 8,
            "barbell_front_split_squat": 9,
            "barbell_lunge": 10,
            "barbell_reverse_lunge": 11,
            "barbell_side_lunge": 12,
            "barbell_split_squat": 13,
            "core_control_rear_lunge": 14,
            "diagonal_lunge": 15,
            "drop_lunge": 16,
            "dumbbell_box_lunge": 17,
            "dumbbell_bulgarian_split_squat": 18,
            "dumbbell_crossover_lunge": 19,
            "dumbbell_diagonal_lunge": 20,
            "dumbbell_lunge": 21,
            "dumbbell_lunge_and_rotation": 22,
            "dumbbell_overhead_bulgarian_split_squat": 23,
            "dumbbell_reverse_lunge_to_high_knee_and_press": 24,
            "dumbbell_side_lunge": 25,
            "elevated_front_foot_barbell_split_squat": 26,
            "front_foot_elevated_dumbbell_split_squat": 27,
            "gunslinger_lunge": 28,
            "lawnmower_lunge": 29,
            "low_lunge_with_isometric_adduction": 30,
            "low_side_to_side_lunge": 31,
            "lunge": 32,
            "weighted_lunge": 33,
            "lunge_with_arm_reach": 34,
            "lunge_with_diagonal_reach": 35,
            "lunge_with_side_bend": 36,
            "offset_dumbbell_lunge": 37,
            "offset_dumbbell_reverse_lunge": 38,
            "overhead_bulgarian_split_squat": 39,
            "overhead_dumbbell_reverse_lunge": 40,
            "overhead_dumbbell_split_squat": 41,
            "overhead_lunge_with_rotation": 42,
            "reverse_barbell_box_lunge": 43,
            "reverse_box_lunge": 44,
            "reverse_dumbbell_box_lunge": 45,
            "reverse_dumbbell_crossover_lunge": 46,
            "reverse_dumbbell_diagonal_lunge": 47,
            "reverse_lunge_with_reach_back": 48,
            "weighted_reverse_lunge_with_reach_back": 49,
            "reverse_lunge_with_twist_and_overhead_reach": 50,
            "weighted_reverse_lunge_with_twist_and_overhead_reach": 51,
            "reverse_sliding_box_lunge": 52,
            "weighted_reverse_sliding_box_lunge": 53,
            "reverse_sliding_lunge": 54,
            "weighted_reverse_sliding_lunge": 55,
            "runners_lunge_to_balance": 56,
            "weighted_runners_lunge_to_balance": 57,
            "shifting_side_lunge": 58,
            "side_and_crossover_lunge": 59,
            "weighted_side_and_crossover_lunge": 60,
            "side_lunge": 61,
            "weighted_side_lunge": 62,
            "side_lunge_and_press": 63,
            "side_lunge_jump_off": 64,
            "side_lunge_sweep": 65,
            "weighted_side_lunge_sweep": 66,
            "side_lunge_to_crossover_tap": 67,
            "weighted_side_lunge_to_crossover_tap": 68,
            "side_to_side_lunge_chops": 69,
            "weighted_side_to_side_lunge_chops": 70,
            "siff_jump_lunge": 71,
            "weighted_siff_jump_lunge": 72,
            "single_arm_reverse_lunge_and_press": 73,
            "sliding_lateral_lunge": 74,
            "weighted_sliding_lateral_lunge": 75,
            "walking_barbell_lunge": 76,
            "walking_dumbbell_lunge": 77,
            "walking_lunge": 78,
            "weighted_walking_lunge": 79,
            "wide_grip_overhead_barbell_split_squat": 80
        }
    },
    "olympic_lift_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "barbell_hang_power_clean",
            "1": "barbell_hang_squat_clean",
            "2": "barbell_power_clean",
            "3": "barbell_power_snatch",
            "4": "barbell_squat_clean",
            "5": "clean_and_jerk",
            "6": "barbell_hang_power_snatch",
            "7": "barbell_hang_pull",
            "8": "barbell_high_pull",
            "9": "barbell_snatch",
            "10": "barbell_split_jerk",
            "11": "clean",
            "12": "dumbbell_clean",
            "13": "dumbbell_hang_pull",
            "14": "one_hand_dumbbell_split_snatch",
            "15": "push_jerk",
            "16": "single_arm_dumbbell_snatch",
            "17": "single_arm_hang_snatch",
            "18": "single_arm_kettlebell_snatch",
            "19": "split_jerk",
            "20": "squat_clean_and_jerk",
            "barbell_hang_power_clean": 0,
            "barbell_hang_squat_clean": 1,
            "barbell_power_clean": 2,
            "barbell_power_snatch": 3,
            "barbell_squat_clean": 4,
            "clean_and_jerk": 5,
            "barbell_hang_power_snatch": 6,
            "barbell_hang_pull": 7,
            "barbell_high_pull": 8,
            "barbell_snatch": 9,
            "barbell_split_jerk": 10,
            "clean": 11,
            "dumbbell_clean": 12,
            "dumbbell_hang_pull": 13,
            "one_hand_dumbbell_split_snatch": 14,
            "push_jerk": 15,
            "single_arm_dumbbell_snatch": 16,
            "single_arm_hang_snatch": 17,
            "single_arm_kettlebell_snatch": 18,
            "split_jerk": 19,
            "squat_clean_and_jerk": 20
        }
    },
    "plank_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "45_degree_plank",
            "1": "weighted_45_degree_plank",
            "2": "90_degree_static_hold",
            "3": "weighted_90_degree_static_hold",
            "4": "bear_crawl",
            "5": "weighted_bear_crawl",
            "6": "cross_body_mountain_climber",
            "7": "weighted_cross_body_mountain_climber",
            "8": "elbow_plank_pike_jacks",
            "9": "weighted_elbow_plank_pike_jacks",
            "10": "elevated_feet_plank",
            "11": "weighted_elevated_feet_plank",
            "12": "elevator_abs",
            "13": "weighted_elevator_abs",
            "14": "extended_plank",
            "15": "weighted_extended_plank",
            "16": "full_plank_passe_twist",
            "17": "weighted_full_plank_passe_twist",
            "18": "inching_elbow_plank",
            "19": "weighted_inching_elbow_plank",
            "20": "inchworm_to_side_plank",
            "21": "weighted_inchworm_to_side_plank",
            "22": "kneeling_plank",
            "23": "weighted_kneeling_plank",
            "24": "kneeling_side_plank_with_leg_lift",
            "25": "weighted_kneeling_side_plank_with_leg_lift",
            "26": "lateral_roll",
            "27": "weighted_lateral_roll",
            "28": "lying_reverse_plank",
            "29": "weighted_lying_reverse_plank",
            "30": "medicine_ball_mountain_climber",
            "31": "weighted_medicine_ball_mountain_climber",
            "32": "modified_mountain_climber_and_extension",
            "33": "weighted_modified_mountain_climber_and_extension",
            "34": "mountain_climber",
            "35": "weighted_mountain_climber",
            "36": "mountain_climber_on_sliding_discs",
            "37": "weighted_mountain_climber_on_sliding_discs",
            "38": "mountain_climber_with_feet_on_bosu_ball",
            "39": "weighted_mountain_climber_with_feet_on_bosu_ball",
            "40": "mountain_climber_with_hands_on_bench",
            "41": "mountain_climber_with_hands_on_swiss_ball",
            "42": "weighted_mountain_climber_with_hands_on_swiss_ball",
            "43": "plank",
            "44": "plank_jacks_with_feet_on_sliding_discs",
            "45": "weighted_plank_jacks_with_feet_on_sliding_discs",
            "46": "plank_knee_twist",
            "47": "weighted_plank_knee_twist",
            "48": "plank_pike_jumps",
            "49": "weighted_plank_pike_jumps",
            "50": "plank_pikes",
            "51": "weighted_plank_pikes",
            "52": "plank_to_stand_up",
            "53": "weighted_plank_to_stand_up",
            "54": "plank_with_arm_raise",
            "55": "weighted_plank_with_arm_raise",
            "56": "plank_with_knee_to_elbow",
            "57": "weighted_plank_with_knee_to_elbow",
            "58": "plank_with_oblique_crunch",
            "59": "weighted_plank_with_oblique_crunch",
            "60": "plyometric_side_plank",
            "61": "weighted_plyometric_side_plank",
            "62": "rolling_side_plank",
            "63": "weighted_rolling_side_plank",
            "64": "side_kick_plank",
            "65": "weighted_side_kick_plank",
            "66": "side_plank",
            "67": "weighted_side_plank",
            "68": "side_plank_and_row",
            "69": "weighted_side_plank_and_row",
            "70": "side_plank_lift",
            "71": "weighted_side_plank_lift",
            "72": "side_plank_with_elbow_on_bosu_ball",
            "73": "weighted_side_plank_with_elbow_on_bosu_ball",
            "74": "side_plank_with_feet_on_bench",
            "75": "weighted_side_plank_with_feet_on_bench",
            "76": "side_plank_with_knee_circle",
            "77": "weighted_side_plank_with_knee_circle",
            "78": "side_plank_with_knee_tuck",
            "79": "weighted_side_plank_with_knee_tuck",
            "80": "side_plank_with_leg_lift",
            "81": "weighted_side_plank_with_leg_lift",
            "82": "side_plank_with_reach_under",
            "83": "weighted_side_plank_with_reach_under",
            "84": "single_leg_elevated_feet_plank",
            "85": "weighted_single_leg_elevated_feet_plank",
            "86": "single_leg_flex_and_extend",
            "87": "weighted_single_leg_flex_and_extend",
            "88": "single_leg_side_plank",
            "89": "weighted_single_leg_side_plank",
            "90": "spiderman_plank",
            "91": "weighted_spiderman_plank",
            "92": "straight_arm_plank",
            "93": "weighted_straight_arm_plank",
            "94": "straight_arm_plank_with_shoulder_touch",
            "95": "weighted_straight_arm_plank_with_shoulder_touch",
            "96": "swiss_ball_plank",
            "97": "weighted_swiss_ball_plank",
            "98": "swiss_ball_plank_leg_lift",
            "99": "weighted_swiss_ball_plank_leg_lift",
            "100": "swiss_ball_plank_leg_lift_and_hold",
            "101": "swiss_ball_plank_with_feet_on_bench",
            "102": "weighted_swiss_ball_plank_with_feet_on_bench",
            "103": "swiss_ball_prone_jackknife",
            "104": "weighted_swiss_ball_prone_jackknife",
            "105": "swiss_ball_side_plank",
            "106": "weighted_swiss_ball_side_plank",
            "107": "three_way_plank",
            "108": "weighted_three_way_plank",
            "109": "towel_plank_and_knee_in",
            "110": "weighted_towel_plank_and_knee_in",
            "111": "t_stabilization",
            "112": "weighted_t_stabilization",
            "113": "turkish_get_up_to_side_plank",
            "114": "weighted_turkish_get_up_to_side_plank",
            "115": "two_point_plank",
            "116": "weighted_two_point_plank",
            "117": "weighted_plank",
            "118": "wide_stance_plank_with_diagonal_arm_lift",
            "119": "weighted_wide_stance_plank_with_diagonal_arm_lift",
            "120": "wide_stance_plank_with_diagonal_leg_lift",
            "121": "weighted_wide_stance_plank_with_diagonal_leg_lift",
            "122": "wide_stance_plank_with_leg_lift",
            "123": "weighted_wide_stance_plank_with_leg_lift",
            "124": "wide_stance_plank_with_opposite_arm_and_leg_lift",
            "125": "weighted_mountain_climber_with_hands_on_bench",
            "126": "weighted_swiss_ball_plank_leg_lift_and_hold",
            "127": "weighted_wide_stance_plank_with_opposite_arm_and_leg_lift",
            "128": "plank_with_feet_on_swiss_ball",
            "129": "side_plank_to_plank_with_reach_under",
            "130": "bridge_with_glute_lower_lift",
            "131": "bridge_one_leg_bridge",
            "132": "plank_with_arm_variations",
            "133": "plank_with_leg_lift",
            "134": "reverse_plank_with_leg_pull",
            "45_degree_plank": 0,
            "weighted_45_degree_plank": 1,
            "90_degree_static_hold": 2,
            "weighted_90_degree_static_hold": 3,
            "bear_crawl": 4,
            "weighted_bear_crawl": 5,
            "cross_body_mountain_climber": 6,
            "weighted_cross_body_mountain_climber": 7,
            "elbow_plank_pike_jacks": 8,
            "weighted_elbow_plank_pike_jacks": 9,
            "elevated_feet_plank": 10,
            "weighted_elevated_feet_plank": 11,
            "elevator_abs": 12,
            "weighted_elevator_abs": 13,
            "extended_plank": 14,
            "weighted_extended_plank": 15,
            "full_plank_passe_twist": 16,
            "weighted_full_plank_passe_twist": 17,
            "inching_elbow_plank": 18,
            "weighted_inching_elbow_plank": 19,
            "inchworm_to_side_plank": 20,
            "weighted_inchworm_to_side_plank": 21,
            "kneeling_plank": 22,
            "weighted_kneeling_plank": 23,
            "kneeling_side_plank_with_leg_lift": 24,
            "weighted_kneeling_side_plank_with_leg_lift": 25,
            "lateral_roll": 26,
            "weighted_lateral_roll": 27,
            "lying_reverse_plank": 28,
            "weighted_lying_reverse_plank": 29,
            "medicine_ball_mountain_climber": 30,
            "weighted_medicine_ball_mountain_climber": 31,
            "modified_mountain_climber_and_extension": 32,
            "weighted_modified_mountain_climber_and_extension": 33,
            "mountain_climber": 34,
            "weighted_mountain_climber": 35,
            "mountain_climber_on_sliding_discs": 36,
            "weighted_mountain_climber_on_sliding_discs": 37,
            "mountain_climber_with_feet_on_bosu_ball": 38,
            "weighted_mountain_climber_with_feet_on_bosu_ball": 39,
            "mountain_climber_with_hands_on_bench": 40,
            "mountain_climber_with_hands_on_swiss_ball": 41,
            "weighted_mountain_climber_with_hands_on_swiss_ball": 42,
            "plank": 43,
            "plank_jacks_with_feet_on_sliding_discs": 44,
            "weighted_plank_jacks_with_feet_on_sliding_discs": 45,
            "plank_knee_twist": 46,
            "weighted_plank_knee_twist": 47,
            "plank_pike_jumps": 48,
            "weighted_plank_pike_jumps": 49,
            "plank_pikes": 50,
            "weighted_plank_pikes": 51,
            "plank_to_stand_up": 52,
            "weighted_plank_to_stand_up": 53,
            "plank_with_arm_raise": 54,
            "weighted_plank_with_arm_raise": 55,
            "plank_with_knee_to_elbow": 56,
            "weighted_plank_with_knee_to_elbow": 57,
            "plank_with_oblique_crunch": 58,
            "weighted_plank_with_oblique_crunch": 59,
            "plyometric_side_plank": 60,
            "weighted_plyometric_side_plank": 61,
            "rolling_side_plank": 62,
            "weighted_rolling_side_plank": 63,
            "side_kick_plank": 64,
            "weighted_side_kick_plank": 65,
            "side_plank": 66,
            "weighted_side_plank": 67,
            "side_plank_and_row": 68,
            "weighted_side_plank_and_row": 69,
            "side_plank_lift": 70,
            "weighted_side_plank_lift": 71,
            "side_plank_with_elbow_on_bosu_ball": 72,
            "weighted_side_plank_with_elbow_on_bosu_ball": 73,
            "side_plank_with_feet_on_bench": 74,
            "weighted_side_plank_with_feet_on_bench": 75,
            "side_plank_with_knee_circle": 76,
            "weighted_side_plank_with_knee_circle": 77,
            "side_plank_with_knee_tuck": 78,
            "weighted_side_plank_with_knee_tuck": 79,
            "side_plank_with_leg_lift": 80,
            "weighted_side_plank_with_leg_lift": 81,
            "side_plank_with_reach_under": 82,
            "weighted_side_plank_with_reach_under": 83,
            "single_leg_elevated_feet_plank": 84,
            "weighted_single_leg_elevated_feet_plank": 85,
            "single_leg_flex_and_extend": 86,
            "weighted_single_leg_flex_and_extend": 87,
            "single_leg_side_plank": 88,
            "weighted_single_leg_side_plank": 89,
            "spiderman_plank": 90,
            "weighted_spiderman_plank": 91,
            "straight_arm_plank": 92,
            "weighted_straight_arm_plank": 93,
            "straight_arm_plank_with_shoulder_touch": 94,
            "weighted_straight_arm_plank_with_shoulder_touch": 95,
            "swiss_ball_plank": 96,
            "weighted_swiss_ball_plank": 97,
            "swiss_ball_plank_leg_lift": 98,
            "weighted_swiss_ball_plank_leg_lift": 99,
            "swiss_ball_plank_leg_lift_and_hold": 100,
            "swiss_ball_plank_with_feet_on_bench": 101,
            "weighted_swiss_ball_plank_with_feet_on_bench": 102,
            "swiss_ball_prone_jackknife": 103,
            "weighted_swiss_ball_prone_jackknife": 104,
            "swiss_ball_side_plank": 105,
            "weighted_swiss_ball_side_plank": 106,
            "three_way_plank": 107,
            "weighted_three_way_plank": 108,
            "towel_plank_and_knee_in": 109,
            "weighted_towel_plank_and_knee_in": 110,
            "t_stabilization": 111,
            "weighted_t_stabilization": 112,
            "turkish_get_up_to_side_plank": 113,
            "weighted_turkish_get_up_to_side_plank": 114,
            "two_point_plank": 115,
            "weighted_two_point_plank": 116,
            "weighted_plank": 117,
            "wide_stance_plank_with_diagonal_arm_lift": 118,
            "weighted_wide_stance_plank_with_diagonal_arm_lift": 119,
            "wide_stance_plank_with_diagonal_leg_lift": 120,
            "weighted_wide_stance_plank_with_diagonal_leg_lift": 121,
            "wide_stance_plank_with_leg_lift": 122,
            "weighted_wide_stance_plank_with_leg_lift": 123,
            "wide_stance_plank_with_opposite_arm_and_leg_lift": 124,
            "weighted_mountain_climber_with_hands_on_bench": 125,
            "weighted_swiss_ball_plank_leg_lift_and_hold": 126,
            "weighted_wide_stance_plank_with_opposite_arm_and_leg_lift": 127,
            "plank_with_feet_on_swiss_ball": 128,
            "side_plank_to_plank_with_reach_under": 129,
            "bridge_with_glute_lower_lift": 130,
            "bridge_one_leg_bridge": 131,
            "plank_with_arm_variations": 132,
            "plank_with_leg_lift": 133,
            "reverse_plank_with_leg_pull": 134
        }
    },
    "plyo_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "alternating_jump_lunge",
            "1": "weighted_alternating_jump_lunge",
            "2": "barbell_jump_squat",
            "3": "body_weight_jump_squat",
            "4": "weighted_jump_squat",
            "5": "cross_knee_strike",
            "6": "weighted_cross_knee_strike",
            "7": "depth_jump",
            "8": "weighted_depth_jump",
            "9": "dumbbell_jump_squat",
            "10": "dumbbell_split_jump",
            "11": "front_knee_strike",
            "12": "weighted_front_knee_strike",
            "13": "high_box_jump",
            "14": "weighted_high_box_jump",
            "15": "isometric_explosive_body_weight_jump_squat",
            "16": "weighted_isometric_explosive_jump_squat",
            "17": "lateral_leap_and_hop",
            "18": "weighted_lateral_leap_and_hop",
            "19": "lateral_plyo_squats",
            "20": "weighted_lateral_plyo_squats",
            "21": "lateral_slide",
            "22": "weighted_lateral_slide",
            "23": "medicine_ball_overhead_throws",
            "24": "medicine_ball_side_throw",
            "25": "medicine_ball_slam",
            "26": "side_to_side_medicine_ball_throws",
            "27": "side_to_side_shuffle_jump",
            "28": "weighted_side_to_side_shuffle_jump",
            "29": "squat_jump_onto_box",
            "30": "weighted_squat_jump_onto_box",
            "31": "squat_jumps_in_and_out",
            "32": "weighted_squat_jumps_in_and_out",
            "alternating_jump_lunge": 0,
            "weighted_alternating_jump_lunge": 1,
            "barbell_jump_squat": 2,
            "body_weight_jump_squat": 3,
            "weighted_jump_squat": 4,
            "cross_knee_strike": 5,
            "weighted_cross_knee_strike": 6,
            "depth_jump": 7,
            "weighted_depth_jump": 8,
            "dumbbell_jump_squat": 9,
            "dumbbell_split_jump": 10,
            "front_knee_strike": 11,
            "weighted_front_knee_strike": 12,
            "high_box_jump": 13,
            "weighted_high_box_jump": 14,
            "isometric_explosive_body_weight_jump_squat": 15,
            "weighted_isometric_explosive_jump_squat": 16,
            "lateral_leap_and_hop": 17,
            "weighted_lateral_leap_and_hop": 18,
            "lateral_plyo_squats": 19,
            "weighted_lateral_plyo_squats": 20,
            "lateral_slide": 21,
            "weighted_lateral_slide": 22,
            "medicine_ball_overhead_throws": 23,
            "medicine_ball_side_throw": 24,
            "medicine_ball_slam": 25,
            "side_to_side_medicine_ball_throws": 26,
            "side_to_side_shuffle_jump": 27,
            "weighted_side_to_side_shuffle_jump": 28,
            "squat_jump_onto_box": 29,
            "weighted_squat_jump_onto_box": 30,
            "squat_jumps_in_and_out": 31,
            "weighted_squat_jumps_in_and_out": 32
        }
    },
    "pull_up_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "banded_pull_ups",
            "1": "30_degree_lat_pulldown",
            "2": "band_assisted_chin_up",
            "3": "close_grip_chin_up",
            "4": "weighted_close_grip_chin_up",
            "5": "close_grip_lat_pulldown",
            "6": "crossover_chin_up",
            "7": "weighted_crossover_chin_up",
            "8": "ez_bar_pullover",
            "9": "hanging_hurdle",
            "10": "weighted_hanging_hurdle",
            "11": "kneeling_lat_pulldown",
            "12": "kneeling_underhand_grip_lat_pulldown",
            "13": "lat_pulldown",
            "14": "mixed_grip_chin_up",
            "15": "weighted_mixed_grip_chin_up",
            "16": "mixed_grip_pull_up",
            "17": "weighted_mixed_grip_pull_up",
            "18": "reverse_grip_pulldown",
            "19": "standing_cable_pullover",
            "20": "straight_arm_pulldown",
            "21": "swiss_ball_ez_bar_pullover",
            "22": "towel_pull_up",
            "23": "weighted_towel_pull_up",
            "24": "weighted_pull_up",
            "25": "wide_grip_lat_pulldown",
            "26": "wide_grip_pull_up",
            "27": "weighted_wide_grip_pull_up",
            "28": "burpee_pull_up",
            "29": "weighted_burpee_pull_up",
            "30": "jumping_pull_ups",
            "31": "weighted_jumping_pull_ups",
            "32": "kipping_pull_up",
            "33": "weighted_kipping_pull_up",
            "34": "l_pull_up",
            "35": "weighted_l_pull_up",
            "36": "suspended_chin_up",
            "37": "weighted_suspended_chin_up",
            "38": "pull_up",
            "banded_pull_ups": 0,
            "30_degree_lat_pulldown": 1,
            "band_assisted_chin_up": 2,
            "close_grip_chin_up": 3,
            "weighted_close_grip_chin_up": 4,
            "close_grip_lat_pulldown": 5,
            "crossover_chin_up": 6,
            "weighted_crossover_chin_up": 7,
            "ez_bar_pullover": 8,
            "hanging_hurdle": 9,
            "weighted_hanging_hurdle": 10,
            "kneeling_lat_pulldown": 11,
            "kneeling_underhand_grip_lat_pulldown": 12,
            "lat_pulldown": 13,
            "mixed_grip_chin_up": 14,
            "weighted_mixed_grip_chin_up": 15,
            "mixed_grip_pull_up": 16,
            "weighted_mixed_grip_pull_up": 17,
            "reverse_grip_pulldown": 18,
            "standing_cable_pullover": 19,
            "straight_arm_pulldown": 20,
            "swiss_ball_ez_bar_pullover": 21,
            "towel_pull_up": 22,
            "weighted_towel_pull_up": 23,
            "weighted_pull_up": 24,
            "wide_grip_lat_pulldown": 25,
            "wide_grip_pull_up": 26,
            "weighted_wide_grip_pull_up": 27,
            "burpee_pull_up": 28,
            "weighted_burpee_pull_up": 29,
            "jumping_pull_ups": 30,
            "weighted_jumping_pull_ups": 31,
            "kipping_pull_up": 32,
            "weighted_kipping_pull_up": 33,
            "l_pull_up": 34,
            "weighted_l_pull_up": 35,
            "suspended_chin_up": 36,
            "weighted_suspended_chin_up": 37,
            "pull_up": 38
        }
    },
    "push_up_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "chest_press_with_band",
            "1": "alternating_staggered_push_up",
            "2": "weighted_alternating_staggered_push_up",
            "3": "alternating_hands_medicine_ball_push_up",
            "4": "weighted_alternating_hands_medicine_ball_push_up",
            "5": "bosu_ball_push_up",
            "6": "weighted_bosu_ball_push_up",
            "7": "clapping_push_up",
            "8": "weighted_clapping_push_up",
            "9": "close_grip_medicine_ball_push_up",
            "10": "weighted_close_grip_medicine_ball_push_up",
            "11": "close_hands_push_up",
            "12": "weighted_close_hands_push_up",
            "13": "decline_push_up",
            "14": "weighted_decline_push_up",
            "15": "diamond_push_up",
            "16": "weighted_diamond_push_up",
            "17": "explosive_crossover_push_up",
            "18": "weighted_explosive_crossover_push_up",
            "19": "explosive_push_up",
            "20": "weighted_explosive_push_up",
            "21": "feet_elevated_side_to_side_push_up",
            "22": "weighted_feet_elevated_side_to_side_push_up",
            "23": "hand_release_push_up",
            "24": "weighted_hand_release_push_up",
            "25": "handstand_push_up",
            "26": "weighted_handstand_push_up",
            "27": "incline_push_up",
            "28": "weighted_incline_push_up",
            "29": "isometric_explosive_push_up",
            "30": "weighted_isometric_explosive_push_up",
            "31": "judo_push_up",
            "32": "weighted_judo_push_up",
            "33": "kneeling_push_up",
            "34": "weighted_kneeling_push_up",
            "35": "medicine_ball_chest_pass",
            "36": "medicine_ball_push_up",
            "37": "weighted_medicine_ball_push_up",
            "38": "one_arm_push_up",
            "39": "weighted_one_arm_push_up",
            "40": "weighted_push_up",
            "41": "push_up_and_row",
            "42": "weighted_push_up_and_row",
            "43": "push_up_plus",
            "44": "weighted_push_up_plus",
            "45": "push_up_with_feet_on_swiss_ball",
            "46": "weighted_push_up_with_feet_on_swiss_ball",
            "47": "push_up_with_one_hand_on_medicine_ball",
            "48": "weighted_push_up_with_one_hand_on_medicine_ball",
            "49": "shoulder_push_up",
            "50": "weighted_shoulder_push_up",
            "51": "single_arm_medicine_ball_push_up",
            "52": "weighted_single_arm_medicine_ball_push_up",
            "53": "spiderman_push_up",
            "54": "weighted_spiderman_push_up",
            "55": "stacked_feet_push_up",
            "56": "weighted_stacked_feet_push_up",
            "57": "staggered_hands_push_up",
            "58": "weighted_staggered_hands_push_up",
            "59": "suspended_push_up",
            "60": "weighted_suspended_push_up",
            "61": "swiss_ball_push_up",
            "62": "weighted_swiss_ball_push_up",
            "63": "swiss_ball_push_up_plus",
            "64": "weighted_swiss_ball_push_up_plus",
            "65": "t_push_up",
            "66": "weighted_t_push_up",
            "67": "triple_stop_push_up",
            "68": "weighted_triple_stop_push_up",
            "69": "wide_hands_push_up",
            "70": "weighted_wide_hands_push_up",
            "71": "parallette_handstand_push_up",
            "72": "weighted_parallette_handstand_push_up",
            "73": "ring_handstand_push_up",
            "74": "weighted_ring_handstand_push_up",
            "75": "ring_push_up",
            "76": "weighted_ring_push_up",
            "77": "push_up",
            "78": "pilates_pushup",
            "chest_press_with_band": 0,
            "alternating_staggered_push_up": 1,
            "weighted_alternating_staggered_push_up": 2,
            "alternating_hands_medicine_ball_push_up": 3,
            "weighted_alternating_hands_medicine_ball_push_up": 4,
            "bosu_ball_push_up": 5,
            "weighted_bosu_ball_push_up": 6,
            "clapping_push_up": 7,
            "weighted_clapping_push_up": 8,
            "close_grip_medicine_ball_push_up": 9,
            "weighted_close_grip_medicine_ball_push_up": 10,
            "close_hands_push_up": 11,
            "weighted_close_hands_push_up": 12,
            "decline_push_up": 13,
            "weighted_decline_push_up": 14,
            "diamond_push_up": 15,
            "weighted_diamond_push_up": 16,
            "explosive_crossover_push_up": 17,
            "weighted_explosive_crossover_push_up": 18,
            "explosive_push_up": 19,
            "weighted_explosive_push_up": 20,
            "feet_elevated_side_to_side_push_up": 21,
            "weighted_feet_elevated_side_to_side_push_up": 22,
            "hand_release_push_up": 23,
            "weighted_hand_release_push_up": 24,
            "handstand_push_up": 25,
            "weighted_handstand_push_up": 26,
            "incline_push_up": 27,
            "weighted_incline_push_up": 28,
            "isometric_explosive_push_up": 29,
            "weighted_isometric_explosive_push_up": 30,
            "judo_push_up": 31,
            "weighted_judo_push_up": 32,
            "kneeling_push_up": 33,
            "weighted_kneeling_push_up": 34,
            "medicine_ball_chest_pass": 35,
            "medicine_ball_push_up": 36,
            "weighted_medicine_ball_push_up": 37,
            "one_arm_push_up": 38,
            "weighted_one_arm_push_up": 39,
            "weighted_push_up": 40,
            "push_up_and_row": 41,
            "weighted_push_up_and_row": 42,
            "push_up_plus": 43,
            "weighted_push_up_plus": 44,
            "push_up_with_feet_on_swiss_ball": 45,
            "weighted_push_up_with_feet_on_swiss_ball": 46,
            "push_up_with_one_hand_on_medicine_ball": 47,
            "weighted_push_up_with_one_hand_on_medicine_ball": 48,
            "shoulder_push_up": 49,
            "weighted_shoulder_push_up": 50,
            "single_arm_medicine_ball_push_up": 51,
            "weighted_single_arm_medicine_ball_push_up": 52,
            "spiderman_push_up": 53,
            "weighted_spiderman_push_up": 54,
            "stacked_feet_push_up": 55,
            "weighted_stacked_feet_push_up": 56,
            "staggered_hands_push_up": 57,
            "weighted_staggered_hands_push_up": 58,
            "suspended_push_up": 59,
            "weighted_suspended_push_up": 60,
            "swiss_ball_push_up": 61,
            "weighted_swiss_ball_push_up": 62,
            "swiss_ball_push_up_plus": 63,
            "weighted_swiss_ball_push_up_plus": 64,
            "t_push_up": 65,
            "weighted_t_push_up": 66,
            "triple_stop_push_up": 67,
            "weighted_triple_stop_push_up": 68,
            "wide_hands_push_up": 69,
            "weighted_wide_hands_push_up": 70,
            "parallette_handstand_push_up": 71,
            "weighted_parallette_handstand_push_up": 72,
            "ring_handstand_push_up": 73,
            "weighted_ring_handstand_push_up": 74,
            "ring_push_up": 75,
            "weighted_ring_push_up": 76,
            "push_up": 77,
            "pilates_pushup": 78
        }
    },
    "row_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "barbell_straight_leg_deadlift_to_row",
            "1": "cable_row_standing",
            "2": "dumbbell_row",
            "3": "elevated_feet_inverted_row",
            "4": "weighted_elevated_feet_inverted_row",
            "5": "face_pull",
            "6": "face_pull_with_external_rotation",
            "7": "inverted_row_with_feet_on_swiss_ball",
            "8": "weighted_inverted_row_with_feet_on_swiss_ball",
            "9": "kettlebell_row",
            "10": "modified_inverted_row",
            "11": "weighted_modified_inverted_row",
            "12": "neutral_grip_alternating_dumbbell_row",
            "13": "one_arm_bent_over_row",
            "14": "one_legged_dumbbell_row",
            "15": "renegade_row",
            "16": "reverse_grip_barbell_row",
            "17": "rope_handle_cable_row",
            "18": "seated_cable_row",
            "19": "seated_dumbbell_row",
            "20": "single_arm_cable_row",
            "21": "single_arm_cable_row_and_rotation",
            "22": "single_arm_inverted_row",
            "23": "weighted_single_arm_inverted_row",
            "24": "single_arm_neutral_grip_dumbbell_row",
            "25": "single_arm_neutral_grip_dumbbell_row_and_rotation",
            "26": "suspended_inverted_row",
            "27": "weighted_suspended_inverted_row",
            "28": "t_bar_row",
            "29": "towel_grip_inverted_row",
            "30": "weighted_towel_grip_inverted_row",
            "31": "underhand_grip_cable_row",
            "32": "v_grip_cable_row",
            "33": "wide_grip_seated_cable_row",
            "barbell_straight_leg_deadlift_to_row": 0,
            "cable_row_standing": 1,
            "dumbbell_row": 2,
            "elevated_feet_inverted_row": 3,
            "weighted_elevated_feet_inverted_row": 4,
            "face_pull": 5,
            "face_pull_with_external_rotation": 6,
            "inverted_row_with_feet_on_swiss_ball": 7,
            "weighted_inverted_row_with_feet_on_swiss_ball": 8,
            "kettlebell_row": 9,
            "modified_inverted_row": 10,
            "weighted_modified_inverted_row": 11,
            "neutral_grip_alternating_dumbbell_row": 12,
            "one_arm_bent_over_row": 13,
            "one_legged_dumbbell_row": 14,
            "renegade_row": 15,
            "reverse_grip_barbell_row": 16,
            "rope_handle_cable_row": 17,
            "seated_cable_row": 18,
            "seated_dumbbell_row": 19,
            "single_arm_cable_row": 20,
            "single_arm_cable_row_and_rotation": 21,
            "single_arm_inverted_row": 22,
            "weighted_single_arm_inverted_row": 23,
            "single_arm_neutral_grip_dumbbell_row": 24,
            "single_arm_neutral_grip_dumbbell_row_and_rotation": 25,
            "suspended_inverted_row": 26,
            "weighted_suspended_inverted_row": 27,
            "t_bar_row": 28,
            "towel_grip_inverted_row": 29,
            "weighted_towel_grip_inverted_row": 30,
            "underhand_grip_cable_row": 31,
            "v_grip_cable_row": 32,
            "wide_grip_seated_cable_row": 33
        }
    },
    "shoulder_press_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "alternating_dumbbell_shoulder_press",
            "1": "arnold_press",
            "2": "barbell_front_squat_to_push_press",
            "3": "barbell_push_press",
            "4": "barbell_shoulder_press",
            "5": "dead_curl_press",
            "6": "dumbbell_alternating_shoulder_press_and_twist",
            "7": "dumbbell_hammer_curl_to_lunge_to_press",
            "8": "dumbbell_push_press",
            "9": "floor_inverted_shoulder_press",
            "10": "weighted_floor_inverted_shoulder_press",
            "11": "inverted_shoulder_press",
            "12": "weighted_inverted_shoulder_press",
            "13": "one_arm_push_press",
            "14": "overhead_barbell_press",
            "15": "overhead_dumbbell_press",
            "16": "seated_barbell_shoulder_press",
            "17": "seated_dumbbell_shoulder_press",
            "18": "single_arm_dumbbell_shoulder_press",
            "19": "single_arm_step_up_and_press",
            "20": "smith_machine_overhead_press",
            "21": "split_stance_hammer_curl_to_press",
            "22": "swiss_ball_dumbbell_shoulder_press",
            "23": "weight_plate_front_raise",
            "alternating_dumbbell_shoulder_press": 0,
            "arnold_press": 1,
            "barbell_front_squat_to_push_press": 2,
            "barbell_push_press": 3,
            "barbell_shoulder_press": 4,
            "dead_curl_press": 5,
            "dumbbell_alternating_shoulder_press_and_twist": 6,
            "dumbbell_hammer_curl_to_lunge_to_press": 7,
            "dumbbell_push_press": 8,
            "floor_inverted_shoulder_press": 9,
            "weighted_floor_inverted_shoulder_press": 10,
            "inverted_shoulder_press": 11,
            "weighted_inverted_shoulder_press": 12,
            "one_arm_push_press": 13,
            "overhead_barbell_press": 14,
            "overhead_dumbbell_press": 15,
            "seated_barbell_shoulder_press": 16,
            "seated_dumbbell_shoulder_press": 17,
            "single_arm_dumbbell_shoulder_press": 18,
            "single_arm_step_up_and_press": 19,
            "smith_machine_overhead_press": 20,
            "split_stance_hammer_curl_to_press": 21,
            "swiss_ball_dumbbell_shoulder_press": 22,
            "weight_plate_front_raise": 23
        }
    },
    "shoulder_stability_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "90_degree_cable_external_rotation",
            "1": "band_external_rotation",
            "2": "band_internal_rotation",
            "3": "bent_arm_lateral_raise_and_external_rotation",
            "4": "cable_external_rotation",
            "5": "dumbbell_face_pull_with_external_rotation",
            "6": "floor_i_raise",
            "7": "weighted_floor_i_raise",
            "8": "floor_t_raise",
            "9": "weighted_floor_t_raise",
            "10": "floor_y_raise",
            "11": "weighted_floor_y_raise",
            "12": "incline_i_raise",
            "13": "weighted_incline_i_raise",
            "14": "incline_l_raise",
            "15": "weighted_incline_l_raise",
            "16": "incline_t_raise",
            "17": "weighted_incline_t_raise",
            "18": "incline_w_raise",
            "19": "weighted_incline_w_raise",
            "20": "incline_y_raise",
            "21": "weighted_incline_y_raise",
            "22": "lying_external_rotation",
            "23": "seated_dumbbell_external_rotation",
            "24": "standing_l_raise",
            "25": "swiss_ball_i_raise",
            "26": "weighted_swiss_ball_i_raise",
            "27": "swiss_ball_t_raise",
            "28": "weighted_swiss_ball_t_raise",
            "29": "swiss_ball_w_raise",
            "30": "weighted_swiss_ball_w_raise",
            "31": "swiss_ball_y_raise",
            "32": "weighted_swiss_ball_y_raise",
            "90_degree_cable_external_rotation": 0,
            "band_external_rotation": 1,
            "band_internal_rotation": 2,
            "bent_arm_lateral_raise_and_external_rotation": 3,
            "cable_external_rotation": 4,
            "dumbbell_face_pull_with_external_rotation": 5,
            "floor_i_raise": 6,
            "weighted_floor_i_raise": 7,
            "floor_t_raise": 8,
            "weighted_floor_t_raise": 9,
            "floor_y_raise": 10,
            "weighted_floor_y_raise": 11,
            "incline_i_raise": 12,
            "weighted_incline_i_raise": 13,
            "incline_l_raise": 14,
            "weighted_incline_l_raise": 15,
            "incline_t_raise": 16,
            "weighted_incline_t_raise": 17,
            "incline_w_raise": 18,
            "weighted_incline_w_raise": 19,
            "incline_y_raise": 20,
            "weighted_incline_y_raise": 21,
            "lying_external_rotation": 22,
            "seated_dumbbell_external_rotation": 23,
            "standing_l_raise": 24,
            "swiss_ball_i_raise": 25,
            "weighted_swiss_ball_i_raise": 26,
            "swiss_ball_t_raise": 27,
            "weighted_swiss_ball_t_raise": 28,
            "swiss_ball_w_raise": 29,
            "weighted_swiss_ball_w_raise": 30,
            "swiss_ball_y_raise": 31,
            "weighted_swiss_ball_y_raise": 32
        }
    },
    "shrug_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "barbell_jump_shrug",
            "1": "barbell_shrug",
            "2": "barbell_upright_row",
            "3": "behind_the_back_smith_machine_shrug",
            "4": "dumbbell_jump_shrug",
            "5": "dumbbell_shrug",
            "6": "dumbbell_upright_row",
            "7": "incline_dumbbell_shrug",
            "8": "overhead_barbell_shrug",
            "9": "overhead_dumbbell_shrug",
            "10": "scaption_and_shrug",
            "11": "scapular_retraction",
            "12": "serratus_chair_shrug",
            "13": "weighted_serratus_chair_shrug",
            "14": "serratus_shrug",
            "15": "weighted_serratus_shrug",
            "16": "wide_grip_jump_shrug",
            "barbell_jump_shrug": 0,
            "barbell_shrug": 1,
            "barbell_upright_row": 2,
            "behind_the_back_smith_machine_shrug": 3,
            "dumbbell_jump_shrug": 4,
            "dumbbell_shrug": 5,
            "dumbbell_upright_row": 6,
            "incline_dumbbell_shrug": 7,
            "overhead_barbell_shrug": 8,
            "overhead_dumbbell_shrug": 9,
            "scaption_and_shrug": 10,
            "scapular_retraction": 11,
            "serratus_chair_shrug": 12,
            "weighted_serratus_chair_shrug": 13,
            "serratus_shrug": 14,
            "weighted_serratus_shrug": 15,
            "wide_grip_jump_shrug": 16
        }
    },
    "sit_up_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "alternating_sit_up",
            "1": "weighted_alternating_sit_up",
            "2": "bent_knee_v_up",
            "3": "weighted_bent_knee_v_up",
            "4": "butterfly_sit_up",
            "5": "weighted_butterfly_situp",
            "6": "cross_punch_roll_up",
            "7": "weighted_cross_punch_roll_up",
            "8": "crossed_arms_sit_up",
            "9": "weighted_crossed_arms_sit_up",
            "10": "get_up_sit_up",
            "11": "weighted_get_up_sit_up",
            "12": "hovering_sit_up",
            "13": "weighted_hovering_sit_up",
            "14": "kettlebell_sit_up",
            "15": "medicine_ball_alternating_v_up",
            "16": "medicine_ball_sit_up",
            "17": "medicine_ball_v_up",
            "18": "modified_sit_up",
            "19": "negative_sit_up",
            "20": "one_arm_full_sit_up",
            "21": "reclining_circle",
            "22": "weighted_reclining_circle",
            "23": "reverse_curl_up",
            "24": "weighted_reverse_curl_up",
            "25": "single_leg_swiss_ball_jackknife",
            "26": "weighted_single_leg_swiss_ball_jackknife",
            "27": "the_teaser",
            "28": "the_teaser_weighted",
            "29": "three_part_roll_down",
            "30": "weighted_three_part_roll_down",
            "31": "v_up",
            "32": "weighted_v_up",
            "33": "weighted_russian_twist_on_swiss_ball",
            "34": "weighted_sit_up",
            "35": "x_abs",
            "36": "weighted_x_abs",
            "37": "sit_up",
            "alternating_sit_up": 0,
            "weighted_alternating_sit_up": 1,
            "bent_knee_v_up": 2,
            "weighted_bent_knee_v_up": 3,
            "butterfly_sit_up": 4,
            "weighted_butterfly_situp": 5,
            "cross_punch_roll_up": 6,
            "weighted_cross_punch_roll_up": 7,
            "crossed_arms_sit_up": 8,
            "weighted_crossed_arms_sit_up": 9,
            "get_up_sit_up": 10,
            "weighted_get_up_sit_up": 11,
            "hovering_sit_up": 12,
            "weighted_hovering_sit_up": 13,
            "kettlebell_sit_up": 14,
            "medicine_ball_alternating_v_up": 15,
            "medicine_ball_sit_up": 16,
            "medicine_ball_v_up": 17,
            "modified_sit_up": 18,
            "negative_sit_up": 19,
            "one_arm_full_sit_up": 20,
            "reclining_circle": 21,
            "weighted_reclining_circle": 22,
            "reverse_curl_up": 23,
            "weighted_reverse_curl_up": 24,
            "single_leg_swiss_ball_jackknife": 25,
            "weighted_single_leg_swiss_ball_jackknife": 26,
            "the_teaser": 27,
            "the_teaser_weighted": 28,
            "three_part_roll_down": 29,
            "weighted_three_part_roll_down": 30,
            "v_up": 31,
            "weighted_v_up": 32,
            "weighted_russian_twist_on_swiss_ball": 33,
            "weighted_sit_up": 34,
            "x_abs": 35,
            "weighted_x_abs": 36,
            "sit_up": 37
        }
    },
    "squat_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "leg_press",
            "1": "back_squat_with_body_bar",
            "2": "back_squats",
            "3": "weighted_back_squats",
            "4": "balancing_squat",
            "5": "weighted_balancing_squat",
            "6": "barbell_back_squat",
            "7": "barbell_box_squat",
            "8": "barbell_front_squat",
            "9": "barbell_hack_squat",
            "10": "barbell_hang_squat_snatch",
            "11": "barbell_lateral_step_up",
            "12": "barbell_quarter_squat",
            "13": "barbell_siff_squat",
            "14": "barbell_squat_snatch",
            "15": "barbell_squat_with_heels_raised",
            "16": "barbell_stepover",
            "17": "barbell_step_up",
            "18": "bench_squat_with_rotational_chop",
            "19": "weighted_bench_squat_with_rotational_chop",
            "20": "body_weight_wall_squat",
            "21": "weighted_wall_squat",
            "22": "box_step_squat",
            "23": "weighted_box_step_squat",
            "24": "braced_squat",
            "25": "crossed_arm_barbell_front_squat",
            "26": "crossover_dumbbell_step_up",
            "27": "dumbbell_front_squat",
            "28": "dumbbell_split_squat",
            "29": "dumbbell_squat",
            "30": "dumbbell_squat_clean",
            "31": "dumbbell_stepover",
            "32": "dumbbell_step_up",
            "33": "elevated_single_leg_squat",
            "34": "weighted_elevated_single_leg_squat",
            "35": "figure_four_squats",
            "36": "weighted_figure_four_squats",
            "37": "goblet_squat",
            "38": "kettlebell_squat",
            "39": "kettlebell_swing_overhead",
            "40": "kettlebell_swing_with_flip_to_squat",
            "41": "lateral_dumbbell_step_up",
            "42": "one_legged_squat",
            "43": "overhead_dumbbell_squat",
            "44": "overhead_squat",
            "45": "partial_single_leg_squat",
            "46": "weighted_partial_single_leg_squat",
            "47": "pistol_squat",
            "48": "weighted_pistol_squat",
            "49": "plie_slides",
            "50": "weighted_plie_slides",
            "51": "plie_squat",
            "52": "weighted_plie_squat",
            "53": "prisoner_squat",
            "54": "weighted_prisoner_squat",
            "55": "single_leg_bench_get_up",
            "56": "weighted_single_leg_bench_get_up",
            "57": "single_leg_bench_squat",
            "58": "weighted_single_leg_bench_squat",
            "59": "single_leg_squat_on_swiss_ball",
            "60": "weighted_single_leg_squat_on_swiss_ball",
            "61": "squat",
            "62": "weighted_squat",
            "63": "squats_with_band",
            "64": "staggered_squat",
            "65": "weighted_staggered_squat",
            "66": "step_up",
            "67": "weighted_step_up",
            "68": "suitcase_squats",
            "69": "sumo_squat",
            "70": "sumo_squat_slide_in",
            "71": "weighted_sumo_squat_slide_in",
            "72": "sumo_squat_to_high_pull",
            "73": "sumo_squat_to_stand",
            "74": "weighted_sumo_squat_to_stand",
            "75": "sumo_squat_with_rotation",
            "76": "weighted_sumo_squat_with_rotation",
            "77": "swiss_ball_body_weight_wall_squat",
            "78": "weighted_swiss_ball_wall_squat",
            "79": "thrusters",
            "80": "uneven_squat",
            "81": "weighted_uneven_squat",
            "82": "waist_slimming_squat",
            "83": "wall_ball",
            "84": "wide_stance_barbell_squat",
            "85": "wide_stance_goblet_squat",
            "86": "zercher_squat",
            "87": "kbs_overhead",
            "88": "squat_and_side_kick",
            "89": "squat_jumps_in_n_out",
            "90": "pilates_plie_squats_parallel_turned_out_flat_and_heels",
            "91": "releve_straight_leg_and_knee_bent_with_one_leg_variation",
            "leg_press": 0,
            "back_squat_with_body_bar": 1,
            "back_squats": 2,
            "weighted_back_squats": 3,
            "balancing_squat": 4,
            "weighted_balancing_squat": 5,
            "barbell_back_squat": 6,
            "barbell_box_squat": 7,
            "barbell_front_squat": 8,
            "barbell_hack_squat": 9,
            "barbell_hang_squat_snatch": 10,
            "barbell_lateral_step_up": 11,
            "barbell_quarter_squat": 12,
            "barbell_siff_squat": 13,
            "barbell_squat_snatch": 14,
            "barbell_squat_with_heels_raised": 15,
            "barbell_stepover": 16,
            "barbell_step_up": 17,
            "bench_squat_with_rotational_chop": 18,
            "weighted_bench_squat_with_rotational_chop": 19,
            "body_weight_wall_squat": 20,
            "weighted_wall_squat": 21,
            "box_step_squat": 22,
            "weighted_box_step_squat": 23,
            "braced_squat": 24,
            "crossed_arm_barbell_front_squat": 25,
            "crossover_dumbbell_step_up": 26,
            "dumbbell_front_squat": 27,
            "dumbbell_split_squat": 28,
            "dumbbell_squat": 29,
            "dumbbell_squat_clean": 30,
            "dumbbell_stepover": 31,
            "dumbbell_step_up": 32,
            "elevated_single_leg_squat": 33,
            "weighted_elevated_single_leg_squat": 34,
            "figure_four_squats": 35,
            "weighted_figure_four_squats": 36,
            "goblet_squat": 37,
            "kettlebell_squat": 38,
            "kettlebell_swing_overhead": 39,
            "kettlebell_swing_with_flip_to_squat": 40,
            "lateral_dumbbell_step_up": 41,
            "one_legged_squat": 42,
            "overhead_dumbbell_squat": 43,
            "overhead_squat": 44,
            "partial_single_leg_squat": 45,
            "weighted_partial_single_leg_squat": 46,
            "pistol_squat": 47,
            "weighted_pistol_squat": 48,
            "plie_slides": 49,
            "weighted_plie_slides": 50,
            "plie_squat": 51,
            "weighted_plie_squat": 52,
            "prisoner_squat": 53,
            "weighted_prisoner_squat": 54,
            "single_leg_bench_get_up": 55,
            "weighted_single_leg_bench_get_up": 56,
            "single_leg_bench_squat": 57,
            "weighted_single_leg_bench_squat": 58,
            "single_leg_squat_on_swiss_ball": 59,
            "weighted_single_leg_squat_on_swiss_ball": 60,
            "squat": 61,
            "weighted_squat": 62,
            "squats_with_band": 63,
            "staggered_squat": 64,
            "weighted_staggered_squat": 65,
            "step_up": 66,
            "weighted_step_up": 67,
            "suitcase_squats": 68,
            "sumo_squat": 69,
            "sumo_squat_slide_in": 70,
            "weighted_sumo_squat_slide_in": 71,
            "sumo_squat_to_high_pull": 72,
            "sumo_squat_to_stand": 73,
            "weighted_sumo_squat_to_stand": 74,
            "sumo_squat_with_rotation": 75,
            "weighted_sumo_squat_with_rotation": 76,
            "swiss_ball_body_weight_wall_squat": 77,
            "weighted_swiss_ball_wall_squat": 78,
            "thrusters": 79,
            "uneven_squat": 80,
            "weighted_uneven_squat": 81,
            "waist_slimming_squat": 82,
            "wall_ball": 83,
            "wide_stance_barbell_squat": 84,
            "wide_stance_goblet_squat": 85,
            "zercher_squat": 86,
            "kbs_overhead": 87,
            "squat_and_side_kick": 88,
            "squat_jumps_in_n_out": 89,
            "pilates_plie_squats_parallel_turned_out_flat_and_heels": 90,
            "releve_straight_leg_and_knee_bent_with_one_leg_variation": 91
        }
    },
    "total_body_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "burpee",
            "1": "weighted_burpee",
            "2": "burpee_box_jump",
            "3": "weighted_burpee_box_jump",
            "4": "high_pull_burpee",
            "5": "man_makers",
            "6": "one_arm_burpee",
            "7": "squat_thrusts",
            "8": "weighted_squat_thrusts",
            "9": "squat_plank_push_up",
            "10": "weighted_squat_plank_push_up",
            "11": "standing_t_rotation_balance",
            "12": "weighted_standing_t_rotation_balance",
            "burpee": 0,
            "weighted_burpee": 1,
            "burpee_box_jump": 2,
            "weighted_burpee_box_jump": 3,
            "high_pull_burpee": 4,
            "man_makers": 5,
            "one_arm_burpee": 6,
            "squat_thrusts": 7,
            "weighted_squat_thrusts": 8,
            "squat_plank_push_up": 9,
            "weighted_squat_plank_push_up": 10,
            "standing_t_rotation_balance": 11,
            "weighted_standing_t_rotation_balance": 12
        }
    },
    "triceps_extension_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "bench_dip",
            "1": "weighted_bench_dip",
            "2": "body_weight_dip",
            "3": "cable_kickback",
            "4": "cable_lying_triceps_extension",
            "5": "cable_overhead_triceps_extension",
            "6": "dumbbell_kickback",
            "7": "dumbbell_lying_triceps_extension",
            "8": "ez_bar_overhead_triceps_extension",
            "9": "incline_dip",
            "10": "weighted_incline_dip",
            "11": "incline_ez_bar_lying_triceps_extension",
            "12": "lying_dumbbell_pullover_to_extension",
            "13": "lying_ez_bar_triceps_extension",
            "14": "lying_triceps_extension_to_close_grip_bench_press",
            "15": "overhead_dumbbell_triceps_extension",
            "16": "reclining_triceps_press",
            "17": "reverse_grip_pressdown",
            "18": "reverse_grip_triceps_pressdown",
            "19": "rope_pressdown",
            "20": "seated_barbell_overhead_triceps_extension",
            "21": "seated_dumbbell_overhead_triceps_extension",
            "22": "seated_ez_bar_overhead_triceps_extension",
            "23": "seated_single_arm_overhead_dumbbell_extension",
            "24": "single_arm_dumbbell_overhead_triceps_extension",
            "25": "single_dumbbell_seated_overhead_triceps_extension",
            "26": "single_leg_bench_dip_and_kick",
            "27": "weighted_single_leg_bench_dip_and_kick",
            "28": "single_leg_dip",
            "29": "weighted_single_leg_dip",
            "30": "static_lying_triceps_extension",
            "31": "suspended_dip",
            "32": "weighted_suspended_dip",
            "33": "swiss_ball_dumbbell_lying_triceps_extension",
            "34": "swiss_ball_ez_bar_lying_triceps_extension",
            "35": "swiss_ball_ez_bar_overhead_triceps_extension",
            "36": "tabletop_dip",
            "37": "weighted_tabletop_dip",
            "38": "triceps_extension_on_floor",
            "39": "triceps_pressdown",
            "40": "weighted_dip",
            "bench_dip": 0,
            "weighted_bench_dip": 1,
            "body_weight_dip": 2,
            "cable_kickback": 3,
            "cable_lying_triceps_extension": 4,
            "cable_overhead_triceps_extension": 5,
            "dumbbell_kickback": 6,
            "dumbbell_lying_triceps_extension": 7,
            "ez_bar_overhead_triceps_extension": 8,
            "incline_dip": 9,
            "weighted_incline_dip": 10,
            "incline_ez_bar_lying_triceps_extension": 11,
            "lying_dumbbell_pullover_to_extension": 12,
            "lying_ez_bar_triceps_extension": 13,
            "lying_triceps_extension_to_close_grip_bench_press": 14,
            "overhead_dumbbell_triceps_extension": 15,
            "reclining_triceps_press": 16,
            "reverse_grip_pressdown": 17,
            "reverse_grip_triceps_pressdown": 18,
            "rope_pressdown": 19,
            "seated_barbell_overhead_triceps_extension": 20,
            "seated_dumbbell_overhead_triceps_extension": 21,
            "seated_ez_bar_overhead_triceps_extension": 22,
            "seated_single_arm_overhead_dumbbell_extension": 23,
            "single_arm_dumbbell_overhead_triceps_extension": 24,
            "single_dumbbell_seated_overhead_triceps_extension": 25,
            "single_leg_bench_dip_and_kick": 26,
            "weighted_single_leg_bench_dip_and_kick": 27,
            "single_leg_dip": 28,
            "weighted_single_leg_dip": 29,
            "static_lying_triceps_extension": 30,
            "suspended_dip": 31,
            "weighted_suspended_dip": 32,
            "swiss_ball_dumbbell_lying_triceps_extension": 33,
            "swiss_ball_ez_bar_lying_triceps_extension": 34,
            "swiss_ball_ez_bar_overhead_triceps_extension": 35,
            "tabletop_dip": 36,
            "weighted_tabletop_dip": 37,
            "triceps_extension_on_floor": 38,
            "triceps_pressdown": 39,
            "weighted_dip": 40
        }
    },
    "warm_up_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "quadruped_rocking",
            "1": "neck_tilts",
            "2": "ankle_circles",
            "3": "ankle_dorsiflexion_with_band",
            "4": "ankle_internal_rotation",
            "5": "arm_circles",
            "6": "bent_over_reach_to_sky",
            "7": "cat_camel",
            "8": "elbow_to_foot_lunge",
            "9": "forward_and_backward_leg_swings",
            "10": "groiners",
            "11": "inverted_hamstring_stretch",
            "12": "lateral_duck_under",
            "13": "neck_rotations",
            "14": "opposite_arm_and_leg_balance",
            "15": "reach_roll_and_lift",
            "16": "scorpion",
            "17": "shoulder_circles",
            "18": "side_to_side_leg_swings",
            "19": "sleeper_stretch",
            "20": "slide_out",
            "21": "swiss_ball_hip_crossover",
            "22": "swiss_ball_reach_roll_and_lift",
            "23": "swiss_ball_windshield_wipers",
            "24": "thoracic_rotation",
            "25": "walking_high_kicks",
            "26": "walking_high_knees",
            "27": "walking_knee_hugs",
            "28": "walking_leg_cradles",
            "29": "walkout",
            "30": "walkout_from_push_up_position",
            "quadruped_rocking": 0,
            "neck_tilts": 1,
            "ankle_circles": 2,
            "ankle_dorsiflexion_with_band": 3,
            "ankle_internal_rotation": 4,
            "arm_circles": 5,
            "bent_over_reach_to_sky": 6,
            "cat_camel": 7,
            "elbow_to_foot_lunge": 8,
            "forward_and_backward_leg_swings": 9,
            "groiners": 10,
            "inverted_hamstring_stretch": 11,
            "lateral_duck_under": 12,
            "neck_rotations": 13,
            "opposite_arm_and_leg_balance": 14,
            "reach_roll_and_lift": 15,
            "scorpion": 16,
            "shoulder_circles": 17,
            "side_to_side_leg_swings": 18,
            "sleeper_stretch": 19,
            "slide_out": 20,
            "swiss_ball_hip_crossover": 21,
            "swiss_ball_reach_roll_and_lift": 22,
            "swiss_ball_windshield_wipers": 23,
            "thoracic_rotation": 24,
            "walking_high_kicks": 25,
            "walking_high_knees": 26,
            "walking_knee_hugs": 27,
            "walking_leg_cradles": 28,
            "walkout": 29,
            "walkout_from_push_up_position": 30
        }
    },
    "run_exercise_name": {
        "base_type": "uint16",
        "values": {
            "0": "run",
            "1": "walk",
            "2": "jog",
            "3": "sprint",
            "run": 0,
            "walk": 1,
            "jog": 2,
            "sprint": 3
        }
    },
    "water_type": {
        "base_type": "enum",
        "values": {
            "0": "fresh",
            "1": "salt",
            "2": "en13319",
            "3": "custom",
            "fresh": 0,
            "salt": 1,
            "en13319": 2,
            "custom": 3
        }
    },
    "tissue_model_type": {
        "base_type": "enum",
        "values": {
            "0": "zhl_16c",
            "zhl_16c": 0
        }
    },
    "dive_gas_status": {
        "base_type": "enum",
        "values": {
            "0": "disabled",
            "1": "enabled",
            "2": "backup_only",
            "disabled": 0,
            "enabled": 1,
            "backup_only": 2
        }
    },
    "dive_alarm_type": {
        "base_type": "enum",
        "values": {
            "0": "depth",
            "1": "time",
            "depth": 0,
            "time": 1
        }
    },
    "dive_backlight_mode": {
        "base_type": "enum",
        "values": {
            "0": "at_depth",
            "1": "always_on",
            "at_depth": 0,
            "always_on": 1
        }
    },
    "favero_product": {
        "base_type": "uint16",
        "values": {
            "10": "assioma_uno",
            "12": "assioma_duo",
            "assioma_uno": 10,
            "assioma_duo": 12
        }
    },
    "climb_pro_event": {
        "base_type": "enum",
        "values": {
            "0": "approach",
            "1": "start",
            "2": "complete",
            "approach": 0,
            "start": 1,
            "complete": 2
        }
    },
    "tap_sensitivity": {
        "base_type": "enum",
        "values": {
            "0": "high",
            "1": "medium",
            "2": "low",
            "high": 0,
            "medium": 1,
            "low": 2
        }
    },
    "radar_threat_level_type": {
        "base_type": "enum",
        "values": {
            "0": "threat_unknown",
            "1": "threat_none",
            "2": "threat_approaching",
            "3": "threat_approaching_fast",
            "threat_unknown": 0,
            "threat_none": 1,
            "threat_approaching": 2,
            "threat_approaching_fast": 3
        }
    }
}