import './connection-switch.js';
import './data-views.js';
import './effect-views.js';
import './editor.js';
import './tabs.js';
import './workout-list.js';
import './workout-graph.js';
import './watch.js';
import './ant-device-scan.js';
import './keyboard.js';

function start() {
    console.log('start views.');
}

start();
